import { CompanyType, CreditorType } from "@gocardless/api/dashboard/types";
import { Box, Field, Label, Option, Select } from "@gocardless/flux-react";
import { I18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { MerchantOnboardingSetupEvents } from "src/components/routes/Setup/common/constants/MerchantOnboardingSetupEvents";
import { useSegmentForSetup } from "src/components/routes/Setup/common/hooks/useSegmentForSetup";

import { Field as Config } from "../config/types";

const ConsolidatedCreditorTypeField = () => {
  const { i18n } = useLingui();

  const { getValues, setValue } = useFormContext();

  const { creditor_type: creditorType, company_type: companyType } =
    getValues();

  const value = useMemo(() => {
    const fromCreditorTypeMapping = Object.entries(creditorTypeMapping)
      .filter(([_, type]) => type === creditorType)
      .map(([key]) => key as UsCompanyType);

    const fromCompanyTypeMapping: UsCompanyType[] = Object.entries(
      companyTypeMapping
    )
      .filter(([_, type]) => type === companyType)
      .map(([key]) => key as UsCompanyType);

    const reverseMapResult = fromCreditorTypeMapping.filter((type) =>
      fromCompanyTypeMapping.includes(type)
    )[0];

    return reverseMapResult || UsCompanyType.LimitedLiabilityCompany;
  }, [creditorType, companyType]);

  // This is used to initialise form values because we don't "register" these fields
  useEffect(() => {
    setValue("creditor_type", creditorTypeMapping[value]);
    setValue("company_type", companyTypeMapping[value]);
  }, [creditorType, companyType, value, setValue]);

  const { sendEvent } = useSegmentForSetup();

  return (
    <Field>
      <Box layout="flex">
        <Label htmlFor="consolidated_creditor_type" css={{ paddingTop: "3px" }}>
          <Trans id="setup.business-details.creditor-type">Business Type</Trans>
        </Label>
      </Box>
      <Select
        value={value}
        data-testid="consolidated_creditor_type"
        id="consolidated_creditor_type"
        onChange={(event) => {
          const type = event.target.value as UsCompanyType;

          setValue("creditor_type", creditorTypeMapping[type]);
          setValue("company_type", companyTypeMapping[type]);

          sendEvent(MerchantOnboardingSetupEvents.BusinessTypeDropdownChanged, {
            merchant_type: type,
          });
        }}
      >
        {Object.values(UsCompanyType).map((option) => (
          <Option key={option} value={option}>
            {ConsolidatedCreditorTypeMap(i18n)[option]}
          </Option>
        ))}
      </Select>
    </Field>
  );
};

export enum UsCompanyType {
  LimitedLiabilityCompany = "llc",
  Corporation = "corporation",
  Charity = "charity",
  Individual = "individual",
  Partnership = "partnership",
  Trust = "trust",
}

export const creditorTypeMapping = {
  [UsCompanyType.LimitedLiabilityCompany]: CreditorType.Company,
  [UsCompanyType.Corporation]: CreditorType.Company,
  [UsCompanyType.Charity]: CreditorType.Charity,
  [UsCompanyType.Individual]: CreditorType.Individual,
  [UsCompanyType.Partnership]: CreditorType.Partnership,
  [UsCompanyType.Trust]: CreditorType.Trust,
};

export const companyTypeMapping = {
  [UsCompanyType.LimitedLiabilityCompany]: CompanyType.LimitedLiabilityCompany,
  [UsCompanyType.Corporation]: CompanyType.Corporation,
  [UsCompanyType.Charity]: undefined,
  [UsCompanyType.Individual]: undefined,
  [UsCompanyType.Partnership]: undefined,
  [UsCompanyType.Trust]: undefined,
};

const ConsolidatedCreditorTypeMap = (i18n: I18n) => ({
  [UsCompanyType.LimitedLiabilityCompany]: i18n._(
    t({
      id: "setup.company-type.limited-liability-company",
      message: "Limited Liability Company",
    })
  ),
  [UsCompanyType.Corporation]: i18n._(
    t({
      id: "setup.company-type.corporation",
      message: "Corporation",
    })
  ),
  [UsCompanyType.Charity]: i18n._(
    t({
      id: "setup.business-details.charity",
      message: "Charity/non-profit",
    })
  ),
  [UsCompanyType.Individual]: i18n._(
    t({
      id: "setup.business-details.individual",
      message: "Individual/sole trader",
    })
  ),
  [UsCompanyType.Partnership]: i18n._(
    t({
      id: "setup.business-details.partnership",
      message: "Partnership",
    })
  ),
  [UsCompanyType.Trust]: i18n._(
    t({
      id: "setup.business-details.trust",
      message: "Trust",
    })
  ),
});

export const config: Config = {
  name: "consolidatedCreditorType",
  displayName: "Business type",
  component: ConsolidatedCreditorTypeField,
};

export default ConsolidatedCreditorTypeField;
