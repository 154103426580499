import { useEffect } from "react";
import {
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { get, hasIn } from "lodash";
import { TrusteeType } from "@gocardless/api/dashboard/types";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { Field as Config, FieldArrayProps } from "../config/types";

import { presenceCheck } from "./helpers";

const TrusteeBusinessNumberField: React.FC<FieldArrayProps> = ({
  defaultValue,
  index,
}) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
    unregister,
  } = useFormContext();
  const fieldPath = `trustees[${index}].company_number`;
  const requiredError = i18n._(
    t({
      id: "setup.trustee-details.business-number-required",
      message: "Please enter a business number",
    })
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => unregister(fieldPath), []);

  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="setup.trustee-details.business-number">
          Australian Business number
        </Trans>
      </Label>
      <Input
        {...register(fieldPath, presenceCheck(requiredError))}
        id={fieldPath}
        defaultValue={defaultValue}
        className="fs-exclude"
      />
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}`)?.message as string}
        </Hint>
      )}
    </Field>
  );
};

export const config: Config = {
  name: "company_number",
  displayName: "Trustee Business Number",
  required: ({ type }) => type === TrusteeType.Company,
  component: TrusteeBusinessNumberField,
};

export default TrusteeBusinessNumberField;
