import { useFormContext } from "react-hook-form";
import { Field, Label, Option, Tooltip, Select } from "@gocardless/flux-react";
import { TrusteeType } from "@gocardless/api/dashboard/types";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { Field as Config, FieldArrayProps } from "../config/types";

const TrusteeTypeField: React.FC<FieldArrayProps> = ({
  defaultValue,
  index,
}) => {
  const { i18n } = useLingui();
  const { register, unregister } = useFormContext();
  const fieldPath = `trustees[${index}].type`;
  const tooltipText = i18n._(
    t({
      id: "setup.trustee-details.trust-type-tooltip",
      message:
        "If a trustee is an individual, please provide their name and date of birth stated on the trust deed. If a trustee is a corporation, please provide the name and ABN number.",
    })
  );

  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="setup.trustee-details.trust-type-label">Trustee Type</Trans>{" "}
        <Tooltip placement="right" message={tooltipText}>
          <Trans id="setup.trustee-details.trust-type-info">
            Additional information about trustee type.
          </Trans>
        </Tooltip>
      </Label>
      <Select
        id={fieldPath}
        {...register(fieldPath, {
          onChange(event) {
            const value = event.target.value;
            if (value === TrusteeType.Person) {
              unregister([
                `trustees[${index}].name`,
                `trustees[${index}].company_number`,
              ]);
            }
            if (value === TrusteeType.Company) {
              unregister([
                `trustees[${index}].given_name`,
                `trustees[${index}].family_name`,
                `trustees[${index}].date_of_birth`,
              ]);
            }
          },
          required: true,
        })}
        defaultValue={defaultValue}
        data-testid="trustee-type"
      >
        <Option value={TrusteeType.Person}>
          {i18n._(
            t({
              id: "setup.business-details.trustee-type.individual-trustee",
              message: "Individual trustee",
            })
          )}
        </Option>
        <Option value={TrusteeType.Company}>
          {i18n._(
            t({
              id: "setup.business-details.trustee-type.corporate-trustee",
              message: " Corporate trustee",
            })
          )}
        </Option>
      </Select>
    </Field>
  );
};

export const config: Config = {
  name: "type",
  required: true,
  displayName: "Trustee type",
  component: TrusteeTypeField,
};

export default TrusteeTypeField;
