import {
  Field,
  Label,
  Option,
  Box,
  Space,
  Select,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useFormContext } from "react-hook-form";
import { CreditorType } from "@gocardless/api/dashboard/types";
import { MerchantOnboardingSetupEvents } from "src/components/routes/Setup/common/constants/MerchantOnboardingSetupEvents";
import { useSegmentForSetup } from "src/components/routes/Setup/common/hooks/useSegmentForSetup";

import { Field as Config } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

const CreditorTypeField = () => {
  const { i18n } = useLingui();
  const { register } = useFormContext<BusinessDetailsConfig>();
  const { sendEvent } = useSegmentForSetup();
  return (
    <>
      <Field>
        <Box layout="flex">
          <Label htmlFor="creditor_type" css={{ paddingTop: "3px" }}>
            <Trans id="setup.business-details.creditor-type">
              Business Type
            </Trans>
          </Label>
        </Box>
        <Select
          id="creditor_type"
          {...register("creditor_type", {
            required: true,
            onChange: (event) =>
              sendEvent(
                MerchantOnboardingSetupEvents.BusinessTypeDropdownChanged,
                {
                  merchant_type: event.target.value,
                }
              ),
          })}
        >
          <Option value={CreditorType.Company}>
            {i18n._(
              t({
                id: "setup.business-details.company",
                message: "Limited company",
              })
            )}
          </Option>
          <Option value={CreditorType.Charity}>
            {i18n._(
              t({
                id: "setup.business-details.charity",
                message: "Charity/non-profit",
              })
            )}
          </Option>
          <Option value={CreditorType.Individual}>
            {i18n._(
              t({
                id: "setup.business-details.individual",
                message: "Individual/sole trader",
              })
            )}
          </Option>
          <Option value={CreditorType.Partnership}>
            {i18n._(
              t({
                id: "setup.business-details.partnership",
                message: "Partnership",
              })
            )}
          </Option>
          <Option value={CreditorType.Trust}>
            {i18n._(
              t({
                id: "setup.business-details.trust",
                message: "Trust",
              })
            )}
          </Option>
        </Select>
      </Field>
      <Space v={2} />
    </>
  );
};

export const config: Config = {
  name: "creditor_type",
  required: true,
  displayName: "Business type",
  component: CreditorTypeField,
};

export default CreditorTypeField;
