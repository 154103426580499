import { unsupported } from "../unsupported";
import { BankStatementName } from "../bankstatementname";

import * as Company from "./company";
import * as Individual from "./individual";

export const FIELD_CONFIG_US = {
  company: {
    BusinessDetails: Company.BusinessDetails,
    BusinessCategory: Company.BusinessCategory,
    BusinessDirectors: Company.BusinessDirectors,
    BusinessOwners: Company.BusinessOwners,
    BankStatementName,
  },
  individual: {
    BusinessDetails: Individual.BusinessDetails,
    BusinessCategory: Individual.BusinessCategory,
    AboutYou: Individual.AboutYou,
    BankStatementName,
  },

  charity: unsupported,
  partnership: unsupported,
  trust: unsupported,
};
