import { useState } from "react";
import { useBillingRedirectFlowCreate } from "@gocardless/api/dashboard/billing-redirect-flow";
import {
  OrganisationType,
  useOrganisationWithType,
} from "src/queries/organisationType";
import { useCreditorSelf } from "@gocardless/api/dashboard/creditor";
import { useUpgradeList } from "@gocardless/api/dashboard/upgrade";
import { generateRandomAlphaNumericString } from "src/utils/randomGenerator";
import { Route } from "src/common/routing";
import { CreditorResource } from "@gocardless/api/dashboard/types";
import { getFeatureFlag, getConfig } from "src/common/config";
import { getItem, setItem } from "src/common/local-storage/local-storage";
import { PackageType } from "src/components/routes/Setup/common/types/packageType";
import { useOrganisationBillingRequestCreate } from "@gocardless/api/dashboard/organisation-billing-request";
import { isDev } from "src/common/hosts";

import useToast from "../common/hooks/useToast";
import { UseSetupPage } from "../routing/types";
import { getActiveUpgrades } from "../package-selection/v3/useUpgrades/upgradeHelpers";

import { InvoicedV3Packages } from "./../package-selection/v3/packages/package/usePackageStateV3/usePackageStateV3";

export interface UseSetupBillingDetails extends UseSetupPage {
  packageType: PackageType;
  creditor?: CreditorResource;
  createError: boolean;
  hasBillingMandate: boolean;
  isOnPricingV3: boolean;
  createRedirectFlow: () => void;
  createBillingRequestFlow: () => void;
  continueSetup: (data?: { notify?: boolean | undefined }) => void;
}

export const BILLING_REDIRECT_SESSION_TOKEN_KEY = "gc.billing_redirect_session";

const redirect = (href: string) => {
  if (
    getFeatureFlag("enableStubbedRedirects") &&
    // If we are already redirecting we ignore this flag
    !href.includes("/redirect?url=")
  ) {
    window.location.assign(`/redirect?url=${href}`);
    return;
  }
  window.location.href = href;
};

export const useSetupBillingDetails = (): UseSetupBillingDetails => {
  const [createError, setCreateError] = useState(false);

  const { organisation, organisationType } = useOrganisationWithType();
  const isPaymentProvider =
    organisationType === OrganisationType.PaymentProvider;
  const { data } = useCreditorSelf(
    organisation?.links?.primary_creditor || null
  );
  const creditor = data?.creditors;

  const { data: upgradeList } = useUpgradeList();

  const monthlyChargeAddOnSelected = !!getActiveUpgrades(
    upgradeList?.upgrades,
    organisation
  ).length;

  const { onSuccess: continueSetup, onError: toastError } = useToast();
  const [createFlow] = useBillingRedirectFlowCreate({
    onSuccess: (response) => {
      if (response?.redirect_flows && response.redirect_flows.redirect_url) {
        setItem(
          BILLING_REDIRECT_SESSION_TOKEN_KEY,
          response.redirect_flows.session_token
        );
        redirect(response.redirect_flows.redirect_url);
      }
    },
    onError: async (error) => {
      await toastError(error);
      setCreateError(true);
    },
  });

  const [createBillingRequest] = useOrganisationBillingRequestCreate({
    onSuccess: (response) => {
      if (response?.authorisation_url) {
        redirect(response.authorisation_url);
      }
    },
    onError: async (error) => {
      await toastError(error);
      setCreateError(true);
    },
  });

  const createRedirectFlow = () => {
    const dashboardUrl = getConfig().client.urls.dashboard as string;
    setCreateError(false);
    createFlow({
      session_token: generateRandomAlphaNumericString(),
      success_redirect_url: `${dashboardUrl}/setup/billing-details/callback`,
    });
  };

  const createBillingRequestFlow = () => {
    const dashboardUrl = getConfig().client.urls.dashboard;
    const merchantDashboardUrl = getConfig().client.urls.merchantDashboard;
    setCreateError(false);
    const postData = {
      success_redirect_url: `${
        isDev ? merchantDashboardUrl : dashboardUrl
      }/setup/billing-details/billing-request-callback`,
    };
    createBillingRequest(postData);
  };

  const merchantSkippedStep =
    getItem(`gc.${creditor?.id}.skipped_step`) === "true";

  const completed = !!organisation?.has_billing_mandate || merchantSkippedStep;

  // If the merchant is on pricing V3 - display this step for merchants that have chosen an add-on
  // otherwise if they are on pricing V2 - display this step for merchants that have other packages
  const isOnPricingV3 = !!organisation?.is_on_pricing_v3;
  const skipBasedOnPricing = isOnPricingV3
    ? !monthlyChargeAddOnSelected &&
      !InvoicedV3Packages.includes(organisation?.package_state ?? "")
    : organisation?.package_state === PackageType.Standard;

  // Payment providers are always invoiced so we do not need to collect billing details.
  // TODO - we should be able to infer this from the organisation's package.
  const skip = skipBasedOnPricing || isPaymentProvider;

  return {
    loaded: !!organisation,
    route: Route.BillingDetails,
    skip,
    completed,
    hasBillingMandate: !!organisation?.has_billing_mandate,
    packageType: organisation?.package_state as PackageType,
    isOnPricingV3,
    creditor,
    createError,
    createRedirectFlow,
    continueSetup,
    createBillingRequestFlow,
  };
};
