import { useEffect } from "react";
import { get, useFormContext } from "react-hook-form";
import { Trans, t } from "@lingui/macro";
import {
  Field,
  Label,
  Input,
  FormFieldStatus,
  Hint,
} from "@gocardless/flux-react";
import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";
import { useLingui } from "@lingui/react";
import { hasIn } from "lodash";

import { validateAddressField } from "./helpers";

type BuildingNameFieldProps = {
  fieldPath: string;
} & FieldArrayProps;

const BuildingNameField: React.FC<BuildingNameFieldProps> = ({
  defaultValue,
  fieldPath,
}) => {
  const { i18n } = useLingui();

  const {
    register,
    unregister,
    setValue,
    formState: { errors },
  } = useFormContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => unregister(fieldPath), []);
  useEffect(() => {
    setValue(fieldPath, defaultValue);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const errorMessage = hasIn(errors, fieldPath)
    ? `${get(errors, `${fieldPath}`)?.message}`
    : undefined;

  const validationRules = {
    validate: validateAddressField(
      `${i18n._(
        t({
          id: "setup.about-you.address.building-name",
          message: "Please enter a valid building name",
        })
      )}`
    ),
  };

  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="Building name">Building name</Trans>
      </Label>
      <Input
        {...register(fieldPath, validationRules)}
        id={fieldPath}
        defaultValue={defaultValue}
        className="fs-exclude"
      />
      {errorMessage && (
        <Hint status={FormFieldStatus.Danger}>{errorMessage}</Hint>
      )}
    </Field>
  );
};

const DirectorBuildingNameField: React.FC<FieldArrayProps> = (props) => (
  <BuildingNameField
    {...props}
    fieldPath={`directors[${props.index}].building_name`}
  />
);

const OwnerBuildingNameField: React.FC<FieldArrayProps> = (props) => (
  <BuildingNameField
    {...props}
    fieldPath={`shareholders[${props.index}].building_name`}
  />
);

const PersonBuildingNameField: React.FC<FieldArrayProps> = (props) => (
  <BuildingNameField {...props} fieldPath="person.building_name" />
);

const ControlBuildingNameField: React.FC<FieldArrayProps> = (props) => (
  <BuildingNameField
    {...props}
    fieldPath={`control_prongs[${props.index}].building_name`}
  />
);

const TrusteeBuildingNameField: React.FC<FieldArrayProps> = (props) => (
  <BuildingNameField
    {...props}
    fieldPath={`trustees[${props.index}].building_name`}
  />
);

const PartnerBuildingNameField: React.FC<FieldArrayProps> = (props) => (
  <BuildingNameField
    {...props}
    fieldPath={`partners[${props.index}].building_name`}
  />
);

const UBOBuildingNameField: React.FC<FieldArrayProps> = (props) => (
  <BuildingNameField
    {...props}
    fieldPath={`ultimate_beneficial_owners[${props.index}].building_name`}
  />
);

export const directorConfig: Config = {
  name: "building_name",
  displayName: "Building Name",
  component: DirectorBuildingNameField,
};

export const ownerConfig: Config = {
  name: "building_name",
  displayName: "Building Name",
  component: OwnerBuildingNameField,
};

export const personConfig: Config = {
  name: "building_name",
  displayName: "Building Name",
  component: PersonBuildingNameField,
};

export const controlConfig: Config = {
  name: "building_name",
  displayName: "Building Name",
  component: ControlBuildingNameField,
};

export const trusteeConfig: Config = {
  name: "building_name",
  displayName: "Building Name",
  component: TrusteeBuildingNameField,
};

export const partnerConfig: Config = {
  name: "building_name",
  displayName: "Building Name",
  component: PartnerBuildingNameField,
};

export const uboConfig: Config = {
  name: "building_name",
  displayName: "Building Name",
  component: UBOBuildingNameField,
};

export default BuildingNameField;
