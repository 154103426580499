import { unsupported } from "../unsupported";
import { BankStatementName } from "../bankstatementname";
import * as Company from "../SEPA/company";
import * as Individual from "../SEPA/individual";
import * as Charity from "../SEPA/charity";

import * as Partnership from "./partnership";

export const FIELD_CONFIG_SEPA_DE = {
  company: {
    BusinessDetails: Company.BusinessDetails,
    BusinessCategory: Company.BusinessCategory,
    BusinessDirectors: Company.BusinessDirectors,
    BusinessOwners: Company.BusinessOwners,
    BankStatementName,
  },
  individual: {
    BusinessDetails: Individual.BusinessDetails,
    BusinessCategory: Individual.BusinessCategory,
    AboutYou: Individual.AboutYou,
    BankStatementName,
  },
  partnership: {
    BusinessDetails: Partnership.BusinessDetails,
    BusinessCategory: Partnership.BusinessCategory,
    BusinessDirectors: Partnership.BusinessDirectors,
    TrustOwners: Partnership.TrustOwners,
  },
  charity: {
    BusinessDetails: Charity.BusinessDetails,
    AboutYou: Charity.AboutYou,
    BusinessCategory: Charity.BusinessCategory,
    TrusteeDetails: Charity.TrusteeDetails,
    BankStatementName,
  },
  trust: unsupported,
};
