import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

import { PricingPackagesV3, ButtonSelectionType } from "../usePackageStateV3";

/**
 * Get i18n translation message for package type
 */
const getTranslationText = (
  pricingPackage: PricingPackagesV3,
  type: ButtonSelectionType
) => {
  const contactSalesText = i18n._(
    t({
      id: "setup.package-selection-v3.contact-sales",
      message: `Contact sales`,
    })
  );

  switch (type) {
    case ButtonSelectionType.Current:
      return i18n._(
        t({
          id: "setup.package-selection-v3.next",
          message: "Next",
        })
      );
    case ButtonSelectionType.Requested:
      return i18n._(
        t({
          id: "setup.package-selection-v3.requested",
          message: `Requested package`,
        })
      );
    case ButtonSelectionType.Downgrade:
      switch (pricingPackage) {
        case PricingPackagesV3.STANDARD:
        case PricingPackagesV3.STANDARD_INVOICED:
          return i18n._(
            t({
              id: "setup.package-selection-v3.downgrade-to-standard",
              message: `Downgrade to Standard`,
            })
          );
        case PricingPackagesV3.ADVANCED:
        case PricingPackagesV3.ADVANCED_INVOICED:
          return i18n._(
            t({
              id: "setup.package-selection-v3.downgrade-to-advanced",
              message: "Downgrade to Advanced",
            })
          );
        case PricingPackagesV3.PRO:
        case PricingPackagesV3.PRO_INVOICED:
          return i18n._(
            t({
              id: "setup.package-selection-v3.downgrade-to-pro",
              message: "Downgrade to Pro",
            })
          );
        default:
          return contactSalesText;
      }
    case ButtonSelectionType.Upgrade:
      switch (pricingPackage) {
        case PricingPackagesV3.ADVANCED:
        case PricingPackagesV3.ADVANCED_INVOICED:
          return i18n._(
            t({
              id: "setup.package-selection-v3.upgrade-to-advanced",
              message: "Upgrade to Advanced",
            })
          );
        case PricingPackagesV3.PRO:
        case PricingPackagesV3.PRO_INVOICED:
          return i18n._(
            t({
              id: "setup.package-selection-v3.upgrade-to-pro",
              message: "Upgrade to Pro",
            })
          );
        default:
          return contactSalesText;
      }
    case ButtonSelectionType.New:
      switch (pricingPackage) {
        case PricingPackagesV3.STANDARD:
        case PricingPackagesV3.STANDARD_INVOICED:
          return i18n._(
            t({
              id: "setup.package-selection-v3.select-standard",
              message: "Select Standard",
            })
          );
        case PricingPackagesV3.ADVANCED:
        case PricingPackagesV3.ADVANCED_INVOICED:
          return i18n._(
            t({
              id: "setup.package-selection-v3.select-advanced",
              message: "Select Advanced",
            })
          );
        case PricingPackagesV3.PRO:
        case PricingPackagesV3.PRO_INVOICED:
          return i18n._(
            t({
              id: "setup.package-selection-v3.select-pro",
              message: "Select Pro",
            })
          );
        default:
          return contactSalesText;
      }
    default:
      return contactSalesText;
  }
};

export const getPackageButtonText = (
  pkg: PricingPackagesV3,
  merchantRequestPackage: boolean,
  requestedPackage?: PricingPackagesV3
) => {
  const pricingLevels: [PricingPackagesV3, ...PricingPackagesV3[]][] = [
    [PricingPackagesV3.STANDARD, PricingPackagesV3.STANDARD_INVOICED],
    [PricingPackagesV3.ADVANCED, PricingPackagesV3.ADVANCED_INVOICED],
    [PricingPackagesV3.PRO, PricingPackagesV3.PRO_INVOICED],
    [PricingPackagesV3.ENTERPRISE],
  ];

  const buttonTexts = pricingLevels.reduce(
    (texts, packages, i, levels) => {
      const [primaryPackage] = packages;
      const currentIndex = levels.findIndex((l) => l.includes(pkg));

      const getSelectionType = () => {
        // If merchantRequestPackage is false we know it's a new organisation so we should show
        // the default pricing package text.
        if (!merchantRequestPackage) {
          return ButtonSelectionType.New;
        }

        // Current package
        if (packages.includes(pkg)) {
          return ButtonSelectionType.Current;
        }

        // Enterprise package
        if (primaryPackage === PricingPackagesV3.ENTERPRISE) {
          return ButtonSelectionType.Custom;
        }

        // Requested package
        if (requestedPackage && packages.includes(requestedPackage)) {
          return ButtonSelectionType.Requested;
        }

        // Downgrade package
        if (i < currentIndex) return ButtonSelectionType.Downgrade;

        // Upgrade package
        if (i > currentIndex) return ButtonSelectionType.Upgrade;

        return ButtonSelectionType.Custom;
      };

      return {
        ...texts,
        [primaryPackage]: getTranslationText(
          primaryPackage,
          getSelectionType()
        ),
      };
    },
    {} as Record<PricingPackagesV3, string>
  );

  return buttonTexts;
};
