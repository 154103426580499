import { ReactElement } from "react";
import { Field, Input, Label } from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { useFormContext } from "react-hook-form";
import { useLingui } from "@lingui/react";

import { Field as Config, FieldArrayProps } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

import { error, presenceCheck } from "./helpers";

interface TradingNameProps extends FieldArrayProps {
  label: string | ReactElement;
  required?: boolean;
}

const TradingNameField: React.FC<TradingNameProps> = ({
  label,
  required,
  defaultValue,
}) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
  } = useFormContext<BusinessDetailsConfig>();

  return (
    <Field>
      <Label htmlFor="trading_name">{label}</Label>
      <Input
        {...register(
          "trading_name",
          required
            ? presenceCheck(
                i18n._(
                  t({
                    id: "setup.business-details.trading-name-required",
                    message: "Please enter your trading name",
                  })
                )
              )
            : {}
        )}
        name="trading_name"
        id="trading_name"
        className="fs-exclude"
        defaultValue={defaultValue}
      />
      {error(errors, "trading_name")}
    </Field>
  );
};

const CompanyTradingNameField: React.FC<FieldArrayProps> = (props) => (
  <TradingNameField
    {...props}
    label={
      <Trans id="setup.business-details.trading-name-label">Trading name</Trans>
    }
    required
  />
);

const IndividualTradingNameField: React.FC<FieldArrayProps> = (props) => (
  <TradingNameField
    {...props}
    label={
      <Trans id="setup.business-details.trading-name">
        Trading name (optional)
      </Trans>
    }
  />
);

const PartnershipTradingNameField: React.FC<FieldArrayProps> = (props) => (
  <TradingNameField
    {...props}
    label={
      <Trans id="setup.business-details.trading-name-label">Trading name</Trans>
    }
    required
  />
);

const TrustTradingNameField: React.FC<FieldArrayProps> = (props) => (
  <TradingNameField
    {...props}
    label={
      <Trans id="setup.business-details.trading-name-label">Trading name</Trans>
    }
    required
  />
);

const CharityTradingNameField: React.FC<FieldArrayProps> = (props) => (
  <TradingNameField
    {...props}
    label={
      <Trans id="setup.business-details.trading-name-label">Trading name</Trans>
    }
    required
  />
);

export const companyConfig: Config = {
  name: "trading_name",
  displayName: (
    <Trans id="setup.business-details.trading-name-label">Trading name</Trans>
  ),
  component: CompanyTradingNameField,
};

export const individualConfig: Config = {
  name: "trading_name",
  displayName: "Trading name",
  component: IndividualTradingNameField,
};

export const partnershipConfig: Config = {
  name: "trading_name",
  displayName: "Trading name",
  component: PartnershipTradingNameField,
};

export const trustConfig: Config = {
  name: "trading_name",
  displayName: "Trading name",
  required: true,
  component: TrustTradingNameField,
};

export const charityConfig: Config = {
  name: "trading_name",
  displayName: "Trading as",
  component: CharityTradingNameField,
};

export default TradingNameField;
