import { concat, get, hasIn, isArray, isEmpty, values } from "lodash";
import {
  Banner,
  BannerStatus,
  BannerVariant,
  Box,
  Color,
  CSSRulesFunction,
  HoverEffect,
  P,
  PlainButton,
  useTheme,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";

interface FormErrorProps {
  errors: {};
}

const formErrorStyle: CSSRulesFunction = (theme) => ({
  padding: "0",
  paddingLeft: theme.spacing(1),
  borderRadius: 0,
  filter: "none",
  background: "none",
  borderLeft: `5px solid ${theme.color(Color.Alert_400)}`,
  "> div > div:nth-of-type(2)": {
    display: "none",
  },
  "> div": {
    p: {
      color: theme.color(Color.Alert_400),
    },
  },
});

const FormError: React.FC<FormErrorProps> = ({ errors }) => {
  const { theme } = useTheme();
  const { i18n } = useLingui();
  const { sendEvent } = useSegment();

  const errorList = values(errors).reduce((finalErrors, error) => {
    let newErrors = [error];
    if (isArray(error)) {
      newErrors = error.reduce(
        (fieldErrorList, fieldError) => [
          ...fieldErrorList,
          ...values(fieldError),
        ],
        []
      );
    } else if (!hasIn(error, "ref")) {
      newErrors = values(error);
    }
    return concat(finalErrors, newErrors);
  }, []);

  values(errorList).forEach((error) => {
    sendEvent(TrackingEvent.VERIFICATION_FORM_ERROR, {
      component_id: error?.ref?.id,
      message: error?.message,
    });
  });

  return !isEmpty(errors) ? (
    <Box spaceAbove={2}>
      <Banner
        variant={BannerVariant.Light}
        leftAccessory={{
          type: BannerLeftAccessoryType.Status,
          status: BannerStatus.Alert,
        }}
        css={formErrorStyle(theme)}
        title={`${i18n._(
          t({ id: "notifications.error.title", message: "There was an error" })
        )}:`}
      >
        {values(errorList).map((e, i) => (
          <P key={i}>
            <PlainButton
              textDecoration="underline"
              effect={HoverEffect.TextDecoration}
              onClick={() =>
                document.getElementById(get(e, "ref.id"))?.scrollIntoView()
              }
            >
              {get(e, "message")}
            </PlainButton>
          </P>
        ))}
      </Banner>
    </Box>
  ) : null;
};

export default FormError;
