import { useSearchParam } from "react-use";
import { useOrganisation } from "src/queries/organisation";

import { getBasePackage } from "./getBasePackage/getBasePackageState";
import { getPackageButtonText } from "./getPackageButtonText/getPackageButtonText";

export enum PricingPackagesV3 {
  STANDARD = "standard_v3",
  STANDARD_INVOICED = "standard_v3_invoiced",
  ADVANCED = "advanced_v3",
  ADVANCED_INVOICED = "advanced_v3_invoiced",
  PRO = "pro_v3",
  PRO_INVOICED = "pro_v3_invoiced",
  ENTERPRISE = "enterprise",
}

export const InvoicedV3Packages = [
  "standard_v3_invoiced",
  "advanced_v3_invoiced",
  "pro_v3_invoiced",
];

export enum ButtonSelectionType {
  Upgrade = "upgrade",
  Downgrade = "downgrade",
  Current = "current",
  Requested = "requested",
  Custom = "custom",
  New = "new",
}

const useRequestedPackage = () => {
  const param = "zd_request_package";
  const requestParam = useSearchParam(param);
  const request =
    requestParam || window.localStorage.getItem(param) || undefined;

  const requestedPackage = Object.values<string>(PricingPackagesV3).find(
    (value) => value === request
  ) as PricingPackagesV3 | undefined;

  const isNewRequest = requestParam && requestedPackage;
  if (isNewRequest) window.localStorage.setItem(param, requestedPackage);

  return {
    requestedPackage,
    showRequestedNotification: Boolean(isNewRequest),
  };
};

export const usePackageStateV3 = () => {
  const organisation = useOrganisation();
  const { requestedPackage, showRequestedNotification } = useRequestedPackage();

  const merchantRequestedPackage =
    !!organisation?.organisation_preferences?.merchant_has_requested_package ||
    organisation?.package_state !== PricingPackagesV3.STANDARD;

  const packageState = organisation?.package_state as PricingPackagesV3;
  const activeBasePackage = getBasePackage(packageState);
  const packageButtonText = getPackageButtonText(
    packageState,
    merchantRequestedPackage,
    requestedPackage
  );

  return {
    packageState,
    activeBasePackage,
    packageButtonText,
    merchantRequestedPackage,
    requestedPackage,
    showRequestedNotification,
    isPricingV3: organisation?.is_on_pricing_v3,
  };
};
