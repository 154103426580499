export const generateRandomAlphaNumericString = (length = 20) => {
  const RANDOM_TOKEN_CHARACTERS =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

  let result = "";

  for (let i = 0; i < length; i++) {
    result +=
      RANDOM_TOKEN_CHARACTERS[
        Math.floor(Math.random() * RANDOM_TOKEN_CHARACTERS.length)
      ];
  }

  return result;
};
