import {
  AmlUboPersonWithAddressResource,
  CreditorDetailResource,
} from "@gocardless/api/dashboard/types";

import { uboSelfDeclaredBuilder } from "./owners";

export interface TrustOwners {
  ultimate_beneficial_owners: AmlUboPersonWithAddressResource[];
}

export const getTrustOwners = (
  creditorDetails: CreditorDetailResource | undefined
): TrustOwners => {
  if (!creditorDetails)
    return {
      ultimate_beneficial_owners: [],
    };
  const ultimate_beneficial_owners =
    creditorDetails?.detail?.ultimate_beneficial_owners || [];
  const uboSelfDeclared = uboSelfDeclaredBuilder(creditorDetails);

  const emptyUbo = {
    ultimate_beneficial_owners: [
      {
        given_name: "",
        family_name: "",
        date_of_birth: "",
        country_code: "AU",
      },
    ],
  };

  return uboSelfDeclared
    ? ultimate_beneficial_owners.length > 0
      ? { ultimate_beneficial_owners }
      : emptyUbo
    : emptyUbo;
};

const addressPresent = (person: AmlUboPersonWithAddressResource) =>
  person.street && person.city && person.postal_code;

/*
  Given a list of ubos returns an array of 2 indexes.
  If the index is > -1, the ubo on this index has their address present.
  If the index is -1, the address is absent.
  Ascending ordering for indexes.
  */
export const getUboIndexes = (ubos: AmlUboPersonWithAddressResource[]) => {
  let index1 = -1;
  let index2 = -1;

  ubos.forEach((ubo, index) => {
    if (addressPresent(ubo)) {
      if (index1 === -1) {
        index1 = index;
      } else if (index2 === -1) {
        index2 = index;
      } else {
        return;
      }
    }
  });
  return [index1, index2];
};

export const mapCountryCodeToUbo = (
  ubos: AmlUboPersonWithAddressResource[],
  country_code: string
) =>
  // map the country code only when it's missing
  ubos.map((i) =>
    i.country_code
      ? i
      : {
          ...i,
          country_code,
        }
  );
