import { Currency } from "src/common/currencies";
import {
  IntervalUnit,
  PlanResource,
  SubscriptionResource,
} from "@gocardless/api/dashboard/types";
import { getPlanIntervalMessage } from "src/utils/planDescription";
import { TrackingEvent } from "src/common/trackingEvents";

import { PaymentType } from "../routes/SetupPayments/common/type";

export const currencyMapping: Record<Currency, string> = {
  [Currency.Aud]: "AU$",
  [Currency.Cad]: "CA$",
  [Currency.Dkk]: "kr.",
  [Currency.Eur]: "€",
  [Currency.Gbp]: "£",
  [Currency.Nzd]: "NZ$",
  [Currency.Sek]: "kr",
  [Currency.Usd]: "US$",
};

export const convertAmountToCurrency = (amount: number, currency: Currency) =>
  `${currencyMapping[currency]}${Math.floor(amount / 100)}.${(amount % 100)
    .toString()
    .padStart(2, "0")}`;

const getPlanInterval = (intervalUnit?: IntervalUnit, interval?: number) => {
  if (!intervalUnit || !interval) {
    return "";
  }
  if (interval === 1) {
    return intervalUnit;
  } else {
    return getPlanIntervalMessage(intervalUnit, interval);
  }
};

export const getPlanPaymentDescription = (
  resource: PlanResource | SubscriptionResource
) => {
  const { amount, currency, interval_unit: intervalUnit, interval } = resource;

  return `${convertAmountToCurrency(
    amount as number,
    currency as Currency
  )} ${getPlanInterval(intervalUnit, interval as number)}`;
};

export const getNumberOfPayments = (plan: PlanResource) => {
  if (!plan?.count) {
    return "";
  }
  return `(${plan.count} payments)`;
};

export const getShareCompletedTrackingNameByPaymentType = (
  paymentType?: PaymentType
) => {
  const trackingName = {
    [PaymentType.Mandate]: TrackingEvent.CUSTOMERS_SHARE_COMPLETED,
    [PaymentType.Plan]: TrackingEvent.PLANS_SHARE_COMPLETED,
    [PaymentType.OneOff]: TrackingEvent.PAYLINKS_SHARE_COMPLETED,
  };
  return paymentType
    ? trackingName[paymentType]
    : TrackingEvent.CUSTOMERS_SHARE_COMPLETED;
};

export const getTemplateEditedTrackingNameByPaymentType = (
  paymentType?: PaymentType
) => {
  const trackingName = {
    [PaymentType.Mandate]: TrackingEvent.CUSTOMERS_TEMPLATE_EDITED,
    [PaymentType.Plan]: TrackingEvent.PLANS_TEMPLATE_EDITED,
    [PaymentType.OneOff]: TrackingEvent.PAYLINKS_TEMPLATE_EDITED,
  };
  return paymentType
    ? trackingName[paymentType]
    : TrackingEvent.CUSTOMERS_TEMPLATE_EDITED;
};
