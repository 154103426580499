import { ReactElement, useEffect } from "react";
import { Field, Label, Input } from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useFormContext } from "react-hook-form";

import { Field as Config, FieldArrayProps } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

import { error, presenceCheck } from "./helpers";

interface LegalNameProps extends FieldArrayProps {
  label?: string | ReactElement;
}

const LegalNameField: React.FC<LegalNameProps> = ({ label, defaultValue }) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<BusinessDetailsConfig>();
  useEffect(() => {
    setValue("legal_name", defaultValue);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);
  return (
    <Field>
      <Label htmlFor="legal_name">
        {label ?? (
          <Trans id="setup.business-details.legal-name-of-company">
            Legal name of company
          </Trans>
        )}
      </Label>
      <Input
        id="legal_name"
        {...register(
          "legal_name",
          presenceCheck(
            i18n._(
              t({
                id: "setup.business-details.legal-name-required-error",
                message: "Please enter the official company name",
              })
            )
          )
        )}
        className="fs-exclude"
        defaultValue={defaultValue}
      />
      {error(errors, "legal_name")}
    </Field>
  );
};

const PartnershipLegalNameField: React.FC<FieldArrayProps> = (props) => (
  <LegalNameField
    {...props}
    label={
      <Trans id="setup.business-details.partnership-legal-name">
        Legal name of partnership
      </Trans>
    }
  />
);

const TrustLegalNameField: React.FC<FieldArrayProps> = (props) => (
  <LegalNameField
    {...props}
    label={
      <Trans id="setup.business-details.trust-official-name-label">
        Official Trust name
      </Trans>
    }
  />
);

export const config: Config = {
  name: "legal_name",
  required: true,
  displayName: (
    <Trans id="setup.business-details.legal-name-label">Legal name</Trans>
  ),
  component: LegalNameField,
};

export const partnershipConfig: Config = {
  name: "legal_name",
  required: true,
  displayName: (
    <Trans id="setup.business-details.legal-name-label">Legal name</Trans>
  ),
  component: PartnershipLegalNameField,
};

export const trustConfig: Config = {
  name: "legal_name",
  required: true,
  displayName: (
    <Trans id="setup.business-details.trust-official-name-label">
      Official Trust name
    </Trans>
  ),
  component: TrustLegalNameField,
};

export default LegalNameField;
