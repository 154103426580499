import * as Fields from "../fields";
import * as Company from "../SEPA/company";

export const BusinessDetails = [
  [Fields.Country, Fields.CreditorType],
  {
    name: "partnershipDetails",
    displayName: "Partnership Details",
    component: [
      Fields.DEPartnershipTypeField,
      Fields.PartnershipLegalName,
      Fields.PartnershipTradingName,
      Fields.DEPartnershipNumberField,
      {
        name: "registered_address",
        displayName: "Registered address",
        component: [
          Fields.CompanyAddressLineOne,
          Fields.CompanyAddressLineTwo,
          Fields.CompanyAddressLineThree,
          Fields.CompanyCity,
          Fields.CompanyPostalCode,
        ],
      },
    ],
  },
];

export const BusinessCategory = [...Company.BusinessCategory];

export const BusinessDirectors = [
  {
    name: "partnerDetails",
    displayName: "Partner Details",
    component: [
      Fields.PartnerGivenName,
      Fields.PartnerFamilyName,
      Fields.PartnerDateOfBirth,
      Fields.PartnerIsUboField,
    ],
  },
  Fields.PartnerSelect,
  Fields.PartnerCountryCode,
  {
    name: "partnerAddress",
    displayName: "Partner Address",
    component: [
      Fields.PartnerFlatNumber,
      Fields.PartnerBuildingNumber,
      Fields.PartnerBuildingName,
      Fields.PartnerStreet,
      Fields.PartnerCity,
      Fields.PartnerPostalCode,
    ],
  },
];

export const TrustOwners = [
  {
    name: "uboDetails",
    displayName: "Ultimate Beneficial Owner Details",
    component: [
      Fields.UBOGivenName,
      Fields.UBOFamilyName,
      Fields.UBODateOfBirth,
      Fields.UBOPlaceOfBirth,
    ],
  },
];
