import * as Fields from "../fields";
import * as SEPA from "../SEPA/individual";

// same as SEPA config with addition of TaxJurisidiction field
export const BusinessDetails = [
  Fields.Country,
  Fields.CreditorType,
  Fields.IndividualTradingName,
  Fields.FRVATNumber,
  Fields.FRTaxJurisdiction,
  Fields.FRIndividualNumberField,
];

export const BusinessCategory = SEPA.BusinessCategory;

export const AboutYou = SEPA.AboutYou;
