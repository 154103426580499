import { Route } from "src/common/routing";
import { useSetupData } from "src/components/routes/Setup/common/hooks/useSetupData";
import { usePaobVerification } from "src/components/routes/Setup/common/hooks/usePaobVerification";

import { UseSetupPage, UseSetupPageCallbacks } from "../routing/types";

export interface MoreBusinessDetailsConfig {
  annual_revenue?: string | null;
  number_of_employees?: string | null;
  creditor_type?: string | null;
}

export interface UseMoreBusinessDetails extends UseSetupPage {
  moreBusinessDetails: MoreBusinessDetailsConfig;
  submitMoreBusinessDetails: (data: MoreBusinessDetailsConfig) => void;
}

export function useMoreBusinessDetails({
  onSuccess = () => {},
  onError = () => {},
}: UseSetupPageCallbacks = {}): UseMoreBusinessDetails {
  const { creditorDetails, updateCreditorDetail } = useSetupData(
    onSuccess,
    onError
  );
  const { loaded: paobVerificationLoaded, gcSasVerificationEnabled } =
    usePaobVerification();
  const creditor_type = creditorDetails?.creditor_type;
  const annual_revenue = creditorDetails?.detail?.annual_revenue;
  const number_of_employees = creditorDetails?.detail?.number_of_employees;
  const moreBusinessDetails = {
    annual_revenue,
    number_of_employees,
    creditor_type,
  };

  const submitMoreBusinessDetails = (data: MoreBusinessDetailsConfig): void => {
    updateCreditorDetail({
      creditor_type,
      detail: { ...data },
    });
  };
  return {
    loaded: !!creditorDetails && paobVerificationLoaded,
    completed: !!annual_revenue && !!number_of_employees,
    skip: !gcSasVerificationEnabled,
    route: Route.MoreBusinessDetails,
    moreBusinessDetails,
    submitMoreBusinessDetails,
  };
}
