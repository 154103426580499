import { useEffect } from "react";
import {
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { HomeSchemeEnum } from "@gocardless/api/dashboard/types";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

import { Field as Config } from "../config/types";

interface DesiredSunNameFieldProps {
  scheme: HomeSchemeEnum;
  disabled: boolean;
}

const DesiredSunNameField = ({
  scheme,
  disabled,
}: DesiredSunNameFieldProps) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const newDesiredSunName = watch("desired_sun_name");

  useEffect(() => {
    if (!newDesiredSunName) return;

    const cleanNewDesiredSunName = newDesiredSunName
      .toUpperCase()
      .replace(/[^A-Z0-9]/, "");

    setValue("desired_sun_name", cleanNewDesiredSunName);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDesiredSunName]);

  const maxLengthByScheme: Record<HomeSchemeEnum, number> = {
    [HomeSchemeEnum.Ach]: 16,
    [HomeSchemeEnum.Autogiro]: 80,
    [HomeSchemeEnum.Bacs]: 18,
    [HomeSchemeEnum.Becs]: 16,
    [HomeSchemeEnum.BecsNz]: 20,
    [HomeSchemeEnum.Betalingsservice]: 60,
    [HomeSchemeEnum.Pad]: 15,
    [HomeSchemeEnum.PayTo]: 16,
    [HomeSchemeEnum.Sepa]: 30,

    // IBP schemes
    [HomeSchemeEnum.FasterPayments]: 18,
    [HomeSchemeEnum.SepaCreditTransfer]: 30,
    [HomeSchemeEnum.SepaInstantCreditTransfer]: 30,
  };

  return (
    <Field>
      <Label htmlFor="desired_sun_name">
        <Trans id="setup.bank-statement-name.organisation-name-shown-on-bank-statements">
          Organisation name shown on bank statements
        </Trans>
      </Label>
      <Input
        {...register("desired_sun_name", {
          required: !disabled
            ? i18n._(
                t({
                  id: "setup.bank-statement-name.desired-sun-name-required",
                  message: "Please enter your organisation name",
                })
              )
            : undefined,
          maxLength: !disabled
            ? {
                value: maxLengthByScheme[scheme],
                message: i18n._(
                  t({
                    id: "setup.bank-statement-name.desired-sun-name-maxlength",
                    message: `Must be ${maxLengthByScheme[scheme]} characters or less`,
                  })
                ),
              }
            : undefined,
        })}
        id="desired_sun_name"
        className="fs-exclude"
        disabled={disabled}
      />
      {errors.desiredSunName && (
        <Hint status={FormFieldStatus.Danger}>
          {errors?.desiredSunName?.message as string}
        </Hint>
      )}
    </Field>
  );
};

export const config: Config = {
  name: "desired_sun_name",
  displayName: (
    <Trans id="setup.bank-statement-name.organisation-name-shown-on-bank-statements">
      Organisation name shown on bank statements
    </Trans>
  ),
  component: DesiredSunNameField,
};

export default DesiredSunNameField;
