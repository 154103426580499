import { get, isArray } from "lodash";
import { createElement } from "react";
import {
  Field,
  FieldAttributes,
} from "src/components/routes/Setup/common/config/types";

// @todo move to useConfig / render fields hook??
/**
 * Renders the fields passed along with the props provided
 * through field attributes or the component provided for
 * the fields if the config fields has an array of fields.
 *
 * @fields  The fields from config that needs to be rendered.
 * @fieldAttributes
 * The attributes using which the fields will be rendered.
 */
export const renderFields = (
  fields: Field[],
  fieldAttributes: FieldAttributes
) =>
  fields?.map((field, i) => {
    const attributes = get(fieldAttributes, field.name);
    if (!attributes || attributes.canRender === false) {
      return null;
    }
    const { component, props } = attributes;
    const { required, displayName } = field;
    const fieldProps = { ...props, required, displayName };

    return isArray(field.component)
      ? createElement(component as React.FC, {
          key: i,
          ...{
            fields: field.component,
            ...fieldProps,
          },
        })
      : createElement(field.component as React.FC, {
          key: i,
          ...fieldProps,
        });
  });
