export const BASIC_PERSON_FIELDS = [
  "given_name",
  "family_name",
  "date_of_birth",
  "place_of_birth",
];

export const INDIVIDUAL_NUMBER_FIELDS = [
  "social_security_number",
  "passport_number",
  "passport_country",
];

export const ADDRESS_FIELDS = [
  "flat_number",
  "building_number",
  "building_name",
  "street",
  "city",
  "region",
  "postal_code",
  "country_code",
];
