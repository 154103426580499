import { useState } from "react";
import _ from "lodash";
import {
  Field,
  Label,
  Input,
  Tooltip,
  Box,
  P,
  PlainLink,
  H6,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useFormContext } from "react-hook-form";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { CountryCodes } from "src/common/country";

import { Field as Config, FieldArrayProps } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";
import { companyNumberValidators } from "../../business-details/validator";

import { error, presenceCheck } from "./helpers";

export enum LocaleNameMap {
  AT = "Firmenbuchnummer",
  AU = "Australian Business Number",
  BE = "BTW/TVA/NWSt",
  BG = "ЕИК",
  CA = "Corporation Number",
  CH = "UID",
  CY = "Registration Number",
  CZ = "Identifikační číslo osoby (IČO)",
  DE = "Handelsregisternummer",
  DK = "CVR",
  ES = "NIF/CIF",
  FI = "Y-tunnus",
  FR = "SIREN",
  HR = "MBS",
  HU = "Cégjegyzékszám",
  IT = "Numero REA",
  LU = "Numéro RCS",
  MT = "Company ID",
  NL = "KvK-nummer",
  NO = "Organisasjonsnummer",
  NZ = "Company Number",
  PT = "Número de Identificação Coletiva (NIPC)",
  PL = "Numer KRS",
  RO = "Nr. Order R.C",
  SE = "Organisationsnummer",
  SI = "Registration Number",
  SK = "Vložka", // TODO = using Vložka for now, might change to IČO
  US = "Taxpayer Identification Number",
  ZA = "Registration Number",
}

const CompanyNumberField: React.FC<FieldArrayProps> = ({ defaultValue }) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext<BusinessDetailsConfig>();
  const [geo] = useState(getValues()?.geo);
  const map = LocaleNameMap[geo as keyof typeof LocaleNameMap];
  const label = !_.isEmpty(map) ? map : "Company number";
  const displayName = !_.isEmpty(map) ? (
    <>{map}</>
  ) : (
    <Trans id="Company number">Company number</Trans>
  );

  const { isFlagEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.US_VERIFICATION_IMPROVEMENTS,
  });

  const usVerificationImprovementsEnabled =
    isFlagEnabled && geo === CountryCodes.US;

  const isGB = geo === CountryCodes.GB;

  const findOutMoreLink = (
    <PlainLink
      decoration="underline"
      target="_blank"
      href="https://www.irs.gov/individuals/international-taxpayers/taxpayer-identification-numbers-tin#ein"
    >
      <Trans>Find out more</Trans>
    </PlainLink>
  );
  const tooltipMessage = (
    <>
      <H6 preset={TypePreset.Heading_02}>
        <Trans id="setup.label.tax-id-number">
          Taxpayer Identification Number
        </Trans>
      </H6>

      <P preset={TypePreset.Body_01}>
        <Trans>This is the same as an Employer Identification Number.</Trans>{" "}
        {findOutMoreLink}.
      </P>
    </>
  );

  return (
    <Field>
      <Box layout="flex" flexDirection="row">
        <Label htmlFor="company_number">{displayName}</Label>
        {usVerificationImprovementsEnabled && (
          <Tooltip placement="top" message={tooltipMessage}>
            <Trans>
              Additional information about Taxpayer Identification Number
            </Trans>
          </Tooltip>
        )}
      </Box>
      <Input
        id="company_number"
        {...register("company_number", {
          ...presenceCheck(
            `${i18n._(
              t({
                id: "setup.business-details.company-number-required-error",
                message: `Please enter a valid ${label.toLocaleLowerCase()}`,
              })
            )}`
          ),
          ...(isGB
            ? {
                validate: (value?: string) =>
                  !companyNumberValidators.UK(value ?? "").valid
                    ? i18n._(
                        t({
                          id: "setup.business-details.company-number-required-error-gb",
                          message:
                            "Please enter a valid company number e.g. BR123456 or 12345678",
                        })
                      )
                    : true,
              }
            : {}),
        })}
        className="fs-exclude"
        defaultValue={defaultValue}
      />
      {error(errors, "company_number")}
    </Field>
  );
};

const CompanyNumberLabel: React.FC = () => {
  const { getValues } = useFormContext<BusinessDetailsConfig>();
  const [geo] = useState(getValues()?.geo);
  const map = LocaleNameMap[geo as keyof typeof LocaleNameMap];
  const label = !_.isEmpty(map) ? (
    <>{map}</>
  ) : (
    <Trans id="Company number">Company number</Trans>
  );
  return label;
};

export const config: Config = {
  name: "company_number",
  required: true,
  displayName: CompanyNumberLabel,
  component: CompanyNumberField,
};

export const USConfig: Config = {
  name: "company_number",
  required: true,
  displayName: LocaleNameMap.US,
  component: CompanyNumberField,
};

export default CompanyNumberField;
