import { unsupported } from "../unsupported";
import { BankStatementName } from "../bankstatementname";
import * as Individual from "../GLOBAL/individual";
import * as Company from "../GLOBAL/company";

export const FIELD_CONFIG_GB_NZ = {
  company: {
    BusinessDetails: Company.BusinessDetails,
    BusinessCategory: Company.BusinessCategory,
    BusinessDirectors: Company.BusinessDirectors,
    BusinessOwners: Company.BusinessOwners,
    BankStatementName,
  },
  individual: {
    BusinessDetails: Individual.BusinessDetails,
    BusinessCategory: Individual.BusinessCategory,
    AboutYou: Individual.AboutYou,
    BankStatementName,
  },
  charity: unsupported,
  partnership: unsupported,
  trust: unsupported,
};
