import * as Fields from "../fields";

export const BusinessDetails = [
  [Fields.Country, Fields.CreditorType],
  {
    name: "charityDetails",
    displayName: "Charity Details",
    component: [
      Fields.CharityType,
      Fields.CharityNumber,
      Fields.CharityName,
      Fields.CharityTradingName,
      {
        name: "registered_address",
        displayName: "Registered address",
        component: [
          Fields.CompanyAddressLineOne,
          Fields.CompanyAddressLineTwo,
          Fields.CompanyAddressLineThree,
          Fields.CompanyCity,
          Fields.CompanyPostalCode,
        ],
      },
      Fields.VATNumber,
    ],
  },
];

export const AboutYou = [
  {
    name: "personDetails",
    displayName: "Person Details",
    component: [
      Fields.PersonGivenName,
      Fields.PersonFamilyName,
      Fields.PersonDateOfBirth,
      Fields.PersonPlaceOfBirthField,
    ],
  },
  Fields.PersonCountryCode,
  {
    name: "personAddress",
    displayName: "Person Address",
    component: [
      Fields.PersonFlatNumber,
      Fields.PersonBuildingNumber,
      Fields.PersonBuildingName,
      Fields.PersonStreet,
      Fields.PersonCity,
      Fields.PersonRegion,
      Fields.PersonPostalCode,
    ],
  },
  Fields.AccountHolderIsBeneficialOwner,
];

export const BusinessCategory = [
  {
    name: "businessCategory",
    displayName: "Business Category",
    component: [Fields.CategoryDescription],
  },
];

export const TrusteeDetails = [
  {
    name: "trusteeDetails",
    displayName: "Trustee Details",
    component: [
      Fields.TrusteeGivenName,
      Fields.TrusteeFamilyName,
      Fields.TrusteeDateOfBirth,
      Fields.TrusteePlaceOfBirth,
    ],
  },
  Fields.TrusteeCountryOfResidence,
  {
    name: "trusteeAddress",
    displayName: "Trustee Address",
    component: [
      Fields.TrusteeFlatNumber,
      Fields.TrusteeBuildingNumber,
      Fields.TrusteeBuildingName,
      Fields.TrusteeStreet,
      Fields.TrusteeCity,
      Fields.TrusteePostalCode,
    ],
  },
];
