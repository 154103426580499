import { Field, Label, Input } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

import { Field as Config, FieldArrayProps } from "../config/types";

import { error, presenceCheck } from "./helpers";

const TrustNumberField: React.FC<FieldArrayProps> = ({ defaultValue }) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Field>
      <Label htmlFor="trust_number">
        <Trans id="setup.business-details.trust-number-label">
          Trust number
        </Trans>
      </Label>
      <Input
        id="trust_number"
        placeholder="e.g. ABN (12 345 678 912) or ACN (123 456 789)"
        {...register(
          "trust_number",
          presenceCheck(
            i18n._(
              t({
                id: "setup.business-details.trust-number-required",
                message: "Please enter a valid Trust number.",
              })
            )
          )
        )}
        className="fs-exclude"
        defaultValue={defaultValue}
      />
      {error(errors, "trust_number")}
    </Field>
  );
};

export const config: Config = {
  name: "trust_number",
  displayName: "Trust Number",
  required: true,
  component: TrustNumberField,
};

export default TrustNumberField;
