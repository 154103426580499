import { unsupported } from "../unsupported";
import { BankStatementName } from "../bankstatementname";
import { FIELD_CONFIG_SEPA } from "../SEPA";
import * as Charity from "../SEPA/charity";

export const FIELD_CONFIG_SEPA_IE = {
  company: {
    ...FIELD_CONFIG_SEPA.company,
  },
  individual: {
    ...FIELD_CONFIG_SEPA.individual,
  },
  charity: {
    BusinessDetails: Charity.BusinessDetails,
    AboutYou: Charity.AboutYou,
    BusinessCategory: Charity.BusinessCategory,
    TrusteeDetails: Charity.TrusteeDetails,
    BankStatementName,
  },
  partnership: unsupported,
  trust: unsupported,
};
