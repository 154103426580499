import DesiredSunNameField from "../fields/DesiredSunNameField";
/**
 * Global Config For This Field
 */
export const BankStatementName = [
  {
    name: "bankStatementName",
    displayName: "Bank Statement Name",
    component: [DesiredSunNameField],
  },
];
