import {
  AlignItems,
  Box,
  ColorPreset,
  FontWeight,
  HoverEffect,
  JustifyContent,
  PlainButton,
  Separator,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useEffect, useState } from "react";

interface FormCardProps {
  heading: string;
  initialIsOpen?: boolean;
  onEdit?: () => void;
  children: ({ isOpen }: { isOpen: boolean }) => JSX.Element;
}

export default function FormCard({
  heading,
  initialIsOpen = false,
  onEdit = () => {},
  children,
}: FormCardProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [prevInitialIsOpen, setPrevInitialIsOpen] = useState(initialIsOpen);

  useEffect(() => {
    // overwrite internal isOpen if initialIsOpen prop is changed
    if (initialIsOpen !== prevInitialIsOpen) {
      setIsOpen(initialIsOpen);
      setPrevInitialIsOpen(initialIsOpen);
    }
  }, [initialIsOpen, prevInitialIsOpen]);

  const handleOpen = () => {
    onEdit();
    setIsOpen(true);
  };

  return isOpen ? (
    <Box
      gutterH={2}
      gutterV={2}
      bg={ColorPreset.BackgroundLight_03}
      borderWidth={1}
      borderColor={ColorPreset.BorderOnLight_03}
      borderRadius={2}
    >
      {children({ isOpen })}
    </Box>
  ) : (
    <Box
      bg={ColorPreset.BackgroundLight_01}
      borderWidth={1}
      borderColor={ColorPreset.BorderOnLight_04}
      borderRadius={2}
    >
      <Box
        gutterH={2}
        gutterV={1.5}
        layout="flex"
        justifyContent={JustifyContent.SpaceBetween}
        alignItems={AlignItems.Center}
      >
        <Text size={4} weight={FontWeight.Bold}>
          {heading}
        </Text>
        <PlainButton
          onClick={handleOpen}
          effect={HoverEffect.TextDecoration}
          textDecoration="underline"
        >
          <Trans id="Edit">Edit</Trans>
        </PlainButton>
      </Box>
      <Separator />
      <Box gutterH={2} gutterV={2}>
        {children({ isOpen })}
      </Box>
    </Box>
  );
}
