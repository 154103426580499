import { Trans } from "@lingui/macro";

import * as Fields from "../fields";
import * as Company from "../GLOBAL/company";

/**
 * GB Company Business Details
 * includes additional fields so
 * all are defined here
 */
export const BusinessDetails = [
  [Fields.Country, Fields.CreditorType],
  {
    name: "companyDetails",
    displayName: "Company Details",
    component: [
      Fields.LegalName,
      Fields.CompanyTradingName,
      Fields.CompanyNumber,
      {
        name: "registered_address",
        displayName: (
          <Trans id="setup.business-details.registered-address">
            Registered address
          </Trans>
        ),
        component: [
          Fields.CompanyAddressLineOne,
          Fields.CompanyAddressLineTwo,
          Fields.CompanyAddressLineThree,
          Fields.CompanyCity,
          Fields.CompanyPostalCode,
        ],
      },
      Fields.TaxJurisdiction,
    ],
  },
  Fields.VATNumber,
];

/**
 * GB Business Category is the same
 * as the global configuration
 */
export const BusinessCategory = [...Company.BusinessCategory];

/**
 * GB Business Directors are the same
 * as the global configuration
 */
export const BusinessDirectors = [...Company.BusinessDirectors];

/**
 * GB Business Owners are the same
 * as the global configuration
 */
export const BusinessOwners = [...Company.BusinessOwners];
