import { get, hasIn } from "lodash";
import { useFormContext } from "react-hook-form";
import {
  Field,
  Label,
  Input,
  Hint,
  FormFieldStatus,
} from "@gocardless/flux-react";
import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";
import { VALID_ITIN_REGEX } from "src/utils/inputValidation";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

type TaxpayerIdNumberFieldProps = {
  fieldPath: string;
} & FieldArrayProps;

const TaxpayerIdNumberField: React.FC<TaxpayerIdNumberFieldProps> = ({
  defaultValue,
  fieldPath,
}) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="setup.label.tax-id-number">
          Taxpayer Identification Number
        </Trans>
      </Label>
      <Input
        {...register(fieldPath, {
          required: i18n._(
            t({
              id: "setup.error.please-enter-tax-payer-id-number",
              message: "Please enter a Taxpayer identification  Number",
            })
          ),
          validate: (number) =>
            !VALID_ITIN_REGEX.test(number)
              ? i18n._(
                  t({
                    message:
                      "Please enter a valid Taxpayer Identification  Number",
                  })
                )
              : true,
        })}
        id={fieldPath}
        defaultValue={defaultValue}
        className="fs-exclude"
      />
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}`)?.message as string}
        </Hint>
      )}
    </Field>
  );
};

const PersonTaxpayerIdNumberField: React.FC<FieldArrayProps> = (props) => (
  <TaxpayerIdNumberField {...props} fieldPath="individual_number" />
);

export const personConfig: Config = {
  name: "taxpayer_identification_number",
  displayName: "Taxpayer Identification Number",
  component: PersonTaxpayerIdNumberField,
};

export default TaxpayerIdNumberField;
