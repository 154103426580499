import { useEffect } from "react";
import {
  Field,
  Label,
  Input,
  Hint,
  FormFieldStatus,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { get, hasIn } from "lodash";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import { Field as Config, FieldArrayProps } from "../config/types";

import { presenceCheck, validateAddressField } from "./helpers";

type CityFieldProps = {
  fieldPath: string;
  requiredError: string;
} & FieldArrayProps;

const CityField: React.FC<CityFieldProps> = ({
  defaultValue,
  fieldPath,
  requiredError,
}) => {
  const { i18n } = useLingui();

  const {
    register,
    formState: { errors },
    setValue,
    clearErrors: clearError,
  } = useFormContext();
  useEffect(() => {
    clearError(fieldPath);
    setValue(fieldPath, defaultValue);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const errorMessage = hasIn(errors, fieldPath)
    ? `${get(errors, `${fieldPath}`)?.message}`
    : undefined;

  const presenceCheckValidation = presenceCheck(requiredError);
  const addressFormatValidation = validateAddressField(
    `${i18n._(
      t({
        id: "setup.about-you.address.city",
        message: "Please enter a valid city",
      })
    )}`
  );
  const validationRules = {
    ...presenceCheckValidation,
    validate: {
      ...presenceCheckValidation.validate,
      ...addressFormatValidation,
    },
  };
  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="City">City</Trans>
      </Label>
      <Input
        {...register(fieldPath, validationRules)}
        name={fieldPath}
        id={fieldPath}
        defaultValue={defaultValue}
        className="fs-exclude"
      />
      {errorMessage && (
        <Hint status={FormFieldStatus.Danger}>{errorMessage}</Hint>
      )}
    </Field>
  );
};

const CompanyCityField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <CityField
      {...props}
      fieldPath="city"
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.enter-company-address",
          message: "Please enter the city for your company address",
        })
      )}
    />
  );
};

const DirectorCityField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <CityField
      {...props}
      fieldPath={`directors[${props.index}].city`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.enter-city",
          message: "Please enter director’s city",
        })
      )}
    />
  );
};

const OwnerCityField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <CityField
      {...props}
      fieldPath={`shareholders[${props.index}].city`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.enter-owners-city",
          message: "Please enter owner's city",
        })
      )}
    />
  );
};

const PersonCityField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <CityField
      {...props}
      fieldPath="person.city"
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.enter-person-city",
          message: "Please enter the city",
        })
      )}
    />
  );
};

const ControlCityField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <CityField
      {...props}
      fieldPath={`control_prongs[${props.index}].city`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.enter-person-city",
          message: "Please enter the city",
        })
      )}
    />
  );
};

const TrusteeCityField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <CityField
      {...props}
      fieldPath={`trustees[${props.index}].city`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.enter-person-city",
          message: "Please enter the city",
        })
      )}
    />
  );
};

const PartnerCityField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <CityField
      {...props}
      fieldPath={`partners[${props.index}].city`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.enter-person-city",
          message: "Please enter the city",
        })
      )}
    />
  );
};

const UBOCityField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <CityField
      {...props}
      fieldPath={`ultimate_beneficial_owners[${props.index}].city`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.enter-person-city",
          message: "Please enter the city",
        })
      )}
    />
  );
};

export const companyConfig: Config = {
  name: "city",
  displayName: "City",
  required: true,
  component: CompanyCityField,
};

export const directorConfig: Config = {
  name: "city",
  displayName: "City",
  required: true,
  component: DirectorCityField,
};

export const ownerConfig: Config = {
  name: "city",
  displayName: "City",
  component: OwnerCityField,
};

export const personConfig: Config = {
  name: "city",
  displayName: "City",
  required: true,
  component: PersonCityField,
};

export const controlConfig: Config = {
  name: "city",
  displayName: "City",
  component: ControlCityField,
};

export const trusteeConfig: Config = {
  name: "city",
  displayName: "City",
  component: TrusteeCityField,
};

export const partnerConfig: Config = {
  name: "city",
  displayName: "City",
  component: PartnerCityField,
};

export const uboConfig: Config = {
  name: "city",
  displayName: "City",
  component: UBOCityField,
};

export default CityField;
