import { FieldConfig } from "../types";
import { unsupported } from "../unsupported";
import { BankStatementName } from "../bankstatementname";
import * as GlobalCompany from "../GLOBAL/company";
import * as GlobalIndividual from "../GLOBAL/individual";

import * as Partnership from "./partnership";
import * as Trust from "./trust";
import * as Individual from "./individual";

export const FIELD_CONFIG_AU: FieldConfig = {
  /**
   * AU Company type is the same
   * as the global configuration
   */
  company: {
    BusinessDetails: GlobalCompany.BusinessDetails,
    BusinessCategory: GlobalCompany.BusinessCategory,
    BusinessDirectors: GlobalCompany.BusinessDirectors,
    BusinessOwners: GlobalCompany.BusinessOwners,
    BankStatementName,
  },
  individual: {
    BusinessDetails: Individual.BusinessDetails,
    BusinessCategory: GlobalIndividual.BusinessCategory,
    AboutYou: GlobalIndividual.AboutYou,
    BankStatementName,
  },
  partnership: {
    BusinessDetails: Partnership.BusinessDetails,
    BusinessCategory: Partnership.BusinessCategory,
    BusinessDirectors: Partnership.BusinessDirectors,
    BusinessOwners: Partnership.BusinessOwners,
    BankStatementName,
  },
  trust: {
    BusinessDetails: Trust.BusinessDetails,
    BusinessCategory: Trust.BusinessCategory,
    TrusteeDetails: Trust.TrusteeDetails,
    TrustOwners: Trust.TrustOwners,
    BankStatementName,
  },
  charity: unsupported,
};
