import * as Fields from "../fields";
import * as Company from "../GLOBAL/company";

/**
 * AU Trust Business Details
 * includes additional fields so
 * all are defined here
 */
export const BusinessDetails = [
  [Fields.Country, Fields.CreditorType],
  {
    name: "trustDetails",
    displayName: "Trust Details",
    component: [
      Fields.TrustLegalName,
      Fields.TrustTradingName,
      Fields.TrustNumber,
      {
        name: "registered_address",
        displayName: "Registered address",
        component: [
          Fields.CompanyAddressLineOne,
          Fields.CompanyAddressLineTwo,
          Fields.CompanyAddressLineThree,
          Fields.CompanyCity,
          Fields.CompanyPostalCode,
        ],
      },
    ],
  },
];

/**
 * AU Trust Business Category is the same
 * as the global company configuration
 */
export const BusinessCategory = [...Company.BusinessCategory];

/**
 * AU Trust Trustee Details
 * includes additional fields so
 * all are defined here
 */
export const TrusteeDetails = [
  Fields.TrusteeType,
  {
    name: "trusteeDetails",
    displayName: "Trustee Details",
    component: [
      Fields.TrusteeGivenName,
      Fields.TrusteeFamilyName,
      Fields.TrusteeDateOfBirth,
    ],
  },
  Fields.TrusteeBusinessName,
  Fields.TrusteeBusinessNumber,
];

/**
 * AU Trust Owners page
 * includes additional fields so
 * all are defined here
 */
export const TrustOwners = [
  {
    name: "uboDetails",
    displayName: "Ultimate Beneficial Owner Details",
    component: [
      Fields.UBOGivenName,
      Fields.UBOFamilyName,
      Fields.UBODateOfBirth,
    ],
  },
  [Fields.UBOSelect, Fields.UBOCountryCode],
  {
    name: "uboAddress",
    displayName: "Ultimate Beneficial Owner Address",
    component: [
      Fields.UBOFlatNumber,
      Fields.UBOBuildingNumber,
      Fields.UBOBuildingName,
      Fields.UBOStreet,
      Fields.UBOCity,
      Fields.UBORegion,
      Fields.UBOPostalCode,
    ],
  },
];
