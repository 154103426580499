import { FieldConfig } from "../types";
import { unsupported } from "../unsupported";
import { BankStatementName } from "../bankstatementname";
import * as Company from "../GLOBAL/company";
import * as Individual from "../GLOBAL/individual";

import * as Partnership from "./partnerships";

export const FIELD_CONFIG_GB_CA: FieldConfig = {
  company: {
    BusinessDetails: Company.BusinessDetails,
    BusinessCategory: Company.BusinessCategory,
    BusinessDirectors: Company.BusinessDirectors,
    BusinessOwners: Company.BusinessOwners,
    BankStatementName,
  },
  individual: {
    BusinessDetails: Individual.BusinessDetails,
    BusinessCategory: Individual.BusinessCategory,
    AboutYou: Individual.AboutYou,
    BankStatementName,
  },
  charity: unsupported,
  partnership: {
    BusinessDetails: Partnership.BusinessDetails,
    BusinessCategory: Partnership.BusinessCategory,
    BusinessDirectors: Partnership.BusinessDirectors,
    BusinessOwners: Partnership.BusinessOwners,
    BankStatementName,
  },
  trust: unsupported,
};
