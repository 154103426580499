import { size } from "lodash";
import { FieldErrors } from "react-hook-form";

export function scrollToErrors<T extends {}>(errors: FieldErrors<T>) {
  if (!size(errors)) return;

  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
}
