import {
  OrganisationResource,
  UpgradeResource,
  UpgradeType,
  UpgradesStatus,
} from "@gocardless/api/dashboard/types";

import { PackageAddonUpgradeType } from "../packages/package/addons";
import { BasePricingPackages } from "../packages/package/usePackageStateV3/getBasePackage/getBasePackageState";
import { PricingPackagesV3 } from "../packages/package/usePackageStateV3/usePackageStateV3";

import { SelectedUpgrades } from "./useUpgrades";

export const ALL_SUPPORTED_UPGRADES: PackageAddonUpgradeType[] = [
  UpgradeType.OwnSun,
  UpgradeType.CustomNotifications,
  UpgradeType.CustomPaymentPages,
];

export const ADDONS_PER_PACKAGE: Partial<
  Record<BasePricingPackages, PackageAddonUpgradeType[]>
> = {
  [PricingPackagesV3.STANDARD]: [UpgradeType.OwnSun],
  [PricingPackagesV3.ADVANCED]: ALL_SUPPORTED_UPGRADES,
  [PricingPackagesV3.PRO]: ALL_SUPPORTED_UPGRADES,
};

export const doUpgradesMatchPackage = (
  pkg: BasePricingPackages,
  selectedUpgrades: SelectedUpgrades
) => {
  const upgrades = Object.keys(selectedUpgrades) as Partial<
    PackageAddonUpgradeType[]
  >;

  const requestedUpgrades = upgrades.filter((u) => u && selectedUpgrades[u]);

  if (!requestedUpgrades.length) return true;

  const currentPackageUpgrades = ADDONS_PER_PACKAGE[pkg];

  return requestedUpgrades?.reduce((doesUpgradesMatch, upgrade) => {
    if (upgrade && !currentPackageUpgrades?.includes(upgrade)) {
      return false;
    }
    return doesUpgradesMatch;
  }, true);
};

export const getActiveUpgrades = (
  upgrades: UpgradeResource[] = [],
  org?: OrganisationResource
) => {
  // We filter for all allowed upgrade types that don't have a status of inactive.
  const filteredUpgrades = upgrades.filter(
    (upgrade) =>
      upgrade.upgrade_type &&
      upgrade.status !== UpgradesStatus.Inactive &&
      ALL_SUPPORTED_UPGRADES.includes(
        upgrade.upgrade_type as PackageAddonUpgradeType
      )
  );

  // We want to return the types of the upgrade as it will make it easier to update the state
  // in `selectedUpgrades`.
  const activeUpgradeTypes: Partial<PackageAddonUpgradeType[]> =
    filteredUpgrades.map(
      (filteredUpgrade) =>
        filteredUpgrade.upgrade_type as PackageAddonUpgradeType
    );

  const requestedCustomSun =
    !!org?.organisation_preferences?.merchant_has_requested_custom_sun;

  if (!activeUpgradeTypes.includes(UpgradeType.OwnSun) && requestedCustomSun) {
    return [...activeUpgradeTypes, UpgradeType.OwnSun];
  }

  return activeUpgradeTypes;
};

export const getUpgradesToAdd = (
  selectedUpgrades: SelectedUpgrades,
  upgrades: UpgradeResource[] | undefined,
  org?: OrganisationResource
): PackageAddonUpgradeType[] => {
  if (!upgrades) return [];

  // Get a list of the active upgrades from the API.
  const activeUpgrades = getActiveUpgrades(
    upgrades,
    org
  ) as PackageAddonUpgradeType[];

  const upgradesToAdd = (
    Object.keys(selectedUpgrades) as PackageAddonUpgradeType[]
  ).filter(
    (selectedUpgrade) =>
      selectedUpgrades[selectedUpgrade] &&
      !activeUpgrades.includes(selectedUpgrade)
  );

  return upgradesToAdd;
};
