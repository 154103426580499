import _ from "lodash";
import { CreditorDetailResource } from "@gocardless/api/dashboard/types";
import {
  ADDRESS_FIELDS,
  BASIC_PERSON_FIELDS,
} from "src/components/routes/Setup/common/builders/types";

import {
  BusinessDirectorsConfig,
  PartnerType,
} from "../../business-directors/useBusinessDirectors";

/**
 * Builds a correctly formatted object for the
 * Partners form from the creditor_details
 * API response
 *
 * @param creditorDetails
 * @returns
 */
export const getPartners = (
  creditorDetails?: CreditorDetailResource
): PartnerType[] => {
  const partners = creditorDetails?.detail?.partners || [];
  return partners.map((partner) =>
    _.pick(_.omitBy(partner, _.isNull), [
      ...BASIC_PERSON_FIELDS,
      ...ADDRESS_FIELDS,
    ])
  );
};

/**
 * Builds a correctly formatted partners object
 * for the creditor_details put request body
 *
 * @param partnerInfo
 * @param country_code
 * @returns
 */
export const formatPartnersBuilder = (
  partnerInfo: BusinessDirectorsConfig,
  country_code?: string
) =>
  partnerInfo.partners.map((partner) =>
    partner.street && partner.city && partner.postal_code
      ? _.omit(partner, ["is_ubo"])
      : {
          ..._.pick(partner, ["given_name", "family_name", "date_of_birth"]),
          country_code,
        }
  );
