export const ALLOWED_PREFIXES = [
  //  english & welsh prefixes
  "AC",
  "BR",
  "FC",
  "GE",
  "IP",
  "LP",
  "OC",
  "RC",
  "SE",
  "ZC",
  //  Scottish prefixes
  "SC",
  "SA",
  "SF",
  "SL",
  "SZ",
  "SP",
  "SO",
  "SR",
  //   Northen Irish prefixes
  "NI",
  "NA",
  "NF",
  "NL",
  "NZ",
  "NP",
  "NO",
  "NR",
  "NC",
  "R0",
  // default prefix regex - 2 digits
  "\\d\\d",
];

export const COMPANIES_HOUSE_REGEX = new RegExp(
  `^(${ALLOWED_PREFIXES.join("|")})\\d{6}$`
);

// @todo remove defaul export tidy method move to commons
export default (
  companyNumber: string
): { valid: boolean; companyNumber: string } => {
  //  # 0-pad 5 or 7 digit registration number
  if (/^(\D{2})(\d{5})$/.test(companyNumber)) {
    companyNumber = `${companyNumber.slice(0, 2)}${companyNumber
      .slice(2)
      .padStart(6, "0")}`;
  } else if (/^\d{7}$/.test(companyNumber)) {
    companyNumber = companyNumber.padStart(8, "0");
  }
  return {
    valid: COMPANIES_HOUSE_REGEX.test(companyNumber),
    companyNumber,
  };
};
