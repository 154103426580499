import { Route } from "src/common/routing";
import { useSetupData } from "src/components/routes/Setup/common/hooks/useSetupData";
import {
  UseSetupPage,
  UseSetupPageCallbacks,
} from "src/components/routes/Setup/routing/types";

export interface PaymentVolumesConfig {
  geo: string | null;
  expected_collections: string | null;
  expected_ipfx_collections: string | null;
}

export interface PaymentVolumes extends UseSetupPage {
  paymentVolumes: PaymentVolumesConfig;
  submitPaymentVolumes: (data: PaymentVolumesConfig) => void;
}

export function usePaymentVolumes({
  onSuccess = () => {},
  onError = () => {},
}: UseSetupPageCallbacks = {}): PaymentVolumes {
  const { creditor, creditorDetails, updateCreditorDetail, loaded } =
    useSetupData(onSuccess, onError);

  const geo = creditor?.geo ?? null;
  const expectedCollections =
    creditorDetails?.detail?.expected_collections ?? null;
  const expectedIpfxCollections =
    creditorDetails?.detail?.expected_ipfx_collections ?? null;

  const paymentVolumes: PaymentVolumesConfig = {
    geo,
    expected_collections: expectedCollections,
    expected_ipfx_collections: expectedIpfxCollections,
  };

  async function submitPaymentVolumes(newPaymentVolumes: PaymentVolumesConfig) {
    updateCreditorDetail({
      creditor_type: creditorDetails?.creditor_type,
      detail: {
        expected_collections: newPaymentVolumes.expected_collections,
        expected_ipfx_collections: newPaymentVolumes.expected_ipfx_collections,
      },
    });
  }

  return {
    loaded,
    completed: !!expectedCollections && !!expectedIpfxCollections,
    skip: false,
    route: Route.PaymentVolumes,
    paymentVolumes,
    submitPaymentVolumes,
  };
}
