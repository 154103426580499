import { useEffect } from "react";
import { get, hasIn } from "lodash";
import { useFormContext } from "react-hook-form";
import {
  Field,
  Label,
  Hint,
  FormFieldStatus,
  Option,
  Select,
} from "@gocardless/flux-react";
import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";
import { COUNTRY_CODES } from "src/common/constants/countryCodes";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

type PassportCountryFieldProps = {
  fieldPath: string;
} & FieldArrayProps;

const PassportCountryField: React.FC<PassportCountryFieldProps> = ({
  defaultValue,
  fieldPath,
}) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
    unregister,
  } = useFormContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => unregister(fieldPath), []);
  return (
    <Field>
      <Label htmlFor={fieldPath}>Passport country</Label>
      <Select
        {...register(fieldPath, {
          required: i18n._(
            t({
              id: "setup.business-owners.choose-passport-country",
              message: "Please choose the passport country",
            })
          ),
        })}
        id={fieldPath}
        defaultValue={defaultValue}
      >
        <Option value="">Choose a country</Option>
        {COUNTRY_CODES(i18n).map(({ code, name }) => (
          // eslint-disable-next-line react/jsx-no-undef
          <Option key={code} value={code}>
            {name}
          </Option>
        ))}
      </Select>
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}`)?.message as string}
        </Hint>
      )}
    </Field>
  );
};

const DirectorPassportCountryField: React.FC<FieldArrayProps> = (props) => (
  <PassportCountryField
    {...props}
    fieldPath={`directors[${props.index}].passport_country`}
  />
);

const ControlPassportCountryField: React.FC<FieldArrayProps> = (props) => (
  <PassportCountryField
    {...props}
    fieldPath={`control_prongs[${props.index}].passport_country`}
  />
);

const OwnerPassportCountryField: React.FC<FieldArrayProps> = (props) => (
  <PassportCountryField
    {...props}
    fieldPath={`shareholders[${props.index}].passport_country`}
  />
);

const PersonPassportCountryField: React.FC<FieldArrayProps> = (props) => (
  <PassportCountryField {...props} fieldPath="person.passport_country" />
);

export const directorConfig: Config = {
  name: "passport_country",
  displayName: "Passport Country",
  component: DirectorPassportCountryField,
};

export const controlConfig: Config = {
  name: "passport_country",
  displayName: "Passport Country",
  component: ControlPassportCountryField,
};

export const ownerConfig: Config = {
  name: "passport_country",
  displayName: "Passport Country",
  component: OwnerPassportCountryField,
};

export const personConfig: Config = {
  name: "passport_country",
  displayName: "Passport Country",
  component: PersonPassportCountryField,
};

export default PassportCountryField;
