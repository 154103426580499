import * as Fields from "../fields";

export const BusinessDetails = [
  Fields.Country,
  Fields.CreditorType,
  Fields.IndividualTradingName,
  Fields.VATNumber,
];

export const AboutYou = [
  {
    name: "personDetails",
    displayName: "Person Details",
    component: [
      Fields.PersonGivenName,
      Fields.PersonFamilyName,
      Fields.PersonDateOfBirth,
      Fields.PersonPlaceOfBirthField,
    ],
  },
  {
    name: "personAddress",
    displayName: "Person Address",
    component: [
      Fields.PersonFlatNumber,
      Fields.PersonBuildingNumber,
      Fields.PersonBuildingName,
      Fields.PersonStreet,
      Fields.PersonCity,
      Fields.PersonPostalCode,
    ],
  },
];

export const BusinessCategory = [
  {
    name: "businessCategory",
    displayName: "Business Category",
    component: [
      Fields.CategoryIndustry,
      Fields.CategoryCode,
      Fields.CategoryDescription,
    ],
  },
];
