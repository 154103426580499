import {
  Field as FieldType,
  FieldAttributes,
} from "src/components/routes/Setup/common/config/types";
import {
  Box,
  Interpose,
  JustifyContent,
  Space,
  XYGrid,
} from "@gocardless/flux-react";
import { get } from "lodash";
import { renderFields } from "src/components/routes/Setup/common/config/utils";
import { CountryCodes } from "src/common/country";

const NAME_FIELDS_LIST = ["given_name", "family_name"];

interface PersonDetailProps {
  fields: FieldType[];
  defaultValue: object;
  index: number;
  countryCode: CountryCodes;
  showPlaceOfBirth?: boolean;
}

const PersonDetail: React.FC<PersonDetailProps> = ({
  fields,
  defaultValue,
  index,
  countryCode,
  showPlaceOfBirth = true,
}) => {
  const fieldAttributes: FieldAttributes = {
    given_name: {
      props: { defaultValue: get(defaultValue, "given_name"), index },
    },
    family_name: {
      props: { defaultValue: get(defaultValue, "family_name"), index },
    },
    date_of_birth: {
      props: {
        defaultValue: get(defaultValue, "date_of_birth"),
        index,
        countryCode,
      },
    },
    place_of_birth: {
      props: {
        defaultValue: get(defaultValue, "place_of_birth"),
        index,
      },
      canRender: showPlaceOfBirth,
    },
    is_ubo: {
      props: { defaultValue: get(defaultValue, "is_ubo"), index },
    },
  };
  const nameFields = fields.filter((field) =>
    NAME_FIELDS_LIST.includes(field.name)
  );
  const otherFields = fields.filter(
    (field) => !NAME_FIELDS_LIST.includes(field.name)
  );

  return (
    <Box>
      <Interpose node={<Space v={2} />}>
        <XYGrid
          templateColumns={["1fr", null, "1fr 1fr"]}
          columnGap={[null, null, 1.5]}
          rowGap={2}
          justifyContent={JustifyContent.Center}
        >
          {renderFields(nameFields, fieldAttributes)}
        </XYGrid>
        {renderFields(otherFields, fieldAttributes)}
      </Interpose>
    </Box>
  );
};

export default PersonDetail;
