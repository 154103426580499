import { useState, useEffect } from "react";
import _ from "lodash";
import { Field, Input, Label } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { Field as Config, FieldArrayProps } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

import { error, presenceCheck } from "./helpers";
import { LocaleNameMap } from "./CompanyNumberField";

interface IndividualNumberFieldProps extends FieldArrayProps {
  errorMessage: string;
}

enum EntityNumberType {
  SIREN = "siren",
}
const IndividualNumberField: React.FC<IndividualNumberFieldProps> = ({
  defaultValue,
  errorMessage,
}) => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<
    BusinessDetailsConfig & { entity_number_type: EntityNumberType }
  >();
  const [geo] = useState(getValues()?.geo);

  const map = LocaleNameMap[geo as keyof typeof LocaleNameMap];

  const displayName = !_.isEmpty(map) ? (
    <>{map}</>
  ) : (
    <Trans id="Company number">Company number</Trans>
  );

  // we need to provide entity_number_type along with entity_number
  useEffect(() => {
    setValue("entity_number_type", EntityNumberType.SIREN);
  }, [setValue]);

  return (
    <Field>
      <Label htmlFor="entity_number">{displayName}</Label>
      <Input
        id="entity_number"
        {...register("entity_number", {
          ...presenceCheck(errorMessage),
        })}
        className="fs-exclude"
        defaultValue={defaultValue}
      />
      {error(errors, "entity_number")}
    </Field>
  );
};

const FRIndividualNumberField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();

  return (
    <IndividualNumberField
      {...props}
      errorMessage={i18n._(
        t({
          id: "setup.business-details.siren-required-error",
          message: `Please enter a valid SIREN`,
        })
      )}
    />
  );
};

export const FRConfig: Config = {
  name: "entity_number",
  required: true,
  displayName: LocaleNameMap.FR,
  component: FRIndividualNumberField,
};

export default IndividualNumberField;
