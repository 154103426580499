import { Trans } from "@lingui/macro";
import {
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  ColorPreset,
  Interpose,
  JustifyContent,
  Space,
  XYGrid,
  Text,
  TypePreset,
  FontWeight,
} from "@gocardless/flux-react";
import { Field as FieldType } from "src/components/routes/Setup/common/config/types";
import { renderFields } from "src/components/routes/Setup/common/config/utils";
import { get } from "lodash";

const FLAT_FIELDS_LIST = ["flat_number"];
const BUILDING_FIELDS_LIST = ["building_number", "building_name"];

interface AddressManualProps {
  fields: FieldType[];
  index: number;
  defaultValue: object;
  countryCode: string;
  onChooseSearchOption: () => void;
  hidden?: boolean;
}

const AddressManual: React.FC<AddressManualProps> = ({
  fields,
  index,
  defaultValue,
  countryCode,
  onChooseSearchOption,
  hidden,
}) => {
  /* Separating flat Fields, building fields and other fields
  to put building fields in the same row inside the XYGrid */
  const flatFields = fields.filter((field) =>
    FLAT_FIELDS_LIST.includes(field.name)
  );
  const buildingFields = fields.filter((field) =>
    BUILDING_FIELDS_LIST.includes(field.name)
  );
  const otherFields = fields.filter(
    (field) =>
      !BUILDING_FIELDS_LIST.includes(field.name) &&
      !FLAT_FIELDS_LIST.includes(field.name)
  );
  const fieldAttributes = {
    address_line1: {
      props: { defaultValue: get(defaultValue, "address_line1"), index },
    },
    address_line2: {
      props: { defaultValue: get(defaultValue, "address_line2"), index },
    },
    address_line3: {
      props: { defaultValue: get(defaultValue, "address_line3"), index },
    },
    flat_number: {
      props: { defaultValue: get(defaultValue, "flat_number"), index },
    },
    building_number: {
      props: { defaultValue: get(defaultValue, "building_number"), index },
    },
    building_name: {
      props: { defaultValue: get(defaultValue, "building_name"), index },
    },
    street: {
      props: { defaultValue: get(defaultValue, "street"), index },
    },
    city: {
      props: { defaultValue: get(defaultValue, "city"), index },
    },
    region: {
      props: { defaultValue: get(defaultValue, "region", ""), index },
      canRender: countryCode === "US",
    },
    postal_code: {
      props: {
        defaultValue: get(defaultValue, "postal_code"),
        index,
        countryCode,
      },
    },
  };
  return (
    <Box
      bg={ColorPreset.BackgroundLight_03}
      borderRadius={1.5}
      borderColor={ColorPreset.BorderOnLight_03}
      borderWidth={1}
      css={{ display: hidden ? "none" : "?" }}
    >
      <Box gutterH={2} gutterV={2}>
        <Interpose node={<Space v={2} />}>
          {renderFields(flatFields, fieldAttributes)}
          <XYGrid
            templateColumns={["1fr", null, "1fr 1fr"]}
            columnGap={[null, null, 2]}
            rowGap={2}
            justifyContent={JustifyContent.Center}
          >
            {renderFields(buildingFields, fieldAttributes)}
          </XYGrid>
          {renderFields(otherFields, fieldAttributes)}
          <Button
            size={ButtonSize.Sm}
            variant={ButtonVariant.InlineUnderlined}
            onClick={onChooseSearchOption}
          >
            <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
              <Trans id="setup.address.search-again">
                Search for address again
              </Trans>
            </Text>
          </Button>
        </Interpose>
      </Box>
    </Box>
  );
};

export default AddressManual;
