import {
  AlignItems,
  Box,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  Interpose,
  PlainButton,
  PlainLink,
  Separator,
  Space,
  Text,
  Visibility,
} from "@gocardless/flux-react";
import { logout } from "src/common/authorisation";
import { Trans } from "@lingui/macro";
import { GoCardlessFooterText } from "src/components/GoCardlessFooterText";

const Footer: React.FC = () => {
  const signOutButton = (
    <PlainButton onClick={() => logout()} effect={HoverEffect.TextDecoration}>
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Icon name={Glyph.User} size="12px" />
        <Space layout="inline" h={0.5} />
        <Text weight={FontWeight.Bold}>
          <Trans id="footer.logout">Sign out and continue later</Trans>
        </Text>
      </Box>
    </PlainButton>
  );

  return (
    <Box fontSize={2}>
      <Box spaceAbove={[3, 6]} spaceBelow={[3, 6]}>
        <Separator />
      </Box>
      <Box maxWidth="800px" spaceBelow={2}>
        <GoCardlessFooterText />
      </Box>
      <Box layout="flex" flexWrap="wrap">
        <Interpose
          node={
            <Space v={[1.5, null]} layout={["block", "inline"]} h={[null, 2]} />
          }
        >
          <PlainLink
            href="https://hub.gocardless.com/"
            target="_blank"
            effect={HoverEffect.TextDecoration}
          >
            <Text weight={FontWeight.Bold}>
              <Trans id="Help">Help</Trans>
            </Text>
          </PlainLink>
          <PlainLink
            href="https://manage.gocardless.com/"
            target="_blank"
            effect={HoverEffect.TextDecoration}
          >
            <Text weight={FontWeight.Bold}>
              <Trans id="footer.dashboard">GoCardless dashboard</Trans>
            </Text>
          </PlainLink>
          <PlainLink
            href="https://gocardless.com/legal/"
            target="_blank"
            effect={HoverEffect.TextDecoration}
          >
            <Text weight={FontWeight.Bold}>
              <Trans id="footer.terms">Terms</Trans>
            </Text>
          </PlainLink>
          <PlainLink
            href="https://gocardless.com/privacy/merchants"
            target="_blank"
            effect={HoverEffect.TextDecoration}
          >
            <Text weight={FontWeight.Bold}>
              <Trans id="footer.privacy-notice">Privacy notice</Trans>
            </Text>
          </PlainLink>
          <Box layout="inline-block">
            <Visibility visible={["none", "inline-block"]}>
              <Box layout="inline-block" spaceAfter={2}>
                <Separator direction="inline-vertical" />
              </Box>
              {signOutButton}
            </Visibility>
            <Visibility visible={["inline-block", "none"]}>
              {signOutButton}
            </Visibility>
          </Box>
        </Interpose>
      </Box>
      <Space v={[3, 6]} />
    </Box>
  );
};

export default Footer;
