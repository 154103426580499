import * as Fields from "../fields";
import * as Company from "../GLOBAL/company";

/**
 * AU Partnership Business Details
 * includes additional fields so
 * all are defined here
 */
export const BusinessDetails = [
  [Fields.Country, Fields.CreditorType],
  {
    name: "partnershipDetails",
    displayName: "Partnership Details",
    component: [
      Fields.AUPartnershipTypeField,
      Fields.PartnershipLegalName,
      Fields.PartnershipTradingName,
      Fields.AUPartnershipNumberField,
      {
        name: "registered_address",
        displayName: "Registered address",
        component: [
          Fields.CompanyAddressLineOne,
          Fields.CompanyAddressLineTwo,
          Fields.CompanyAddressLineThree,
          Fields.CompanyCity,
          Fields.CompanyPostalCode,
        ],
      },
    ],
  },
];

/**
 * AU Partnership Business Category is the same
 * as the global company configuration
 */
export const BusinessCategory = [...Company.BusinessCategory];

export const BusinessDirectors = [
  {
    name: "partnerDetails",
    displayName: "Partner Details",
    component: [
      Fields.PartnerGivenName,
      Fields.PartnerFamilyName,
      Fields.PartnerDateOfBirth,
      Fields.PartnerIsBusinessOwnerField,
    ],
  },
  Fields.PartnerSelect,
  Fields.PartnerCountryCode,
  {
    name: "partnerAddress",
    displayName: "Partner Address",
    component: [
      Fields.PartnerFlatNumber,
      Fields.PartnerBuildingNumber,
      Fields.PartnerBuildingName,
      Fields.PartnerStreet,
      Fields.PartnerCity,
      Fields.PartnerPostalCode,
    ],
  },
];

/**
 * AU Partnership Business Owners are the same
 * as the global configuration
 */
export const BusinessOwners = [...Company.BusinessOwners];
