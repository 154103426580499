import _ from "lodash";
import {
  AmlUboPersonWithAddressResource,
  ControlProngElement,
  CreditorDetailResource,
} from "@gocardless/api/dashboard/types";
import { getUbos } from "src/components/routes/Setup/common/builders/owners";
import {
  formatPartnersBuilder,
  getPartners,
} from "src/components/routes/Setup/common/builders/partners";
import { ShareHolderType } from "src/components/routes/Setup/business-owners/useBusinessOwners";

import { BusinessDirectorsConfig, PartnerType } from "./useBusinessDirectors";

interface MappedPartnerUbo {
  partners?: ControlProngElement[];
  ultimate_beneficial_owners?: AmlUboPersonWithAddressResource[];
  ultimate_beneficial_owners_self_declared?: boolean;
}
export interface UsePartnerUboMapping {
  mappedPartners: PartnerType[];
  buildMappedPartnerUbo: (
    directorInfo: BusinessDirectorsConfig
  ) => MappedPartnerUbo;
}

export function usePartnerUboMapping(
  creditorDetails?: CreditorDetailResource
): UsePartnerUboMapping {
  if (!creditorDetails) {
    return { mappedPartners: [], buildMappedPartnerUbo: () => ({}) };
  }
  const countryCode = creditorDetails.detail?.country_code;
  const parsedUbos = getUbos(creditorDetails);
  const parsedPartners = getPartners(creditorDetails);

  const isPartnerMatch = (
    partner: PartnerType,
    ubo: ShareHolderType
  ): boolean =>
    partner.given_name === ubo.given_name &&
    partner.family_name === ubo.family_name &&
    partner.date_of_birth === ubo.date_of_birth;

  const isPartnerAnUbo = (partner: PartnerType) =>
    parsedUbos.some((ubo) => isPartnerMatch(partner, ubo));

  const mappedPartners = parsedPartners.map((partner) => ({
    ...partner,
    is_ubo: isPartnerAnUbo(partner),
  }));

  const buildMappedPartnerUbo = (
    directorInfo: BusinessDirectorsConfig
  ): MappedPartnerUbo => {
    const { updateUbo, updatedUbos } = directorInfo.partners.reduce(
      (uboDetails, partner) => {
        // Add partner as UBO if not already present
        if (partner.is_ubo && !isPartnerAnUbo(partner)) {
          return {
            updateUbo: true,
            updatedUbos: [
              ...uboDetails.updatedUbos,
              {
                given_name: partner.given_name,
                family_name: partner.family_name,
                date_of_birth: partner.date_of_birth,
                country_code: countryCode,
              },
            ],
          };
        }
        // Remove partner from UBO if already present
        if (!partner.is_ubo && isPartnerAnUbo(partner)) {
          return {
            updateUbo: true,
            updatedUbos: uboDetails.updatedUbos.filter(
              (ubo) => !isPartnerMatch(partner, ubo)
            ),
          };
        }
        return uboDetails;
      },
      { updatedUbos: parsedUbos, updateUbo: false }
    );

    const ultimate_beneficial_owners = updatedUbos.map((ubo) =>
      _.omitBy(ubo, _.isNull)
    );
    const partners = formatPartnersBuilder(directorInfo, countryCode);

    return updateUbo
      ? {
          ultimate_beneficial_owners,
          partners,
          ultimate_beneficial_owners_self_declared: true,
        }
      : { partners };
  };

  return { mappedPartners, buildMappedPartnerUbo };
}
