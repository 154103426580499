import { get } from "lodash";
import { Route } from "src/common/routing";
import { CompanyType, CreditorType } from "@gocardless/api/dashboard/types";
import { HTTPError } from "@gocardless/api/utils/api";
import { CountryCodes } from "src/common/country";
import { SetupPages } from "src/components/routes/Setup/common/config/types";
import { usePartnerUboMapping } from "src/components/routes/Setup/business-directors/usePartnerUboMapping";

import { useSetupData } from "../common/hooks/useSetupData";
import { AddressConfig } from "../common/components/Address/AddressSearch";
import { UseSetupPage } from "../routing/types";
import { useComplete } from "../common/validators/useComplete";
import {
  getDirectors,
  formatDirectorsBuilder,
} from "../common/builders/directors";
import { usePaobVerification } from "../common/hooks/usePaobVerification";

export interface UseBusinessDirectors extends UseSetupPage {
  businessDirectors: BusinessDirectorsConfig;
  countryCode: CountryCodes;
  creditorType: CreditorType;
  companyType: string;
  registeredCompanySearchId: string;
  submitDirectors: (data: BusinessDirectorsConfig) => Promise<void>;
}

export type DirectorType = {
  given_name?: string;
  family_name?: string;
  date_of_birth?: string;
  social_security_number?: string;
  passport_number?: string;
  passport_country?: string;
} & AddressConfig;

export type PartnerType = {
  given_name?: string;
  family_name?: string;
  date_of_birth?: string;
  is_ubo?: boolean;
} & AddressConfig;

export interface BusinessDirectorsConfig {
  directors: DirectorType[];
  partners: PartnerType[];
}

const SUPPORTED_CREDITOR_TYPES = [
  CreditorType.Company,
  CreditorType.Partnership,
];

const COUNTRY_CODES_WITHOUT_DIRECTORS = [CountryCodes.US];

export function useBusinessDirectors({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: (error: HTTPError) => void;
} = {}): UseBusinessDirectors {
  const { creditorDetails, creditor, updateCreditorDetail } = useSetupData(
    onSuccess,
    onError
  );
  const { mappedPartners, buildMappedPartnerUbo } =
    usePartnerUboMapping(creditorDetails);
  const { isListComplete } = useComplete(SetupPages.BUSINESS_DIRECTORS);
  const { loaded, gcSasVerificationEnabled } = usePaobVerification();
  const creditorType = get(creditorDetails, "creditor_type");
  const parsedDirectors = getDirectors(creditorDetails);
  const businessDirectors = {
    directors: parsedDirectors.length > 0 ? parsedDirectors : [{}],
    partners: mappedPartners.length > 1 ? mappedPartners : [{}, {}],
  };
  const countryCode = get(
    creditorDetails,
    "detail.country_code",
    ""
  ) as CountryCodes;
  const companyType = get(
    creditorDetails,
    "detail.company_type",
    ""
  ) as CompanyType;
  const registeredCompanySearchId = get(
    creditorDetails,
    "detail.registered_business_search_id",
    ""
  );

  const isUSLimitedCompanyMerchant =
    countryCode === CountryCodes.US && creditorType === CreditorType.Company;

  const completed = isListComplete(
    creditorType || CreditorType.Company,
    creditorType === CreditorType.Partnership
      ? businessDirectors.partners
      : businessDirectors.directors
  );

  const submitDirectors = async (directorInfo: BusinessDirectorsConfig) => {
    const detail =
      creditorType === CreditorType.Partnership
        ? buildMappedPartnerUbo(directorInfo)
        : {
            directors: formatDirectorsBuilder(directorInfo, countryCode),
            ...(isUSLimitedCompanyMerchant && {
              company_type: companyType,
            }),
          };
    await updateCreditorDetail({
      creditor_type: creditorDetails?.creditor_type,
      detail,
    });
  };

  const shouldCountryBeSkipped =
    !SUPPORTED_CREDITOR_TYPES.includes(creditorType as CreditorType) ||
    COUNTRY_CODES_WITHOUT_DIRECTORS.includes(creditor?.geo as CountryCodes) ||
    gcSasVerificationEnabled;

  return {
    loaded: !!creditorDetails && loaded,
    skip: shouldCountryBeSkipped,
    completed,
    route: Route.BusinessDirectors,
    businessDirectors,
    countryCode,
    creditorType: creditorType || CreditorType.Company,
    companyType,
    registeredCompanySearchId,
    submitDirectors,
  };
}
