import { useEffect } from "react";
import { get, hasIn } from "lodash";
import { useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Field,
  Hint,
  FormFieldStatus,
  Option,
  Select,
} from "@gocardless/flux-react";
import {
  Field as Config,
  FieldSelectProps,
} from "src/components/routes/Setup/common/config/types";

import { BusinessDirectorsFormType } from "../../business-directors/BusinessDirectorsForm";
import { TrustOwnersFormProps } from "../../trust-owners/TrustOwnersForm";

interface PersonSelectProps {
  options: { value: number; label: string; disabled?: boolean }[];
  fieldPath: string;
  onChange: (value: number) => void;
  value: number;
  requiredError: string;
  defaultOption: string;
}
interface UBOSelectProps extends FieldSelectProps {
  fieldPath: string;
  disabledIndexes: number[];
}
const PersonSelectField: React.FC<PersonSelectProps> = ({
  options,
  fieldPath,
  onChange,
  value,
  requiredError,
  defaultOption,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    setValue(fieldPath, value);
  }, [value, setValue, fieldPath]);
  return (
    <Field>
      <Select
        {...register(fieldPath, {
          min: {
            value: 0,
            message: requiredError,
          },
        })}
        id={fieldPath}
        onChange={(event) => onChange(parseInt(event.target.value))}
        value={value}
        data-testid={fieldPath}
      >
        <Option value="-1">{defaultOption}</Option>
        {options.map((option, index) => (
          <Option key={index} value={option.value} disabled={option.disabled}>
            {option.label}
          </Option>
        ))}
      </Select>
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}`)?.message as string}
        </Hint>
      )}
    </Field>
  );
};

const DirectorSelectField: React.FC<FieldSelectProps> = ({
  onChange,
  value,
}) => {
  const { i18n } = useLingui();
  const { watch } = useFormContext<BusinessDirectorsFormType>();
  const directorNames = watch("directors").map((director, index) => ({
    value: index,
    label: `${director.given_name} ${director.family_name}`,
  }));
  return (
    <PersonSelectField
      options={directorNames}
      fieldPath="directorIndex"
      onChange={onChange}
      value={value}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.no-director-selected",
          message: "Please choose a director we can verify",
        })
      )}
      defaultOption={i18n._(
        t({
          id: "setup.business-directors.address.choose-director",
          message: "Choose one director",
        })
      )}
    />
  );
};

const PartnerSelectField: React.FC<FieldSelectProps> = ({
  onChange,
  value,
}) => {
  const { i18n } = useLingui();
  const { watch } = useFormContext<BusinessDirectorsFormType>();
  const partnerNames = watch("partners").map((partner, index) => ({
    value: index,
    label: `${partner.given_name} ${partner.family_name}`,
  }));
  return (
    <PersonSelectField
      options={partnerNames}
      fieldPath="partnerIndex"
      onChange={onChange}
      value={value}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.choose-a-partner-for-verification",
          message: "Please choose a partner we can verify",
        })
      )}
      defaultOption={i18n._(
        t({
          id: "setup.business-directors.address.choose-one-partner",
          message: "Choose one partner",
        })
      )}
    />
  );
};

const UBOSelectField: React.FC<UBOSelectProps> = ({
  onChange,
  value,
  fieldPath,
  disabledIndexes,
}) => {
  const { i18n } = useLingui();
  const { watch } = useFormContext<TrustOwnersFormProps>();

  const uboNames = watch("ultimate_beneficial_owners").map((ubo, index) => ({
    value: index,
    label: `${ubo.given_name} ${ubo.family_name}`,
    disabled: disabledIndexes.includes(index),
  }));

  return (
    <PersonSelectField
      options={uboNames ?? [{ value: 0, label: "" }]}
      fieldPath={fieldPath}
      onChange={onChange}
      value={value}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.address.choose-a-ubo-for-verification",
          message: "Please choose a ultimate beneficial owner we can verify",
        })
      )}
      defaultOption={i18n._(
        t({
          id: "setup.business-directors.address.choose-one-ubo",
          message: "Choose one ultimate beneficial owner",
        })
      )}
    />
  );
};

export const directorConfig: Config = {
  name: "directorIndex",
  displayName: "Director Index",
  component: DirectorSelectField,
};

export const partnerConfig: Config = {
  name: "partnerIndex",
  displayName: "Partner Index",
  component: PartnerSelectField,
};

export const uboConfig: Config = {
  name: "uboIndex",
  displayName: "Partner Index",
  component: UBOSelectField,
};

export default PersonSelectField;
