import { hasIn, get } from "lodash";
import {
  Field,
  Label,
  Option,
  Box,
  Space,
  Tooltip,
  Hint,
  FormFieldStatus,
  Select,
} from "@gocardless/flux-react";
import { I18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { useFormContext } from "react-hook-form";
import { Field as Config } from "src/components/routes/Setup/common/config/types";
import { PaymentVolumesConfig } from "src/components/routes/Setup/payment-volumes/usePaymentVolumes";
import { countryToCurrency, Currency } from "src/common/currencies";
import { CountryCodes } from "src/common/country";
import { currencyMapping } from "src/components/payment-sharing/helpers";
import { useLingui } from "@lingui/react";

type ExpectedIpfxCollectionsValues =
  | "none"
  | "1_15k"
  | "15k_30k"
  | "30k_125k"
  | "over_125k";

const amountByCurrency: Record<Currency, string> = {
  [Currency.Aud]: "250,000",
  [Currency.Cad]: "200,000",
  [Currency.Dkk]: "1,000,000",
  [Currency.Eur]: "150,000",
  [Currency.Gbp]: "125,000",
  [Currency.Nzd]: "250,000",
  [Currency.Sek]: "1,500,000",
  [Currency.Usd]: "150,000",
};

const getExpectedIpfxCollectionsLabels = (i18n: I18n, currency: Currency) => {
  const amount = amountByCurrency[currency];

  const expectedIpfxCollectionsLabels: Record<
    Currency,
    Record<ExpectedIpfxCollectionsValues, string>
  > = {
    [Currency.Aud]: {
      none: i18n._(
        t({
          id: "setup.payment-volumes.none",
          message: "None",
        })
      ),
      "1_15k": "1-30,000",
      "15k_30k": "30,000-60,000",
      "30k_125k": "60,000-250,000",
      over_125k: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Cad]: {
      none: i18n._(
        t({
          id: "setup.payment-volumes.none",
          message: "None",
        })
      ),
      "1_15k": "1-25,000",
      "15k_30k": "25,000-50,000",
      "30k_125k": "50,000-200,000",
      over_125k: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Dkk]: {
      none: i18n._(
        t({
          id: "setup.payment-volumes.none",
          message: "None",
        })
      ),
      "1_15k": "1-150,000",
      "15k_30k": "150,000-250,000",
      "30k_125k": "200,000-1,000,000",
      over_125k: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Eur]: {
      none: i18n._(
        t({
          id: "setup.payment-volumes.none",
          message: "None",
        })
      ),
      "1_15k": "1-20,000",
      "15k_30k": "20,000-40,000",
      "30k_125k": "40,000-150,000",
      over_125k: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Gbp]: {
      none: i18n._(
        t({
          id: "setup.payment-volumes.none",
          message: "None",
        })
      ),
      "1_15k": "1-15,000",
      "15k_30k": "15,000-30,000",
      "30k_125k": "30,000-125,000",
      over_125k: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Nzd]: {
      none: i18n._(
        t({
          id: "setup.payment-volumes.none",
          message: "None",
        })
      ),
      "1_15k": "1-30,000",
      "15k_30k": "30,000-60,000",
      "30k_125k": "60,000-250,000",
      over_125k: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Sek]: {
      none: i18n._(
        t({
          id: "setup.payment-volumes.none",
          message: "None",
        })
      ),
      "1_15k": "1-200,000",
      "15k_30k": "200,000-400,000",
      "30k_125k": "400,000-1,500,000",
      over_125k: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Usd]: {
      none: i18n._(
        t({
          id: "setup.payment-volumes.none",
          message: "None",
        })
      ),
      "1_15k": "1-20,000",
      "15k_30k": "20,000-40,000",
      "30k_125k": "40,000-150,000",
      over_125k: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
  };

  return expectedIpfxCollectionsLabels[currency];
};

interface ExpectedIpfxCollectionsFieldProps {
  geo: string;
}

const ExpectedIpfxCollectionsField: React.FC<
  ExpectedIpfxCollectionsFieldProps
> = ({ geo }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<PaymentVolumesConfig>();
  const { i18n } = useLingui();

  const currency = countryToCurrency(geo as CountryCodes);
  const labels = getExpectedIpfxCollectionsLabels(i18n, currency);

  return (
    <>
      <Field>
        <Box layout="flex">
          <Label htmlFor="expected_ipfx_collections">
            <Trans id="setup.payment-volumes.expected-ipfx-collections-title">
              How much do you expect will be from international customers?
            </Trans>
          </Label>
          <Space layout="inline" h={0.5} />
          <Tooltip
            tooltipId="expected-collections-tooltip"
            message={
              <Trans id="setup.more-business-details.expected-collections-international-tooltip">
                Estimate the total value of payments you expect to collect via
                GoCardless in the next year from customers in a different
                country to your organisation.
              </Trans>
            }
          >
            <Trans id="setup.payment-volumes.expected-ipfx-collections-title">
              How much do you expect will be from international customers?
            </Trans>
          </Tooltip>
        </Box>
        <Select
          id="expected_ipfx_collections"
          {...register("expected_ipfx_collections", {
            required: i18n._(
              t({
                id: "setup.payment-volumes.required-error",
                message: "Please select an option — this can be an estimate.",
              })
            ),
          })}
        >
          <Option value="">
            {i18n._(
              t({
                id: "setup.payment-volumes.please-select",
                message: "Please select...",
              })
            )}
          </Option>

          {Object.entries(labels).map(([value, label]) => (
            <Option value={value} key={value}>
              {`${label} (${currencyMapping[currency]})`}
            </Option>
          ))}
        </Select>
        {hasIn(errors, `expected_ipfx_collections`) && (
          <Hint status={FormFieldStatus.Danger}>
            {get(errors, "expected_ipfx_collections.message")}
          </Hint>
        )}
      </Field>
    </>
  );
};

export const config: Config = {
  name: "expected_ipfx_collections",
  required: true,
  displayName: "Expected IPFX collections",
  component: ExpectedIpfxCollectionsField,
};

export default ExpectedIpfxCollectionsField;
