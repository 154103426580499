import { Route } from "src/common/routing";
import { useSetupData } from "src/components/routes/Setup/common/hooks/useSetupData";
import {
  CreditorType,
  RequiredActionsName,
} from "@gocardless/api/dashboard/types";
import {
  ControlProngType,
  ShareHolderType,
} from "src/components/routes/Setup/business-owners/useBusinessOwners";
import {
  useRequiredActionList,
  useRequiredActionSubmitAchDetails,
} from "@gocardless/api/dashboard/required-action";
import { ownersBuilder } from "src/components/routes/Setup/common/builders/owners";
import { formatAchUpliftDetails } from "src/components/routes/Setup/common/builders/achUpliftDetails";
import { AmlPersonWithAddressResource } from "@gocardless/api/staff/types";
import { useCreditorDetailSelf } from "@gocardless/api/dashboard/creditor-detail";

import { UseSetupPage, UseSetupPageCallbacks } from "../routing/types";

export interface AchUpliftDetailsConfig {
  shareholders?: ShareHolderType[];
  control_prongs?: ControlProngType[];
  person?: AmlPersonWithAddressResource;
}

export interface AchUpliftDetails extends UseSetupPage {
  achUpliftDetails: AchUpliftDetailsConfig;
  submitAchUpliftDetails: (data: AchUpliftDetailsConfig) => void;
  creditorType?: CreditorType;
  countryCode: string;
}

const SUPPORTED_CREDITOR_TYPES = [
  CreditorType.Company,
  CreditorType.Individual,
  CreditorType.Partnership,
  CreditorType.Trust,
];

export function useAchUpliftDetails({
  onSuccess = () => {},
  onError = () => {},
}: UseSetupPageCallbacks = {}): AchUpliftDetails {
  const { creditor, creditorDetails, updateCreditorDetail } = useSetupData();
  const { data: requiredActions, mutate } = useRequiredActionList();
  const { mutate: revalidateCreditorDetails } = useCreditorDetailSelf(
    creditor?.id || null
  );

  const fillAchDetailsVerification = requiredActions?.required_actions?.find(
    (requiredAction) =>
      requiredAction.name === RequiredActionsName.FillAchDetails
  );
  const [submitAchDetails] = useRequiredActionSubmitAchDetails(
    fillAchDetailsVerification?.id || "",
    {
      onSuccess: () => {
        mutate()
          .then(() => revalidateCreditorDetails())
          .then(() => onSuccess());
      },
      onError,
    }
  );

  const creditorType = creditorDetails?.creditor_type || undefined;
  const countryCode = creditorDetails?.detail?.country_code ?? "";
  const achUpliftInitiated = !!creditor?.ach_uplift_initiated;
  const creditorTypeSupported =
    creditorType && SUPPORTED_CREDITOR_TYPES.includes(creditorType);

  const { shareholders = [], control_prongs = [] } =
    ownersBuilder(creditorDetails);
  const person = creditorDetails?.detail?.person || {};

  const achUpliftDetails = { shareholders, control_prongs, person };

  const submitAchUpliftDetails = (formData: AchUpliftDetailsConfig) => {
    const requestBody = formatAchUpliftDetails(
      formData,
      creditorDetails?.detail || {}
    );
    submitAchDetails(requestBody).then(() =>
      updateCreditorDetail({ creditor_type: creditorType })
    );
  };

  let completed: boolean;

  if (achUpliftInitiated) {
    const people =
      creditorType === CreditorType.Company
        ? [...(shareholders as ShareHolderType[]), ...control_prongs]
        : [person];
    completed =
      people.every(
        (one) => one.social_security_number || one.passport_number
      ) &&
      (creditorType !== CreditorType.Company || control_prongs.length > 0);
  } else {
    completed = true;
  }

  return {
    loaded: !!creditorDetails && !!requiredActions,
    completed: completed,
    skip: !achUpliftInitiated || !creditorTypeSupported,
    route: Route.AchUpliftDetails,
    achUpliftDetails,
    submitAchUpliftDetails,
    creditorType,
    countryCode,
  };
}
