import { CalendarDate, DatesI18n } from "@gocardless/flux-react";

export const fromCalendarDate = (d: CalendarDate): Date =>
  new Date(d.year, d.month - 1, d.day);

export const i18nEn: DatesI18n = {
  instructionsText: "Arrow keys can navigate dates.",
  previousMonthButtonLabel: "Previous",
  nextMonthButtonLabel: "Next",
  dayInputLabel: "Day",
  dayInputPlaceholder: "dd",
  monthInputLabel: "Month",
  monthInputPlaceholder: "mm",
  yearInputLabel: "Year",
  yearInputPlaceholder: "yyyy",
  changeDateButtonLabel: "Change date",

  getDaysOfWeek() {
    return {
      mon: { full: "Monday", short: "Mo" },
      tue: { full: "Tuesday", short: "Tu" },
      wed: { full: "Wednesday", short: "We" },
      thu: { full: "Thursday", short: "Th" },
      fri: { full: "Friday", short: "Fr" },
      sat: { full: "Saturday", short: "Sa" },
      sun: { full: "Sunday", short: "Su" },
    };
  },
  getDateLabel(date) {
    return new Intl.DateTimeFormat("en", { dateStyle: "full" }).format(
      fromCalendarDate(date)
    );
  },
  getSelectedDateLabel(date) {
    return `Selected date: ${i18nEn.getDateLabel(date)}`;
  },
  getDayLabel(day) {
    return `${day}`;
  },
  getMonthYearLabel(date) {
    return new Intl.DateTimeFormat("en", {
      month: "long",
      year: "numeric",
    }).format(fromCalendarDate(date));
  },
};
