import { useState, forwardRef } from "react";
import {
  Field,
  DatePicker,
  CalendarDate,
  DateInputProps,
} from "@gocardless/flux-react";
import { i18nEn } from "src/components/ui/RestrictedDatePicker/DatePicker";
import { CountryCodes } from "src/common/country";

const dateToString = (date: Partial<CalendarDate> | undefined): {} => {
  if (!date) return {};
  return `${date?.year}-${date?.month}-${date?.day}`;
};

/**
 * The initialDate prop is a string usually in the formay "mm/dd/yyyy", "yyyy/mm/dd", "mm-dd-yyyy or yyyy-dd-mm" etc
 * In certain instances, initialDate is "yyyy-mm-" without the day value
 * Converting such values into a date object with new Date(initialDate) will default the day value to 1
 * We want the default state day value of calendarDate to be undefined if initialDate has no day value
 * Can add other checks for other formats here later
 */
const initialDateWithoutDay = (initialDate?: string) =>
  // Check if the input string is in the "yyyy-mm-" format
  /^(\d{4})-(\d{2})-?$/.test(String(initialDate));

interface DatePickerInputProps {
  initialDate?: string;
  countryCode: CountryCodes;
}

const DatePickerInput = forwardRef<
  HTMLLabelElement,
  Omit<DateInputProps, "i18n" | "format" | "value"> & DatePickerInputProps
>(({ status, onChange, initialDate, countryCode, ...field }, ref) => {
  const defaultDate =
    initialDate && !isNaN(new Date(initialDate).getDate())
      ? new Date(initialDate)
      : null;

  const [calendarDate, setCalendarDate] = useState<
    Partial<CalendarDate> | undefined
  >({
    year: defaultDate?.getFullYear(),
    month: defaultDate ? defaultDate?.getMonth() + 1 : undefined,
    day: initialDateWithoutDay(initialDate)
      ? undefined
      : defaultDate?.getDate(),
  });

  const handleChange = (e: Partial<CalendarDate> | undefined) => {
    const newValue = dateToString(e);
    onChange?.(newValue);
  };

  const dateFormatIntl = [CountryCodes.US].includes(countryCode)
    ? "mm-dd-yyyy"
    : "dd-mm-yyyy";

  return (
    <Field>
      <DatePicker
        {...field}
        ref={ref}
        calendar="disabled"
        format={dateFormatIntl}
        id="dob-di"
        data-testid="dob-di"
        onChange={(e) => {
          setCalendarDate(e);
          handleChange(e);
        }}
        aria-labelledby="doblabel-di"
        i18n={i18nEn}
        value={calendarDate}
        status={status}
      />
    </Field>
  );
});

export default DatePickerInput;
