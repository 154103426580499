import { ReactElement } from "react";
import {
  Field,
  Label,
  Option,
  Box,
  Space,
  Select,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { PartnershipType } from "@gocardless/api/dashboard/types";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { Field as Config } from "../config/types";

const AUPartnershipOptions = () => {
  const { i18n } = useLingui();
  return (
    <>
      <Option value={PartnershipType.LimitedPartnership}>
        {i18n._(
          t({
            id: "setup.setup.business-detrails.limited-liability-partnership",
            message: "Limited Liability Partnership",
          })
        )}
      </Option>
      <Option value={PartnershipType.UnlimitedPartnership}>
        {i18n._(
          t({
            id: "setup.setup.business-detrails.partnership-unlimited",
            message: "Partnership (unlimited)",
          })
        )}
      </Option>
    </>
  );
};

const CAPartnershipOptions = () => {
  const { i18n } = useLingui();
  return (
    <>
      <Option value={PartnershipType.GeneralPartnership}>
        {i18n._(
          t({
            id: "setup.business-details.general-partnership",
            message: "General Partnership",
          })
        )}
      </Option>
      <Option value={PartnershipType.LimitedPartnership}>
        {i18n._(
          t({
            id: "setup.setup.business-detrails.limited-liability-partnership",
            message: "Limited Liability Partnership",
          })
        )}
      </Option>
    </>
  );
};

const DEPartnershipOptions = () => {
  const { i18n } = useLingui();
  return (
    <>
      <Option value={PartnershipType.Kg}>
        {i18n._(
          t({
            id: "setup.business-details.kg-partnership",
            message: "Kommanditgesellschaft (KG)",
          })
        )}
      </Option>
      <Option value={PartnershipType.Ohg}>
        {i18n._(
          t({
            id: "setup.business-details.ohg-partnership",
            message: "Offene Handelsgesellschaft (oHG)",
          })
        )}
      </Option>
      <Option value={PartnershipType.Gbr}>
        {i18n._(
          t({
            id: "setup.business-details.gbr-partnership",
            message: "Gesellschaft bürgerlichen Rechts (GbR)",
          })
        )}
      </Option>
      <Option value={PartnershipType.Blended}>
        {i18n._(
          t({
            id: "setup.business-details.blended-partnership",
            message: "GmbH & Co. KG / UG & Co. KG",
          })
        )}
      </Option>
    </>
  );
};

const PartnershipTypeField: React.FC<{ partnershipOptions: ReactElement }> = ({
  partnershipOptions,
}) => {
  const { register } = useFormContext();
  return (
    <>
      <Field>
        <Box layout="flex">
          <Label htmlFor="partnership_type" css={{ paddingTop: "3px" }}>
            <Trans id="setup.business-details.partnership-type">
              Partnership type
            </Trans>
          </Label>
        </Box>
        <Select
          id="partnership_type"
          {...register("partnership_type", {
            required: true,
            deps: ["partnership_number"],
          })}
        >
          {partnershipOptions}
        </Select>
      </Field>
      <Space v={2} />
    </>
  );
};

const AUPartnershipTypeField = () => (
  <PartnershipTypeField partnershipOptions={<AUPartnershipOptions />} />
);

const CAPartnershipTypeField = () => (
  <PartnershipTypeField partnershipOptions={<CAPartnershipOptions />} />
);

const DEPartnershipTypeField = () => (
  <PartnershipTypeField partnershipOptions={<DEPartnershipOptions />} />
);

export const AUConfig: Config = {
  name: "partnership_type",
  displayName: "Partnership type",
  component: AUPartnershipTypeField,
};

export const CAConfig: Config = {
  name: "partnership_type",
  displayName: "Partnership type",
  component: CAPartnershipTypeField,
};

export const DEConfig: Config = {
  name: "partnership_type",
  displayName: "Partnership type",
  component: DEPartnershipTypeField,
};
