import { Route } from "src/common/routing";
import { PackageType } from "src/components/routes/Setup/common/types/packageType";
import {
  OrganisationType,
  useOrganisationWithType,
} from "src/queries/organisationType";
import { UpgradesStatus, UpgradeType } from "@gocardless/api/dashboard/types";
import { usePrimaryCreditor } from "src/queries/creditor";
import {
  useUpgradeCreate,
  useUpgradeList,
} from "@gocardless/api/dashboard/upgrade";
import { HTTPError } from "@gocardless/api/utils/api";

import { UseSetupPage } from "../routing/types";

export interface BankStatementName extends UseSetupPage {
  desiredSunName?: string;
  upgradeStatus?: UpgradesStatus;
  submitBankStatementName: (
    bankStatementNameConfig: BankStatementNameConfig
  ) => Promise<void>;
}

export interface BankStatementNameConfig {
  desired_sun_name?: string;
}

export const useBankStatementName = ({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: (error: HTTPError) => void;
} = {}): BankStatementName => {
  const { organisation, organisationType } = useOrganisationWithType();
  const isPaymentProvider =
    organisationType === OrganisationType.PaymentProvider;
  const creditor = usePrimaryCreditor();
  const { data: upgrades, revalidate: revalidateUpgrades } = useUpgradeList();
  const [createUpgrade] = useUpgradeCreate({
    onSuccess: () => revalidateUpgrades().then(onSuccess),
    onError,
  });

  const ownSunUpgrade = (upgrades?.upgrades ?? []).find(
    (upgrade) => upgrade.upgrade_type === UpgradeType.OwnSun
  );

  const desiredSunName = ownSunUpgrade?.desired_sun_name ?? undefined;
  const upgradeStatus = ownSunUpgrade?.status ?? undefined;

  async function submitBankStatementName({
    desired_sun_name,
  }: BankStatementNameConfig) {
    if (ownSunUpgrade) {
      onSuccess();
      return;
    }

    await createUpgrade({
      upgrade_type: UpgradeType.OwnSun,
      desired_sun_name,
    });
  }

  // If the merchant is on pricing V3 - display this step for merchants that have chosen an add-on of own_sun
  // otherwise if they are on pricing V2 - display this step for merchants that have other packages
  const isOnPricingV3 = !!organisation?.is_on_pricing_v3;
  const selectedOwnSunUpgrade =
    (ownSunUpgrade && ownSunUpgrade.status !== UpgradesStatus.Inactive) ||
    !!organisation?.organisation_preferences?.merchant_has_requested_custom_sun;
  const skipBasedOnPackage = isOnPricingV3
    ? !selectedOwnSunUpgrade
    : organisation?.package_state === PackageType.Standard;

  // Payment providers have custome SUNs, but they are not managed through the dashboard.
  // Instead they are provisioned by customer operations or through the API.
  // Therefore we can always skip this step for payment providers.
  const skip = skipBasedOnPackage || isPaymentProvider;

  return {
    loaded: !!organisation && !!creditor && !!upgrades,
    route: Route.BankStatementName,
    skip,
    completed: !!ownSunUpgrade,
    desiredSunName,
    upgradeStatus,
    submitBankStatementName,
  };
};
