import { ReactElement } from "react";
import { Field, Label, Option, Select } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { Trans } from "@lingui/macro";
import { COUNTRY_CODES } from "src/common/constants/countryCodes";
import { useLingui } from "@lingui/react";

import { Field as Config, FieldArrayProps } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

interface TaxJurisdictionFieldProps extends FieldArrayProps {
  countryCode: string;
  labelElement?: ReactElement;
  tooltip?: ReactElement;
}

const taxJurisidictionsByCountryCode = {
  GB: ["GB", "GG", "GI", "JE"],
  FR: ["FR", "GP", "MQ", "RE", "PM", "FR-COR", "YT", "GF"],
};

const TaxJurisdictionField: React.FC<TaxJurisdictionFieldProps> = ({
  countryCode,
  defaultValue,
  labelElement,
  tooltip,
}) => {
  const { i18n } = useLingui();
  const { register } = useFormContext<BusinessDetailsConfig>();

  const taxJurisidictions = COUNTRY_CODES(i18n).filter(({ code }) =>
    taxJurisidictionsByCountryCode[
      countryCode as keyof typeof taxJurisidictionsByCountryCode
    ].includes(code)
  );

  return (
    <Field>
      <Label htmlFor="tax_jurisdiction">
        {labelElement ?? (
          <Trans id="setup.business-details.tax-jurisdiction">
            Where do you pay VAT?
          </Trans>
        )}
        {tooltip}
      </Label>
      <Select
        id="tax_jurisdiction"
        {...register("tax_jurisdiction", { required: true })}
        defaultValue={defaultValue}
      >
        {taxJurisidictions.map(({ code, name }) => (
          <Option key={code} value={code}>
            {name}
          </Option>
        ))}
      </Select>
    </Field>
  );
};

const FRTaxJurisdictionField: React.FC<TaxJurisdictionFieldProps> = (props) => (
  <TaxJurisdictionField
    {...props}
    labelElement={
      <Trans id="setup.business-details.where-do-you-pay-tva">
        Where do you pay TVA?
      </Trans>
    }
  />
);

export const config: Config = {
  name: "tax_jurisdiction",
  displayName: (
    <Trans id="setup.business-details.tax-jurisdiction">
      Where do you pay VAT?
    </Trans>
  ),
  component: TaxJurisdictionField,
};

export const FRConfig: Config = {
  name: "tax_jurisdiction",
  displayName: (
    <Trans id="setup.business-details.where-do-you-pay-tva">
      Where do you pay TVA?
    </Trans>
  ),
  component: FRTaxJurisdictionField,
};

export default TaxJurisdictionField;
