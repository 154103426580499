import { AchUpliftDetailsConfig } from "src/components/routes/Setup/ach-uplift-details/useAchUpliftDetails";
import {
  AmlPersonIdAndAddress,
  ControlProngElement,
  RequiredActionSubmitAchDetailsRequestBody,
} from "@gocardless/api/dashboard/types";
import {
  AmlPersonWithAddressResource,
  DetailResource,
} from "@gocardless/api/staff/types";
import _ from "lodash";
import { ShareHolderType } from "src/components/routes/Setup/business-owners/useBusinessOwners";
import {
  ADDRESS_FIELDS,
  INDIVIDUAL_NUMBER_FIELDS,
} from "src/components/routes/Setup/common/builders/types";
import { DirectorType } from "src/components/routes/Setup/business-directors/useBusinessDirectors";
import moment from "moment";

export const addressPresent = (person: ControlProngElement) =>
  !!(person.street && person.city && person.postal_code);

export const getPersonIdWithAddress = (
  formPerson: ShareHolderType | AmlPersonWithAddressResource,
  apiPerson: ControlProngElement | AmlPersonWithAddressResource
): AmlPersonIdAndAddress => {
  const fields = addressPresent(apiPerson)
    ? INDIVIDUAL_NUMBER_FIELDS
    : [...INDIVIDUAL_NUMBER_FIELDS, ...ADDRESS_FIELDS];
  return {
    id: apiPerson.id,
    ..._.pick(_.omitBy(formPerson, _.isNull), fields),
  };
};
export const formatAchUpliftDetails = (
  achUpliftDetails: AchUpliftDetailsConfig,
  creditorDetails: DetailResource
): RequiredActionSubmitAchDetailsRequestBody => {
  const shareholders = creditorDetails.shareholders?.map(
    (shareholder, index) => {
      const formShareholder = _.get(
        achUpliftDetails,
        `shareholders[${index}]`,
        ""
      ) as ShareHolderType;
      return getPersonIdWithAddress(formShareholder, shareholder);
    }
  );
  const person = getPersonIdWithAddress(
    achUpliftDetails.person || {},
    creditorDetails.person || {}
  );
  return {
    shareholders,
    control_prongs: _.get(achUpliftDetails, "control_prongs", []),
    person: person.id ? person : undefined,
  };
};

export const formatDateOfBirth = (dateOfBirth: string): string => {
  try {
    const date = moment(dateOfBirth);
    return date.format("Do MMMM YYYY");
  } catch (e) {
    return dateOfBirth;
  }
};

export const formatAddress = (
  person: ShareHolderType | DirectorType,
  countryName?: string
): string =>
  [
    [person.flat_number, person.building_number, person.building_name]
      .filter((value) => !!value)
      .join(" "),
    person.street,
    person.city,
    person.region,
    person.postal_code,
    countryName,
  ]
    .filter((value) => !!value)
    .join(", ") + ".";
