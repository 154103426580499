export const removeEmptyObjProperties = <T>(obj: T): T => {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return obj;
  }

  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        // Handles nested objects
        acc[key] = removeEmptyObjProperties(value);
      }
      return acc;
    },
    {} as Record<string, string | number>
  ) as T;
};
