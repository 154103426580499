import { CreditorType } from "@gocardless/api/dashboard/types";

/**
 * Returns true if the creditor type is one of the non-business organisation types;
 * charity, trust or partnership.
 */
export default function isOrganisationCreditorType(
  creditorType: CreditorType
): boolean {
  return [
    CreditorType.Charity,
    CreditorType.Trust,
    CreditorType.Partnership,
  ].includes(creditorType);
}
