import { hasIn, get } from "lodash";
import {
  Field,
  Label,
  Option,
  Box,
  Space,
  Tooltip,
  Hint,
  FormFieldStatus,
  Select,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useFormContext } from "react-hook-form";
import { Field as Config } from "src/components/routes/Setup/common/config/types";
import { PaymentVolumesConfig } from "src/components/routes/Setup/payment-volumes/usePaymentVolumes";
import { countryToCurrency, Currency } from "src/common/currencies";
import { CountryCodes } from "src/common/country";
import { currencyMapping } from "src/components/payment-sharing/helpers";
import { useLingui } from "@lingui/react";
import { I18n } from "@lingui/core";

type ExpectedCollectionValues =
  | "0_10k"
  | "10k_100k"
  | "100k_250k"
  | "250k_1m"
  | "1m_5m"
  | "5m_10m"
  | "over_10m";

const amountByCurrency: Record<Currency, string> = {
  [Currency.Gbp]: "10M",
  [Currency.Aud]: "20M",
  [Currency.Cad]: "20M",
  [Currency.Dkk]: "100M",
  [Currency.Eur]: "10M",
  [Currency.Nzd]: "20M",
  [Currency.Sek]: "100M",
  [Currency.Usd]: "10M",
};

const getExpectedCollectionsLabels = (i18n: I18n, currency: Currency) => {
  const amount = amountByCurrency[currency];

  const expectedCollectionsLabels: Record<
    Currency,
    Record<ExpectedCollectionValues, string>
  > = {
    [Currency.Gbp]: {
      "0_10k": "0-10,000",
      "10k_100k": "10,000-100,000",
      "100k_250k": "100,000-250,000",
      "250k_1m": "250,000-1M",
      "1m_5m": "1M-5M",
      "5m_10m": "5M-10M",
      over_10m: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Aud]: {
      "0_10k": "0-20,000",
      "10k_100k": "20,000-250,000",
      "100k_250k": "250,000-500,000",
      "250k_1m": "500,000-2M",
      "1m_5m": "2-10M",
      "5m_10m": "10M-20M",
      over_10m: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Cad]: {
      "0_10k": "0-20,000",
      "10k_100k": "20,000-200,000",
      "100k_250k": "200,000-500,000",
      "250k_1m": "500,000-2M",
      "1m_5m": "2M-10M",
      "5m_10m": "10M-20M",
      over_10m: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Dkk]: {
      "0_10k": "0-100,000",
      "10k_100k": "100,000-1M",
      "100k_250k": "1M-2M",
      "250k_1m": "2M-10M",
      "1m_5m": "10M-50M",
      "5m_10m": "50M-100M",
      over_10m: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Eur]: {
      "0_10k": "0-10,000",
      "10k_100k": "10,000-100,000",
      "100k_250k": "100,000-250,000",
      "250k_1m": "250,000-1M",
      "1m_5m": "1M-5M",
      "5m_10m": "5M-10M",
      over_10m: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Nzd]: {
      "0_10k": "0-20,000",
      "10k_100k": "20,000-250,000",
      "100k_250k": "250,000-500,000",
      "250k_1m": "500,000-2M",
      "1m_5m": "2-10M",
      "5m_10m": "10M-20M",
      over_10m: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Sek]: {
      "0_10k": "0-100,000",
      "10k_100k": "100,000-1M",
      "100k_250k": "1M-2M",
      "250k_1m": "2M-10M",
      "1m_5m": "10M-50M",
      "5m_10m": "50M-100M",
      over_10m: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
    [Currency.Usd]: {
      "0_10k": "0-10,000",
      "10k_100k": "10,000-100,000",
      "100k_250k": "100,000-250,000",
      "250k_1m": "250,000-1M",
      "1m_5m": "1M-5M",
      "5m_10m": "5M-10M",
      over_10m: i18n._(
        t({
          id: "setup.payment-volumes.expected-collections.over-amount",
          message: `Over ${amount}`,
        })
      ),
    },
  };

  return expectedCollectionsLabels[currency];
};

interface ExpectedCollectionsFieldProps {
  geo: string;
}

const ExpectedCollectionsField: React.FC<ExpectedCollectionsFieldProps> = ({
  geo,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<PaymentVolumesConfig>();
  const { i18n } = useLingui();

  const currency = countryToCurrency(geo as CountryCodes);
  const labels = getExpectedCollectionsLabels(i18n, currency);

  return (
    <>
      <Field>
        <Box layout="flex">
          <Label htmlFor="expected_collections">
            <Trans id="setup.payment-volumes.expected-collections-title">
              How much do you expect to collect via GoCardless in the next year?
            </Trans>
          </Label>
          <Space layout="inline" h={0.5} />
          <Tooltip
            tooltipId="expected-collections-tooltip"
            message={
              <Trans id="setup.payment-volumes.expected-collections-tooltip">
                Estimate the total value of payments you expect to collect using
                GoCardless in the next year.
              </Trans>
            }
          >
            <Trans id="setup.payment-volumes.expected-collections-title">
              How much do you expect to collect via GoCardless in the next year?
            </Trans>
          </Tooltip>
        </Box>
        <Select
          id="expected_collections"
          {...register("expected_collections", {
            required: i18n._({
              id: "setup.payment-volumes.required-error",
              message: "Please select an option — this can be an estimate.",
            }),
          })}
        >
          <Option value="">
            {i18n._({
              id: "setup.payment-volumes.please-select",
              message: "Please select...",
            })}
          </Option>

          {Object.entries(labels).map(([value, label]) => (
            <Option value={value} key={value}>
              {`${label} (${currencyMapping[currency]})`}
            </Option>
          ))}
        </Select>
        {hasIn(errors, `expected_collections`) && (
          <Hint status={FormFieldStatus.Danger}>
            {get(errors, "expected_collections.message")}
          </Hint>
        )}
      </Field>
    </>
  );
};

export const config: Config = {
  name: "expected_collections",
  required: true,
  displayName: "Expected collections",
  component: ExpectedCollectionsField,
};

export default ExpectedCollectionsField;
