import { get, hasIn } from "lodash";
import { useFormContext } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
} from "@gocardless/flux-react";
import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";
import { TrusteeType } from "@gocardless/api/dashboard/types";

import { presenceCheck } from "./helpers";

type GivenNameFieldProps = {
  fieldPath: string;
  requiredError: string;
} & FieldArrayProps;

const GivenNameField: React.FC<GivenNameFieldProps> = ({
  defaultValue,
  fieldPath,
  requiredError,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="Given name">Given name</Trans>
      </Label>
      <Input
        {...register(fieldPath, presenceCheck(requiredError))}
        name={fieldPath}
        id={fieldPath}
        defaultValue={defaultValue}
        className="fs-exclude"
      />
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}`)?.message as string}
        </Hint>
      )}
    </Field>
  );
};

const DirectorGivenNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <GivenNameField
      {...props}
      fieldPath={`directors[${props.index}].given_name`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-first-name",
          message: "Please enter director’s first name",
        })
      )}
    />
  );
};

const OwnerGivenNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <GivenNameField
      {...props}
      fieldPath={`shareholders[${props.index}].given_name`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-owner-first-name",
          message: "Please enter owner's first name",
        })
      )}
    />
  );
};

const ControlGivenNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <GivenNameField
      {...props}
      fieldPath={`control_prongs[${props.index}].given_name`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-person-first-name",
          message: "Please enter first name",
        })
      )}
    />
  );
};

const PersonGivenNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <GivenNameField
      {...props}
      fieldPath="person.given_name"
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-person-first-name",
          message: "Please enter first name",
        })
      )}
    />
  );
};

const TrusteeGivenNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <GivenNameField
      {...props}
      fieldPath={`trustees[${props.index}].given_name`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-person-first-name",
          message: "Please enter first name",
        })
      )}
    />
  );
};

const PartnerGivenNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <GivenNameField
      {...props}
      fieldPath={`partners[${props.index}].given_name`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-person-first-name",
          message: "Please enter first name",
        })
      )}
    />
  );
};
const UBOGivenNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <GivenNameField
      {...props}
      fieldPath={`ultimate_beneficial_owners[${props.index}].given_name`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-person-first-name",
          message: "Please enter first name",
        })
      )}
    />
  );
};

export const directorConfig: Config = {
  name: "given_name",
  displayName: "Given name",
  required: true,
  component: DirectorGivenNameField,
};

export const ownerConfig: Config = {
  name: "given_name",
  displayName: "Given name",
  component: OwnerGivenNameField,
};

export const controlConfig: Config = {
  name: "given_name",
  displayName: "Given name",
  required: true,
  component: ControlGivenNameField,
};

export const personConfig: Config = {
  name: "given_name",
  displayName: "Given name",
  component: PersonGivenNameField,
};

export const trusteeConfig: Config = {
  name: "given_name",
  displayName: "Given name",
  required: ({ type }) => !type || type === TrusteeType.Person,
  component: TrusteeGivenNameField,
};

export const partnerConfig: Config = {
  name: "given_name",
  displayName: "Given name",
  required: true,
  component: PartnerGivenNameField,
};

export const uboConfig: Config = {
  name: "given_name",
  displayName: "Given name",
  required: true,
  component: UBOGivenNameField,
};

export default GivenNameField;
