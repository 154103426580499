import { useSupportedCountryList } from "@gocardless/api/dashboard/supported-country";
import { CreditorType } from "@gocardless/api/dashboard/types";
import { CountryCodeSupported } from "src/common/country";

/**
 * This hook is used to get the list of country codes supported by gocardless.
 * It also returns a function which can be used to check the creditor types
 * supported in a given country code.
 * This hook uses the /supported_countries endpoint in payment service.
 *
 * @returns { supportedCountries, isCreditorTypeSupported, isLoading }
 * supportedCountries is an array of country codes supported by gocardless
 * isCreditorTypeSupported is a function which accepts the country code
 *      and creditor type as parameters and returns a boolean to specify
 *      if the creditor type is allowed in that geo.
 * isLoading is a boolean that returns the state of the fetcher function
 */

export const useSupportedCountries: () => {
  supportedCountries: CountryCodeSupported[];
  isCreditorTypeSupported: (
    countryCode?: CountryCodeSupported,
    creditorType?: CreditorType
  ) => boolean;
  isLoading: boolean;
} = () => {
  const { data, isLoading } = useSupportedCountryList();
  const supportedCountriesList = data?.supported_countries?.countries || [];

  const supportedCountries = supportedCountriesList.map(
    (country) =>
      CountryCodeSupported[country.code as keyof typeof CountryCodeSupported]
  );

  const isCreditorTypeSupported = (
    countryCode?: CountryCodeSupported,
    creditorType?: CreditorType
  ) => {
    if (!countryCode || !creditorType) {
      return false;
    }
    const country = supportedCountriesList.find(
      (e) => e.code === countryCode.valueOf()
    );
    const supportedCreditorTypes = country?.supported_creditor_types || {};
    return !!supportedCreditorTypes[creditorType];
  };
  return { supportedCountries, isCreditorTypeSupported, isLoading };
};
