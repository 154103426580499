import { HTTPError } from "@gocardless/api/utils/api";
import { getErrorsFromErrorResponse } from "@gocardless/api/utils/error";
import { useToastNotification } from "src/hooks/useToastNotification";
import { Route, routerPush } from "src/common/routing";
import _ from "lodash";
import { useContext, useState } from "react";
import { NavRouteContext } from "src/components/layout/SetupPageLayout";
import { Trans } from "@lingui/macro";

//@todo move to common
const useToast = (pushRoute?: Route, queryParams?: Record<string, string>) => {
  const { triggerSuccessNotification, triggerErrorNotification } =
    useToastNotification();

  const { nextRoute } = useContext(NavRouteContext);

  const [errorsArray, setErrorsArray] = useState<string[]>([]);

  return {
    onSuccess({ notify }: { notify?: boolean } = {}) {
      notify &&
        triggerSuccessNotification({
          title: null,
          message: (
            <Trans id="setup.use-toast.success-notification">
              We’ve saved your progress
            </Trans>
          ),
          hideOnMobile: true,
        });
      routerPush({ route: pushRoute || nextRoute, queryParams });
    },
    onError: async (httpError: HTTPError) => {
      const errors = await getErrorsFromErrorResponse(httpError);
      setErrorsArray([]);
      errors.forEach((error) => {
        const humanizedRequestPointer = _.capitalize(
          _.last(_.get(error, "request_pointer", "").split("/"))
        ).replace(/_/g, " ");
        setErrorsArray((prev) => [...prev, humanizedRequestPointer]);
        triggerErrorNotification({
          message: `${humanizedRequestPointer} ${error.message}`,
        });
      });
    },
    errors: errorsArray,
  };
};

export default useToast;
