import {
  Field,
  Label,
  Option,
  Box,
  Space,
  Select,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { CompanyType } from "@gocardless/api/dashboard/types";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

import { Field as Config } from "../config/types";

const CompanyTypeField: React.FC = () => {
  const { i18n } = useLingui();
  const { register } = useFormContext();
  return (
    <>
      <Field>
        <Box layout="flex">
          <Label htmlFor="company_type" css={{ paddingTop: "3px" }}>
            <Trans id="setup.company-type.company-type-label">
              Company type
            </Trans>
          </Label>
        </Box>
        <Select
          id="company_type"
          {...register("company_type", { required: true })}
        >
          <Option value={CompanyType.LimitedLiabilityCompany}>
            {i18n._(
              t({
                id: "setup.company-type.limited-liability-company",
                message: "Limited Liability Company",
              })
            )}
          </Option>
          <Option value={CompanyType.Corporation}>
            {i18n._(
              t({
                id: "setup.company-type.corporation",
                message: "Corporation",
              })
            )}
          </Option>
        </Select>
      </Field>
      <Space v={2} />
    </>
  );
};

export const config: Config = {
  name: "company_type",
  displayName: "Company type",
  component: CompanyTypeField,
};

export default CompanyTypeField;
