import {
  Box,
  Button,
  ButtonLayout,
  ButtonProps,
  ButtonVariant,
  Color,
  ColorPreset,
  Glyph,
  Icon,
  Layer,
  Space,
  Visibility,
  useTheme,
} from "@gocardless/flux-react";
import { FC, MouseEventHandler } from "react";
import { createPortal } from "react-dom";
import { ToTranslate } from "src/components/i18n";

import { boxStyle } from "./IntroSubmitButton.styles";

interface IntroSubmitButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isSubmitting?: boolean;
}

export const IntroSubmitButton: FC<IntroSubmitButtonProps> = ({
  onClick,
  disabled = false,
  isSubmitting = false,
}) => {
  const { theme } = useTheme();

  const buttonProps: ButtonProps = {
    type: "button",
    variant: ButtonVariant.PrimaryOnLight,
    disabled: disabled || isSubmitting,
    children: isSubmitting ? (
      <Icon
        css={{
          display: "flex",
        }}
        color={ColorPreset.IconOnLight_01}
        name={Glyph.Spinner}
        size="22px"
      />
    ) : (
      <ToTranslate>Continue</ToTranslate>
    ),
    onClick,
  };

  return (
    <>
      <Visibility visible={["none", null, "block"]}>
        <Space v={3} />
        <Button {...buttonProps} />
      </Visibility>
      {createPortal(
        <Visibility visible={["block", null, "none"]}>
          <Layer mode="fixed" right={0} bottom={0} left={0}>
            <Box
              bg={Color.White}
              gutterH={1.5}
              gutterV={1.5}
              css={[boxStyle(theme)]}
            >
              <Button {...buttonProps} layout={ButtonLayout.Full} />
            </Box>
          </Layer>
        </Visibility>,
        document.body
      )}
    </>
  );
};
