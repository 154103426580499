import { Field, Label, Option, Select } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { Field as Config } from "../config/types";
import { BusinessCategoryConfig } from "../../business-category/useBusinessCategory";
import {
  merchantIndustries,
  merchantIndustriesMapping,
} from "../../business-category/constants";

import { error } from "./helpers";

const CategoryIndustryField = () => {
  const { i18n } = useLingui();
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<BusinessCategoryConfig>();

  return (
    <Field>
      <Label htmlFor="industry">
        <Trans id="setup.business-category.industry">Industry</Trans>
      </Label>
      <Select
        {...register("industry", {
          required: i18n._(
            t({
              id: "setup.business-category.choose-an-industry",
              message: "Please choose an industry",
            })
          ),
          onChange(event) {
            const { value } = event.target;
            setValue(`merchantCategoryCode`, "");
            setValue(`industry`, value);
          },
        })}
        id="industry"
      >
        <Option value="">
          {i18n._(
            t({
              id: "setup.business-category.choose-industry",
              message: "Choose an industry",
            })
          )}
        </Option>

        {Object.entries(merchantIndustries).map((v, i) => (
          <Option key={i} value={v[1]}>
            {merchantIndustriesMapping()[v[1]]}
          </Option>
        ))}
      </Select>
      {error(errors, "industry")}
    </Field>
  );
};

export const config: Config = {
  name: "industry",
  required: true,
  displayName: "Category Industry",
  component: CategoryIndustryField,
};

export default CategoryIndustryField;
