import { ReactNode } from "react";
import {
  OptimizelyExperiment,
  OptimizelyVariation,
} from "@optimizely/react-sdk";

interface OptimizelyExperimentsProps {
  experiments: string[];
  defaultNode: ReactNode;
  children: ReactNode;
}

const OptimizelyExperiments = ({
  experiments,
  children,
  defaultNode,
}: OptimizelyExperimentsProps) => {
  const getNextExperiment = (currentIndex: number): ReactNode =>
    currentIndex < experiments.length ? (
      <OptimizelyExperiment experiment={experiments[currentIndex] || ""}>
        <OptimizelyVariation variation="on">{children}</OptimizelyVariation>
        <OptimizelyVariation default>
          {getNextExperiment(currentIndex + 1)}
        </OptimizelyVariation>
      </OptimizelyExperiment>
    ) : (
      defaultNode
    );
  return <>{getNextExperiment(0)}</>;
};

export default OptimizelyExperiments;
