import { useEffect } from "react";
import { Field, Label, Input } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { Field as Config, FieldArrayProps } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

import { error, validateAddressField } from "./helpers";

const AddressLineTwoField: React.FC<FieldArrayProps> = ({ defaultValue }) => {
  const { i18n } = useLingui();

  const {
    register,
    formState: { errors },
    setValue,
    clearErrors: clearError,
  } = useFormContext<BusinessDetailsConfig>();

  useEffect(() => {
    clearError("address_line2");
    setValue("address_line2", defaultValue);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const addressFormatValidation = validateAddressField(
    `${i18n._(
      t({
        id: "setup.business-details.address-line-2-error",
        message: "Please enter a valid address",
      })
    )}`
  );
  const validationRules = {
    validate: addressFormatValidation,
  };

  return (
    <Field>
      <Label htmlFor="address_line2">
        <Trans id="setup.business-details.address-line-2">
          Address line 2 (optional)
        </Trans>
      </Label>
      <Input
        id="address_line2"
        {...register("address_line2", validationRules)}
        defaultValue={defaultValue}
        className="fs-exclude"
      />
      {error(errors, "address_line2")}
    </Field>
  );
};

export const config: Config = {
  name: "address_line2",
  displayName: "Address line two",
  component: AddressLineTwoField,
};

export default AddressLineTwoField;
