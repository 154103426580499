import { useFormContext } from "react-hook-form";
import { Trans } from "@lingui/macro";
import { Field, Label, Option, Select } from "@gocardless/flux-react";
import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";
import { COUNTRY_CODES } from "src/common/constants/countryCodes";
import { useLingui } from "@lingui/react";

type CountryCodeFieldProps = {
  fieldPath: string;
} & FieldArrayProps;

const CountryCodeField: React.FC<CountryCodeFieldProps> = ({
  defaultValue,
  fieldPath,
}) => {
  const { i18n } = useLingui();
  const { register } = useFormContext();
  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="Country of residence">Country of residence</Trans>
      </Label>
      <Select
        {...register(fieldPath, { required: true })}
        id={fieldPath}
        defaultValue={defaultValue}
      >
        {COUNTRY_CODES(i18n).map(({ code, name }) => (
          // eslint-disable-next-line react/jsx-no-undef
          <Option key={code} value={code}>
            {name}
          </Option>
        ))}
      </Select>
    </Field>
  );
};

const DirectorCountryCodeField: React.FC<FieldArrayProps> = (props) => (
  <CountryCodeField
    {...props}
    fieldPath={`directors[${props.index}].country_code`}
  />
);

const OwnerCountryCodeField: React.FC<FieldArrayProps> = (props) => (
  <CountryCodeField
    {...props}
    fieldPath={`shareholders[${props.index}].country_code`}
  />
);

const ControlCountryCodeField: React.FC<FieldArrayProps> = (props) => (
  <CountryCodeField
    {...props}
    fieldPath={`control_prongs[${props.index}].country_code`}
  />
);

const PersonCountryCodeField: React.FC<FieldArrayProps> = (props) => (
  <CountryCodeField {...props} fieldPath="person.country_code" />
);

const TrusteeCountryCodeField: React.FC<FieldArrayProps> = (props) => (
  <CountryCodeField
    {...props}
    fieldPath={`trustees[${props.index}].country_code`}
  />
);

const PartnerCountryCodeField: React.FC<FieldArrayProps> = (props) => (
  <CountryCodeField
    {...props}
    fieldPath={`partners[${props.index}].country_code`}
  />
);

const UBOCountryCodeField: React.FC<FieldArrayProps> = (props) => (
  <CountryCodeField
    {...props}
    fieldPath={`ultimate_beneficial_owners[${props.index}].country_code`}
  />
);

export const directorConfig: Config = {
  name: "country_code",
  displayName: "Country of residence",
  component: DirectorCountryCodeField,
};

export const ownerConfig: Config = {
  name: "country_code",
  displayName: "Country of residence",
  component: OwnerCountryCodeField,
};

export const controlConfig: Config = {
  name: "country_code",
  displayName: "Country of residence",
  component: ControlCountryCodeField,
};

export const personConfig: Config = {
  name: "country_code",
  displayName: "Country of residence",
  component: PersonCountryCodeField,
};

export const trusteeConfig: Config = {
  name: "country_code",
  displayName: "Country of residence",
  required: true,
  component: TrusteeCountryCodeField,
};

export const partnerConfig: Config = {
  name: "country_code",
  displayName: "Country of residence",
  required: true,
  component: PartnerCountryCodeField,
};

export const uboConfig: Config = {
  name: "country_code",
  displayName: "Country of residence",
  required: true,
  component: UBOCountryCodeField,
};

export default CountryCodeField;
