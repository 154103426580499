import {
  Banner,
  BannerLeftAccessoryType,
  BannerStatus,
  BannerVariant,
  Box,
  ButtonSize,
  ButtonVariant,
  P,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ZendeskLink } from "src/components/ui/ZendeskLink/ZendeskLink";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";

export const UnsupportedCreditorTypeBanner = () => {
  const { isFlagEnabled: isVerificationsUnsupportedEntityType } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.VERIFICATIONS_UNSUPPORTED_ENTITY_TYPE,
    });
  return (
    <Banner
      variant={BannerVariant.Light}
      leftAccessory={{
        type: BannerLeftAccessoryType.Status,
        status: BannerStatus.Alert,
      }}
      title={
        <Trans id="setup.business-details.unsuppported-creditor.country-unsupported">
          Unfortunately we don&apos;t currently support this business type in
          your country
        </Trans>
      }
    >
      {isVerificationsUnsupportedEntityType ? (
        <Box spaceAbove={1}>
          <ZendeskLink
            data-tracking-label="Notify Creditor When Charity/Non-profit Available"
            variant={ButtonVariant.SecondaryOnLight}
            size={ButtonSize.Sm}
            formId={15852425489820}
          >
            <Trans>Notify me when this becomes available</Trans>
          </ZendeskLink>
        </Box>
      ) : (
        <P>
          <Trans id="setup.business-details.unsuppported-creditor.get-in-touch">
            Please get in touch if you would like to be notified when this is
            available.
          </Trans>
        </P>
      )}
    </Banner>
  );
};
