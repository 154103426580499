import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

export enum merchantIndustries {
  AgriculturalServices = "agricultural-services",
  ContractedServices = "contracted-services",
  Transportation = "transportation",
  Utilities = "utilities",
  RetailOutlets = "retail-outlets",
  AutomobilesAndVehicles = "automobiles-and-vehicles",
  ClothingOutlets = "clothing-outlets",
  MiscellaneousOutlets = "miscellaneous-outlets",
  ServiceProviders = "service-providers",
  BusinessServices = "business-services",
  RepairService = "repair-services",
  AmusementAndEntertainment = "amusement-and-entertainment",
  ProfessionalservicesAndMembershipOrganizations = "professional-services-and-membership-organizations",
  GovernmentServices = "government-services",
}

export const merchantIndustriesMapping = (): Record<
  merchantIndustries,
  string
> => ({
  [merchantIndustries.AgriculturalServices]: i18n._(
    t({
      id: "setup.business-category.industry.agricultural-services",
      message: "Agricultural services",
    })
  ),
  [merchantIndustries.ContractedServices]: i18n._(
    t({
      id: "setup.business-category.industry.contracted-services",
      message: "Contracted services",
    })
  ),
  [merchantIndustries.Transportation]: i18n._(
    t({
      id: "setup.business-category.industry.transportation",
      message: "Transportation",
    })
  ),
  [merchantIndustries.Utilities]: i18n._(
    t({
      id: "setup.business-category.industry.utilities",
      message: "Utilities",
    })
  ),
  [merchantIndustries.RetailOutlets]: i18n._(
    t({
      id: "setup.business-category.industry.retail-outlets",
      message: "Retail outlets",
    })
  ),
  [merchantIndustries.AutomobilesAndVehicles]: i18n._(
    t({
      id: "setup.business-category.industry.automobiles-and-vehicles",
      message: "Automobiles and vehicles",
    })
  ),
  [merchantIndustries.ClothingOutlets]: i18n._(
    t({
      id: "setup.business-category.industry.clothing-outlets",
      message: "Clothing outlets",
    })
  ),

  [merchantIndustries.MiscellaneousOutlets]: i18n._(
    t({
      id: "setup.business-category.industry.miscellaneous-outlets",
      message: "Miscellaneous outlets",
    })
  ),

  [merchantIndustries.ServiceProviders]: i18n._(
    t({
      id: "setup.business-category.industry.service-providers",
      message: "Service providers",
    })
  ),

  [merchantIndustries.BusinessServices]: i18n._(
    t({
      id: "setup.business-category.industry.business-services",
      message: "Business services",
    })
  ),
  [merchantIndustries.RepairService]: i18n._(
    t({
      id: "setup.business-category.industry.repair-services",
      message: "Repair services",
    })
  ),
  [merchantIndustries.AmusementAndEntertainment]: i18n._(
    t({
      id: "setup.business-category.industry.amusement-and-entertainment",
      message: "Amusement and entertainment",
    })
  ),
  [merchantIndustries.ProfessionalservicesAndMembershipOrganizations]: i18n._(
    t({
      id: "setup.business-category.industry.professional-services-and-membership-organizations",
      message: "Professional services and membership organizations",
    })
  ),
  [merchantIndustries.GovernmentServices]: i18n._(
    t({
      id: "setup.business-category.industry.government-services",
      message: "Government services",
    })
  ),
});

export const merchantCategories: Record<merchantIndustries, string[]> = {
  "agricultural-services": ["0742", "0743", "0744", "0763", "0780"],
  "contracted-services": [
    "1520",
    "1711",
    "1731",
    "1740",
    "1750",
    "1761",
    "1771",
    "1799",
    "2741",
    "2791",
    "2842",
  ],
  transportation: [
    "4011",
    "4111",
    "4112",
    "4119",
    "4121",
    "4131",
    "4214",
    "4215",
    "4225",
    "4411",
    "4457",
    "4468",
    "4511",
    "4582",
    "4722",
    "4784",
    "4789",
  ],
  utilities: ["4812", "4814", "4815", "4816", "4821", "4829", "4899", "4900"],
  "retail-outlets": [
    "5013",
    "5021",
    "5039",
    "5044",
    "5045",
    "5046",
    "5047",
    "5051",
    "5065",
    "5072",
    "5074",
    "5085",
    "5094",
    "5099",
    "5111",
    "5122",
    "5131",
    "5137",
    "5139",
    "5169",
    "5172",
    "5192",
    "5193",
    "5198",
    "5199",
    "5200",
    "5211",
    "5231",
    "5251",
    "5261",
    "5271",
    "5300",
    "5301",
    "5309",
    "5310",
    "5311",
    "5331",
    "5399",
    "5411",
    "5422",
    "5441",
    "5451",
    "5462",
    "5499",
  ],
  "automobiles-and-vehicles": [
    "5511",
    "5521",
    "5531",
    "5532",
    "5533",
    "5541",
    "5542",
    "5551",
    "5561",
    "5571",
    "5592",
    "5598",
    "5599",
  ],
  "clothing-outlets": [
    "5611",
    "5621",
    "5631",
    "5641",
    "5651",
    "5655",
    "5661",
    "5681",
    "5691",
    "5697",
    "5698",
    "5699",
  ],
  "miscellaneous-outlets": [
    "5712",
    "5713",
    "5714",
    "5715",
    "5718",
    "5719",
    "5722",
    "5732",
    "5733",
    "5734",
    "5735",
    "5811",
    "5812",
    "5813",
    "5814",
    "5912",
    "5913",
    "5921",
    "5931",
    "5932",
    "5933",
    "5935",
    "5937",
    "5940",
    "5941",
    "5942",
    "5943",
    "5944",
    "5945",
    "5946",
    "5947",
    "5948",
    "5949",
    "5950",
    "5960",
    "5962",
    "5963",
    "5964",
    "5965",
    "5966",
    "5967",
    "5968",
    "5969",
    "5970",
    "5971",
    "5972",
    "5973",
    "5975",
    "5976",
    "5977",
    "5978",
    "5983",
    "5992",
    "5993",
    "5994",
    "5995",
    "5996",
    "5997",
    "5998",
    "5999",
  ],
  "service-providers": [
    "6010",
    "6011",
    "6012",
    "6050",
    "6051",
    "6211",
    "6300",
    "6529",
    "6530",
    "6531",
    "6532",
    "6533",
    "6534",
    "6536",
    "6538",
    "7011",
    "7012",
    "7216",
    "7032",
    "7033",
    "7210",
    "7211",
    "7217",
    "7221",
    "7230",
    "7251",
    "7261",
    "7273",
    "7276",
    "7277",
    "7278",
    "7296",
    "7297",
    "7298",
    "7299",
  ],
  "business-services": [
    "7311",
    "7321",
    "7322",
    "7333",
    "7338",
    "7339",
    "7342",
    "7349",
    "7361",
    "7372",
    "7375",
    "7379",
    "7392",
    "7393",
    "7394",
    "7395",
    "7399",
    "7407",
    "7408",
  ],
  "repair-services": [
    "7512",
    "7513",
    "7519",
    "7523",
    "7531",
    "7534",
    "7535",
    "7538",
    "7542",
    "7549",
    "7622",
    "7623",
    "7629",
    "7631",
    "7641",
    "7692",
    "7699",
  ],
  "amusement-and-entertainment": [
    "7829",
    "7832",
    "7841",
    "7911",
    "7922",
    "7929",
    "7932",
    "7933",
    "7941",
    "7991",
    "7992",
    "7993",
    "7994",
    "7995",
    "7996",
    "7997",
    "7998",
    "7999",
  ],
  "professional-services-and-membership-organizations": [
    "8011",
    "8021",
    "8031",
    "8041",
    "8042",
    "8043",
    "8049",
    "8050",
    "8062",
    "8071",
    "8099",
    "8111",
    "8211",
    "8220",
    "8241",
    "8244",
    "8249",
    "8299",
    "8351",
    "8398",
    "8641",
    "8651",
    "8661",
    "8675",
    "8699",
    "8734",
    "8911",
    "8931",
    "8999",
  ],
  "government-services": ["9211", "9222", "9223", "9311", "9399", "9402"],
};

export const prohibitedMerchantCategories = [
  "4511",
  "4722",
  "4829",
  "5051",
  "5094",
  "5122",
  "5169",
  "5172",
  "5271",
  "5301",
  "5309",
  "5310",
  "5311",
  "5331",
  "5551",
  "5561",
  "5571",
  "5592",
  "5598",
  "5715",
  "5813",
  "5912",
  "5913",
  "5921",
  "5933",
  "5944",
  "5962",
  "5966",
  "5993",
  "6051",
  "6532",
  "6534",
  "6538",
  "7011",
  "7012",
  "7273",
  "7277",
  "7297",
  "7322",
  "7549",
  "7996",
];

export const merchantCategoriesCodeMapping = () => ({
  "0742": i18n._(
    t({
      id: "setup.business-category.options.veterinary-services",
      message: "Veterinary services",
    })
  ),
  "0743": i18n._(
    t({
      id: "setup.business-category.options.wine-producers",
      message: "Wine producers",
    })
  ),
  "0744": i18n._(
    t({
      id: "setup.business-category.options.champagne-producers",
      message: "Champagne producers",
    })
  ),
  "0763": i18n._(
    t({
      id: "setup.business-category.options.agricultural-cooperatives",
      message: "Agricultural cooperatives",
    })
  ),
  "0780": i18n._(
    t({
      id: "setup.business-category.options.landscaping-and-horticultural-services",
      message: "Landscaping and horticultural services",
    })
  ),
  1520: i18n._(
    t({
      id: "setup.business-category.options.general-contractors-residential-and-commercial",
      message: "General contractors - residential and commercial",
    })
  ),
  1711: i18n._(
    t({
      id: "setup.business-category.options.heating-plumbing-and-air-conditioning-contractors",
      message: "Heating, plumbing and air-conditioning contractors",
    })
  ),
  1731: i18n._(
    t({
      id: "setup.business-category.options.electrical-contractors",
      message: "Electrical contractors",
    })
  ),
  1740: i18n._(
    t({
      id: "setup.business-category.options.masonry-stonework-tile-setting-plastering-and-insulation-contractors",
      message:
        "Masonry, stonework, tile setting, plastering and insulation contractors",
    })
  ),
  1750: i18n._(
    t({
      id: "setup.business-category.options.carpentry-contractors",
      message: "Carpentry contractors",
    })
  ),
  1761: i18n._(
    t({
      id: "setup.business-category.options.roofing-siding-and-sheet-metal-work-contractors",
      message: "Roofing, siding and sheet metal work contractors",
    })
  ),
  1771: i18n._(
    t({
      id: "setup.business-category.options.concrete-work-contractors",
      message: "Concrete work contractors",
    })
  ),
  1799: i18n._(
    t({
      id: "setup.business-category.options.special-trade-contractors-not-elsewhere-classified",
      message: "Special trade contractors - not elsewhere classified",
    })
  ),
  2741: i18n._(
    t({
      id: "setup.business-category.options.miscellaneous-publishing-and-printing-services",
      message: "Miscellaneous publishing and printing services",
    })
  ),

  2791: i18n._(
    t({
      id: "setup.business-category.options.typesetting-platemaking-and-related-services",
      message: "Typesetting, platemaking and related services",
    })
  ),
  2842: i18n._(
    t({
      id: "setup.business-category.options.speciality-cleaning-polishing-and-sanitation-preparations",
      message: "Speciality cleaning, polishing and sanitation preparations",
    })
  ),
  4112: i18n._(
    t({
      id: "setup.business-category.options.passenger-railways",
      message: "Passenger railways",
    })
  ),
  4121: i18n._(
    t({
      id: "setup.business-category.options.taxi-cabs-and-limousines",
      message: "Taxi-cabs and limousines",
    })
  ),
  4214: i18n._(
    t({
      id: "setup.business-category.options.motor-freight-carriers-and-trucking",
      message:
        "Motor freight carriers and trucking - local and long distance, moving and storage companies and local delivery",
    })
  ),
  4215: i18n._(
    t({
      id: "setup.business-category.options.courier-services-air-and-ground-and-freight-forwarders",
      message: "Courier services - air and ground and freight forwarders",
    })
  ),
  4225: i18n._(
    t({
      id: "setup.business-category.options.public-warehousing-and-storage-farm-products",
      message:
        "Public warehousing and storage - farm products, refrigerated goods and household goods",
    })
  ),
  4457: i18n._(
    t({
      id: "setup.business-category.options.boat-rentals-and-leasing",
      message: "Boat rentals and leasing",
    })
  ),
  4468: i18n._(
    t({
      id: "setup.business-category.options.marinas-marine-service-and-supplies",
      message: "Marinas, marine service and supplies",
    })
  ),
  4511: i18n._(
    t({
      id: "setup.business-category.options.airlines-and-air-carriers",
      message: "Airlines and air carriers",
    })
  ),
  4722: i18n._(
    t({
      id: "setup.business-category.options.travel-agencies-and-tour-operators",
      message: "Travel agencies and tour operators",
    })
  ),
  4789: i18n._(
    t({
      id: "setup.business-category.options.transportation-services",
      message: "Transportation services - not elsewhere classified",
    })
  ),
  4812: i18n._(
    t({
      id: "setup.business-category.options.telecommunication-equipment-and-telephone-sales",
      message: "Telecommunication equipment and telephone sales",
    })
  ),
  4814: i18n._(
    t({
      id: "setup.business-category.options.telecommunication-services",
      message:
        "Telecommunication services, including local and long distance calls, credit card calls, calls through use of magnetic stripe reading telephones and faxes",
    })
  ),
  4815: i18n._(
    t({
      id: "setup.business-category.options.monthly-summary-telephone-charges",
      message: "Monthly summary telephone charges",
    })
  ),
  4816: i18n._(
    t({
      id: "setup.business-category.options.computer-network-information-services",
      message: "Computer network/information services",
    })
  ),
  4821: i18n._(
    t({
      id: "setup.business-category.options.telegraph-services",
      message: "Telegraph services",
    })
  ),
  4829: i18n._(
    t({
      id: "setup.business-category.options.wire-transfers-and-money-orders",
      message: "Wire transfers and money orders",
    })
  ),
  4899: i18n._(
    t({
      id: "setup.business-category.options.cable-and-other-pay-television-services",
      message: "Cable and other pay television services",
    })
  ),
  4900: i18n._(
    t({
      id: "setup.business-category.options.utilities-electric-gas-water-and-sanitary",
      message: "Utilities - electric, gas, water and sanitary",
    })
  ),
  5013: i18n._(
    t({
      id: "setup.business-category.options.motor-vehicle-supplies",
      message: "Motor vehicle supplies and new parts",
    })
  ),
  5021: i18n._(
    t({
      id: "setup.business-category.options.office-and-commercial-furniture",
      message: "Office and commercial furniture",
    })
  ),
  5039: i18n._(
    t({
      id: "setup.business-category.options.construction-materials",
      message: "Construction materials - not elsewhere classified",
    })
  ),

  5044: i18n._(
    t({
      id: "setup.business-category.options.office-photographic-photocopy-microfilm-equipment",
      message: "Office, photographic, photocopy and microfilm equipment",
    })
  ),
  5045: i18n._(
    t({
      id: "setup.business-category.options.computers-computer-peripheral-equipment",
      message:
        "Computers, computer peripheral equipment - not elsewhere classified",
    })
  ),
  5046: i18n._(
    t({
      id: "setup.business-category.options.commercial-equipment",
      message: "Commercial equipment - not elsewhere classified",
    })
  ),
  5047: i18n._(
    t({
      id: "setup.business-category.options.dental-laboratory-medical-ophthalmic-hospital",
      message:
        "Dental/laboratory/medical/ophthalmic hospital equipment and supplies",
    })
  ),
  5051: i18n._(
    t({
      id: "setup.business-category.options.metal-service-centres-and-offices",
      message: "Metal service centres and offices",
    })
  ),
  5065: i18n._(
    t({
      id: "setup.business-category.options.electrical-parts-and-equipment",
      message: "Electrical parts and equipment",
    })
  ),
  5072: i18n._(
    t({
      id: "setup.business-category.options.hardware-equipment-and-supplies",
      message: "Hardware equipment and supplies",
    })
  ),
  5074: i18n._(
    t({
      id: "setup.business-category.options.plumbing-and-heating-equipment-and-supplies",
      message: "Plumbing and heating equipment and supplies",
    })
  ),
  5085: i18n._(
    t({
      id: "setup.business-category.options.industrial-supplies",
      message: "Industrial supplies - not elsewhere classified",
    })
  ),
  5111: i18n._(
    t({
      id: "setup.business-category.options.stationery-office-supplies",
      message: "Stationery, office supplies and printing and writing paper",
    })
  ),
  5122: i18n._(
    t({
      id: "setup.business-category.options.drugs-drug-proprietors",
      message: "Drugs, drug proprietors",
    })
  ),
  5137: i18n._(
    t({
      id: "setup.business-category.options.uniforms-and-commercial-clothing",
      message: "Men’s, women’s and children’s uniforms and commercial clothing",
    })
  ),

  5139: i18n._(
    t({
      id: "setup.business-category.options.commercial-footwear",
      message: "Commercial footwear",
    })
  ),
  5441: i18n._(
    t({
      id: "setup.business-category.options.candy-nut-and-confectionery-shops",
      message: "Candy, nut and confectionery shops",
    })
  ),
  5451: i18n._(
    t({
      id: "setup.business-category.options.dairies",
      message: "Dairies",
    })
  ),
  5462: i18n._(
    t({
      id: "setup.business-category.options.bakeries",
      message: "Bakeries",
    })
  ),
  5511: i18n._(
    t({
      id: "setup.business-category.options.car-and-truck-dealers-new-and-used",
      message:
        "Car and truck dealers (new and used) sales, services, repairs, parts and leasing",
    })
  ),
  5521: i18n._(
    t({
      id: "setup.business-category.options.car-and-truck-dealers-used-only",
      message:
        "Car and truck dealers: (used only) sales, service, repairs, parts and leasing",
    })
  ),
  5531: i18n._(
    t({
      id: "setup.business-category.options.auto-and-home-supply-outlets",
      message: "Auto and home supply outlets",
    })
  ),
  5532: i18n._(
    t({
      id: "setup.business-category.options.automotive-tyre-outlets",
      message: "Automotive tyre outlets",
    })
  ),
  5533: i18n._(
    t({
      id: "setup.business-category.options.automotive-parts-and-accessories-outlets",
      message: "Automotive parts and accessories outlets",
    })
  ),
  5561: i18n._(
    t({
      id: "setup.business-category.options.camper-recreational-trailer-dealers",
      message: "Camper, recreational and utility trailer dealers",
    })
  ),
  5592: i18n._(
    t({
      id: "setup.business-category.options.motor-home-dealers",
      message: "Motor home dealers",
    })
  ),
  5598: i18n._(
    t({
      id: "setup.business-category.options.snowmobile-dealers",
      message: "Snowmobile dealers",
    })
  ),
  5599: i18n._(
    t({
      id: "setup.business-category.options.miscellaneous-automotive-dealers",
      message:
        "Miscellaneous automotive, aircraft and farm equipment dealers - not elsewhere classified",
    })
  ),
  5655: i18n._(
    t({
      id: "setup.business-category.options.sports-and-riding-apparel-shops",
      message: "Sports and riding apparel shops",
    })
  ),
  5712: i18n._(
    t({
      id: "setup.business-category.options.furniture-and-home-furnishings",
      message:
        "Furniture, home furnishings and equipment shops and manufacturers, except appliances",
    })
  ),
  5713: i18n._(
    t({
      id: "setup.business-category.options.floor-covering-services",
      message: "Floor covering services",
    })
  ),
  5714: i18n._(
    t({
      id: "setup.business-category.options.drapery-window-covering-upholstery-shops",
      message: "Drapery, window covering and upholstery shops",
    })
  ),
  5715: i18n._(
    t({
      id: "setup.business-category.options.alcoholic-beverage-wholesalers",
      message: "Alcoholic beverage wholesalers",
    })
  ),
  5732: i18n._(
    t({
      id: "setup.business-category.options.electronics-shops",
      message: "Electronics shops",
    })
  ),
  5733: i18n._(
    t({
      id: "setup.business-category.options.music-shops-musical-instruments-pianos-and-sheet-music",
      message: "Music shops - musical instruments, pianos and sheet music",
    })
  ),
  5734: i18n._(
    t({
      id: "setup.business-category.options.computer-software-outlets",
      message: "Computer software outlets",
    })
  ),

  5811: i18n._(
    t({
      id: "setup.business-category.options.caterers",
      message: "Caterers",
    })
  ),

  5814: i18n._(
    t({
      id: "setup.business-category.options.fast-food-restaurants",
      message: "Fast food restaurants",
    })
  ),
  5912: i18n._(
    t({
      id: "setup.business-category.options.drug-stores-and-pharmacies",
      message: "Drug stores and pharmacies",
    })
  ),
  5913: i18n._(
    t({
      id: "setup.business-category.options.firearms-weapons-and-other-devices",
      message:
        "Firearms, weapons and other devices including parts and ammunition",
    })
  ),
  5932: i18n._(
    t({
      id: "setup.business-category.options.antique-shops-sales-repairs-and-restoration",
      message: "Antique shops - sales, repairs and restoration services",
    })
  ),
  5940: i18n._(
    t({
      id: "setup.business-category.options.bicycle-shops-sales-and-service",
      message: "Bicycle shops - sales and service",
    })
  ),
  5941: i18n._(
    t({
      id: "setup.business-category.options.sporting-goods-shops",
      message: "Sporting goods shops",
    })
  ),
  5942: i18n._(
    t({
      id: "setup.business-category.options.bookshops",
      message: "Bookshops",
    })
  ),
  5943: i18n._(
    t({
      id: "setup.business-category.options.stationery-office-and-school-supply-shops",
      message: "Stationery, office and school supply shops",
    })
  ),
  5946: i18n._(
    t({
      id: "setup.business-category.options.camera-and-photographic-supply-shops",
      message: "Camera and photographic supply shops",
    })
  ),
  5948: i18n._(
    t({
      id: "setup.business-category.options.luggage-and-leather-goods-shops",
      message: "Luggage and leather goods shops",
    })
  ),
  5949: i18n._(
    t({
      id: "setup.business-category.options.sewing-needlework-fabric-and-piece-goods-shops",
      message: "Sewing, needlework, fabric and piece goods shops",
    })
  ),
  5960: i18n._(
    t({
      id: "setup.business-category.options.direct-marketing-insurance-services",
      message: "Direct marketing - insurance services",
    })
  ),

  5962: i18n._(
    t({
      id: "setup.business-category.options.telemarketing-travel-related-arrangement-services",
      message: "Telemarketing - travel-related arrangement services",
    })
  ),
  5963: i18n._(
    t({
      id: "setup.business-category.options.door-to-door-sales",
      message: "Door-to-door sales",
    })
  ),
  5964: i18n._(
    t({
      id: "setup.business-category.options.direct-marketing-catalogue-merchants",
      message: "Direct marketing - catalogue merchants",
    })
  ),
  5965: i18n._(
    t({
      id: "setup.business-category.options.direct-marketing-combination-catalogue-and-retail-merchants",
      message: "Direct marketing - combination catalogue and retail merchants",
    })
  ),
  5966: i18n._(
    t({
      id: "setup.business-category.options.direct-marketing-outbound-telemarketing-merchants",
      message: "Direct marketing - outbound telemarketing merchants",
    })
  ),
  5967: i18n._(
    t({
      id: "setup.business-category.options.direct-marketing-inbound-telemarketing-merchants",
      message: "Direct marketing - inbound telemarketing merchants",
    })
  ),
  5968: i18n._(
    t({
      id: "setup.business-category.options.direct-marketing-subscription-merchants",
      message: "Direct marketing - continuity/subscription merchants",
    })
  ),

  5969: i18n._(
    t({
      id: "setup.business-category.options.direct-marketing",
      message: "Direct marketing/direct marketers - not elsewhere classified",
    })
  ),
  5970: i18n._(
    t({
      id: "setup.business-category.options.artist-supply-and-craft-shops",
      message: "Artist supply and craft shops",
    })
  ),
  5971: i18n._(
    t({
      id: "setup.business-category.options.art-dealers-and-galleries",
      message: "Art dealers and galleries",
    })
  ),

  5975: i18n._(
    t({
      id: "setup.business-category.options.hearing-aids-sales-service-and-supplies",
      message: "Hearing aids - sales, service and supplies",
    })
  ),
  5976: i18n._(
    t({
      id: "setup.business-category.options.orthopaedic-goods-and-prosthetic-devices",
      message: "Orthopaedic goods and prosthetic devices",
    })
  ),

  5977: i18n._(
    t({
      id: "setup.business-category.options.cosmetic-shops",
      message: "Cosmetic shops",
    })
  ),

  5992: i18n._(
    t({
      id: "setup.business-category.options.florists",
      message: "Florists",
    })
  ),
  5993: i18n._(
    t({
      id: "setup.business-category.options.cigar-shops-and-stands",
      message: "Cigar shops and stands",
    })
  ),
  5995: i18n._(
    t({
      id: "setup.business-category.options.pet-shops-pet-food-and-supplies",
      message: "Pet shops, pet food and supplies",
    })
  ),
  5996: i18n._(
    t({
      id: "setup.business-category.options.swimming-pools-sales-supplies-and-services",
      message: "Swimming pools - sales, supplies and services",
    })
  ),
  5997: i18n._(
    t({
      id: "setup.business-category.options.electric-razor-shops-sales-and-service",
      message: "Electric razor shops - sales and service",
    })
  ),
  5998: i18n._(
    t({
      id: "setup.business-category.options.tent-and-awning-shops",
      message: "Tent and awning shops",
    })
  ),
  6012: i18n._(
    t({
      id: "setup.business-category.options.financial-institutions-merchandise-and-services",
      message: "Financial institutions - merchandise and services",
    })
  ),
  6050: i18n._(
    t({
      id: "setup.business-category.options.quasi-cash",
      message:
        "Quasi cash - for instruments being issued by a licensed, regulated financial entity",
    })
  ),
  6211: i18n._(
    t({
      id: "setup.business-category.options.securities-brokers-and-dealers",
      message: "Securities - brokers and dealers",
    })
  ),
  6300: i18n._(
    t({
      id: "setup.business-category.options.insurance-sales-underwriting-and-premiums",
      message: "Insurance sales, underwriting and premiums",
    })
  ),
  6529: i18n._(
    t({
      id: "setup.business-category.options.financial-institutions-general",
      message: "Financial institutions - general",
    })
  ),
  6530: i18n._(
    t({
      id: "setup.business-category.options.remote-stored-value-load",
      message: "Remote stored value load - merchant",
    })
  ),
  6531: i18n._(
    t({
      id: "setup.business-category.options.savings",
      message: "Savings",
    })
  ),
  6532: i18n._(
    t({
      id: "setup.business-category.options.crowdfunding",
      message: "Crowdfunding",
    })
  ),
  6533: i18n._(
    t({
      id: "setup.business-category.options.lending-and-financing",
      message: "Lending and financing",
    })
  ),
  6534: i18n._(
    t({
      id: "setup.business-category.options.money-service-bureau",
      message: "Money Service Bureau",
    })
  ),
  6536: i18n._(
    t({
      id: "setup.business-category.options.pensions",
      message: "Pensions",
    })
  ),
  6538: i18n._(
    t({
      id: "setup.business-category.options.stored-value-cards",
      message: "Stored value cards",
    })
  ),
  7011: i18n._(
    t({
      id: "setup.business-category.options.lodging-hotels-motels-and-resorts",
      message: "Lodging - hotels, motels and resorts",
    })
  ),
  7032: i18n._(
    t({
      id: "setup.business-category.options.sporting-and-recreational-camps",
      message: "Sporting and recreational camps",
    })
  ),
  7033: i18n._(
    t({
      id: "setup.business-category.options.trailer-parks-and-camp-sites",
      message: "Trailer parks and camp-sites",
    })
  ),
  7210: i18n._(
    t({
      id: "setup.business-category.options.laundry-cleaning-and-garment-services",
      message: "Laundry, cleaning and garment services",
    })
  ),

  7211: i18n._(
    t({
      id: "setup.business-category.options.laundry-services-family-and-commercial",
      message: "Laundry services - family and commercial",
    })
  ),

  7217: i18n._(
    t({
      id: "setup.business-category.options.carpet-and-upholstery-cleaning",
      message: "Carpet and upholstery cleaning",
    })
  ),

  7221: i18n._(
    t({
      id: "setup.business-category.options.photographic-studios",
      message: "Photographic studios",
    })
  ),
  7230: i18n._(
    t({
      id: "setup.business-category.options.beauty-and-barber-shops",
      message: "Beauty and barber shops",
    })
  ),
  7261: i18n._(
    t({
      id: "setup.business-category.options.funeral-services-and-crematoriums",
      message: "Funeral services and crematoriums",
    })
  ),
  7273: i18n._(
    t({
      id: "setup.business-category.options.dating-and-escort-services",
      message: "Dating and escort services",
    })
  ),
  7276: i18n._(
    t({
      id: "setup.business-category.options.tax-preparation-services",
      message: "Tax preparation services",
    })
  ),
  7278: i18n._(
    t({
      id: "setup.business-category.options.buying-shopping-services-and-clubs",
      message: "Buying and shopping services and clubs",
    })
  ),
  7297: i18n._(
    t({
      id: "setup.business-category.options.massage-parlours",
      message: "Massage parlours",
    })
  ),
  7298: i18n._(
    t({
      id: "setup.business-category.options.health-and-beauty-spas",
      message: "Health and beauty spas",
    })
  ),
  7311: i18n._(
    t({
      id: "setup.business-category.options.advertising-services",
      message: "Advertising services",
    })
  ),
  7333: i18n._(
    t({
      id: "setup.business-category.options.commercial-photography-art-and-graphics",
      message: "Commercial photography, art and graphics",
    })
  ),
  7338: i18n._(
    t({
      id: "setup.business-category.options.quick-copy-reproduction-and-blueprinting-services",
      message: "Quick copy, reproduction and blueprinting services",
    })
  ),

  7339: i18n._(
    t({
      id: "setup.business-category.options.stenographic-and-secretarial-support-services",
      message: "Stenographic and secretarial support services",
    })
  ),
  7342: i18n._(
    t({
      id: "setup.business-category.options.exterminating-and-disinfecting-services",
      message: "Exterminating and disinfecting services",
    })
  ),
  7349: i18n._(
    t({
      id: "setup.business-category.options.cleaning-maintenance-and-janitorial-services",
      message: "Cleaning, maintenance and janitorial services",
    })
  ),
  7361: i18n._(
    t({
      id: "setup.business-category.options.employment-agencies-and-temporary-help-services",
      message: "Employment agencies and temporary help services",
    })
  ),
  7372: i18n._(
    t({
      id: "setup.business-category.options.computer-programming-data-processing",
      message:
        "Computer programming, data processing and integrated systems design services",
    })
  ),
  7375: i18n._(
    t({
      id: "setup.business-category.options.information-retrieval-services",
      message: "Information retrieval services",
    })
  ),
  7379: i18n._(
    t({
      id: "setup.business-category.options.computer-maintenance-and-repair-services",
      message:
        "Computer maintenance and repair services - not elsewhere classified",
    })
  ),
  7392: i18n._(
    t({
      id: "setup.business-category.options.management-consulting-and-public-relations-services",
      message: "Management, consulting and public relations services",
    })
  ),
  7393: i18n._(
    t({
      id: "setup.business-category.options.detective-agencies-protective-agencies-and-security-services",
      message:
        "Detective agencies, protective agencies and security services, including armoured cars and guard dogs",
    })
  ),
  7394: i18n._(
    t({
      id: "setup.business-category.options.equipment-tool-furniture-and-appliance-rentals-and-leasing",
      message: "Equipment, tool, furniture and appliance rentals and leasing",
    })
  ),
  7395: i18n._(
    t({
      id: "setup.business-category.options.photofinishing-laboratories-and-photo-developing",
      message: "Photofinishing laboratories and photo developing",
    })
  ),
  7399: i18n._(
    t({
      id: "setup.business-category.options.business-services-not-elsewhere-classified",
      message: "Business services - not elsewhere classified",
    })
  ),
  7407: i18n._(
    t({
      id: "setup.business-category.options.office-letting-co-working-spaces-virtual-offices",
      message: "Office letting, Co-working spaces, Virtual offices",
    })
  ),
  7408: i18n._(
    t({
      id: "setup.business-category.options.home-rental-services-commercial-property-mgt",
      message:
        "Home rental services, Commercial property, Property management, Letting agents",
    })
  ),

  7512: i18n._(
    t({
      id: "setup.business-category.options.automobile-rentals",
      message: "Automobile rentals",
    })
  ),
  7513: i18n._(
    t({
      id: "setup.business-category.options.truck-and-utility-trailer-rentals",
      message: "Truck and utility trailer rentals",
    })
  ),
  7519: i18n._(
    t({
      id: "setup.business-category.options.motor-home-and-recreational-vehicle-rentals",
      message: "Motor home and recreational vehicle rentals",
    })
  ),
  7523: i18n._(
    t({
      id: "setup.business-category.options.parking-lots-and-garages",
      message: "Parking lots and garages",
    })
  ),
  7531: i18n._(
    t({
      id: "setup.business-category.options.automotive-body-repair-shops",
      message: "Automotive body repair shops",
    })
  ),
  7534: i18n._(
    t({
      id: "setup.business-category.options.tyre-retreading-and-repair-shops",
      message: "Tyre retreading and repair shops",
    })
  ),
  7535: i18n._(
    t({
      id: "setup.business-category.options.automotive-paint-shops",
      message: "Automotive paint shops",
    })
  ),
  7538: i18n._(
    t({
      id: "setup.business-category.options.automotive-service-shops-non-dealer",
      message: "Automotive service shops (non-dealer)",
    })
  ),
  7542: i18n._(
    t({
      id: "setup.business-category.options.car-washes",
      message: "Car washes",
    })
  ),
  7549: i18n._(
    t({
      id: "setup.business-category.options.towing-services",
      message: "Towing services",
    })
  ),
  7622: i18n._(
    t({
      id: "setup.business-category.options.electronics-repair-shops",
      message: "Electronics repair shops",
    })
  ),
  7623: i18n._(
    t({
      id: "setup.business-category.options.air-conditioning-and-refrigeration-repair-shops",
      message: "Air conditioning and refrigeration repair shops",
    })
  ),

  7629: i18n._(
    t({
      id: "setup.business-category.options.electrical-and-small-appliance-repair-shops",
      message: "Electrical and small appliance repair shops",
    })
  ),
  7631: i18n._(
    t({
      id: "setup.business-category.options.watch-clock-and-jewellery-repair-shops",
      message: "Watch, clock and jewellery repair shops",
    })
  ),
  7641: i18n._(
    t({
      id: "setup.business-category.options.furniture-reupholstery-repair-and-refining",
      message: "Furniture reupholstery, repair and refinishing",
    })
  ),
  7692: i18n._(
    t({
      id: "setup.business-category.options.welding-services",
      message: "Welding services",
    })
  ),
  7699: i18n._(
    t({
      id: "setup.business-category.options.miscellaneous-repair-shops-and-related-services",
      message: "Miscellaneous repair shops and related services",
    })
  ),

  7911: i18n._(
    t({
      id: "setup.business-category.options.dance-halls-studios-and-schools",
      message: "Dance halls, studios and schools",
    })
  ),
  7929: i18n._(
    t({
      id: "setup.business-category.options.bands-orchestras-and-entertainers",
      message:
        "Bands, orchestras and miscellaneous entertainers - not elsewhere classified",
    })
  ),
  7941: i18n._(
    t({
      id: "setup.business-category.options.sports",
      message:
        "Commercial sports, professional sports clubs, athletic fields and sports promoters",
    })
  ),
  7991: i18n._(
    t({
      id: "setup.business-category.options.tourist-attractions-and-exhibits",
      message: "Tourist attractions and exhibits",
    })
  ),
  7992: i18n._(
    t({
      id: "setup.business-category.options.public-golf-courses",
      message: "Public golf courses",
    })
  ),
  7993: i18n._(
    t({
      id: "setup.business-category.options.video-amusement-game-supplies",
      message: "Video amusement game supplies",
    })
  ),
  7994: i18n._(
    t({
      id: "setup.business-category.options.video-game-arcades–and-establishments",
      message: "Video game arcades and establishments",
    })
  ),

  7995: i18n._(
    t({
      id: "setup.business-category.options.betting-including-lottery-tickets",
      message:
        "Betting, including lottery tickets, casino gaming chips, off-track betting and wagers at race tracks",
    })
  ),
  7996: i18n._(
    t({
      id: "setup.business-category.options.amusement-parks",
      message: "Amusement parks, circuses, carnivals and fortune tellers",
    })
  ),
  7997: i18n._(
    t({
      id: "setup.business-category.options.membership-clubs",
      message:
        "Membership clubs (sports, recreation, athletic), country clubs and private golf courses",
    })
  ),
  7999: i18n._(
    t({
      id: "setup.business-category.options.recreation-services",
      message: "Recreation services - not elsewhere classified",
    })
  ),
  8011: i18n._(
    t({
      id: "setup.business-category.options.doctors-and-physicians",
      message: "Doctors and physicians - not elsewhere classified",
    })
  ),
  8021: i18n._(
    t({
      id: "setup.business-category.options.dentists-and-orthodontists",
      message: "Dentists and orthodontists",
    })
  ),
  8031: i18n._(
    t({
      id: "setup.business-category.options.osteopaths",
      message: "Osteopaths",
    })
  ),
  8041: i18n._(
    t({
      id: "setup.business-category.options.chiropractors",
      message: "Chiropractors",
    })
  ),
  8042: i18n._(
    t({
      id: "setup.business-category.options.optometrists-and-ophthalmologists",
      message: "Optometrists and ophthalmologists",
    })
  ),
  8043: i18n._(
    t({
      id: "setup.business-category.options.opticians-and-optical-goods",
      message: "Opticians, optical goods and eyeglasses",
    })
  ),
  8049: i18n._(
    t({
      id: "setup.business-category.options.podiatrists-and-chiropodists",
      message: "Podiatrists and chiropodists",
    })
  ),
  8050: i18n._(
    t({
      id: "setup.business-category.options.nursing-and-personal-care-facilities",
      message: "Nursing and personal care facilities",
    })
  ),
  8062: i18n._(
    t({
      id: "setup.business-category.options.hospitals",
      message: "Hospitals",
    })
  ),
  8071: i18n._(
    t({
      id: "setup.business-category.options.medical-and-dental-laboratories",
      message: "Medical and dental laboratories",
    })
  ),

  8099: i18n._(
    t({
      id: "setup.business-category.options.medical-services-and-health-practitioners",
      message:
        "Medical services and health practitioners - not elsewhere classified",
    })
  ),

  8111: i18n._(
    t({
      id: "setup.business-category.options.legal-services-and-attorneys",
      message: "Legal services and attorneys",
    })
  ),
  8211: i18n._(
    t({
      id: "setup.business-category.options.elementary-and-secondary-schools",

      message: "Elementary and secondary schools",
    })
  ),
  8220: i18n._(
    t({
      id: "setup.business-category.options.colleges-universities-schools",
      message:
        "Colleges, universities, professional schools and junior colleges",
    })
  ),
  8241: i18n._(
    t({
      id: "setup.business-category.options.correspondence-schools",
      message: "Correspondence schools",
    })
  ),
  8244: i18n._(
    t({
      id: "setup.business-category.options.business-and-secretarial-schools",
      message: "Business and secretarial schools",
    })
  ),
  8249: i18n._(
    t({
      id: "setup.business-category.options.trade-and-vocational-schools",
      message: "Trade and vocational schools",
    })
  ),
  8299: i18n._(
    t({
      id: "setup.business-category.options.schools-and-educational-services",
      message: "Schools and educational services - not elsewhere classified",
    })
  ),
  8351: i18n._(
    t({
      id: "setup.business-category.options.child-care-services",
      message: "Child care services",
    })
  ),
  8398: i18n._(
    t({
      id: "setup.business-category.options.charitable-and-social-service-organizations",
      message: "Charitable and social service organizations",
    })
  ),
  8641: i18n._(
    t({
      id: "setup.business-category.options.civic-social-and-fraternal-associations",
      message: "Civic, social and fraternal associations",
    })
  ),
  8651: i18n._(
    t({
      id: "setup.business-category.options.political-organizations",
      message: "Political organizations",
    })
  ),
  8661: i18n._(
    t({
      id: "setup.business-category.options.religious-organizations",
      message: "Religious organizations",
    })
  ),
  8675: i18n._(
    t({
      id: "setup.business-category.options.automobile-associations",
      message: "Automobile associations",
    })
  ),
  8699: i18n._(
    t({
      id: "setup.business-category.options.membership-organizations",
      message: "Membership organizations - not elsewhere classified",
    })
  ),
  8911: i18n._(
    t({
      id: "setup.business-category.options.architectural-engineering-and-surveying-services",
      message: "Architectural, engineering and surveying services",
    })
  ),
  8931: i18n._(
    t({
      id: "setup.business-category.options.accounting-auditing-and-bookkeeping-services",
      message: "Accounting, auditing and bookkeeping services",
    })
  ),
  8999: i18n._(
    t({
      id: "setup.business-category.options.professional-services",
      message: "Professional services - not elsewhere classified",
    })
  ),
  9211: i18n._(
    t({
      id: "setup.business-category.options.court-costs-including-alimony-and-child-support",
      message: "Court costs, including alimony and child support",
    })
  ),
  9399: i18n._(
    t({
      id: "setup.business-category.options.government-services",
      message: "Government services - not elsewhere classified",
    })
  ),
  4011: i18n._(
    t({
      id: "setup.business-category.options.railroads",
      message: "Railroads",
    })
  ),
  4111: i18n._(
    t({
      id: "setup.business-category.options.local-and-suburban-commuter-transportation",
      message:
        "Local and suburban commuter passenger transportation, including ferries",
    })
  ),
  4119: i18n._(
    t({
      id: "setup.business-category.options.ambulance-services",
      message: "Ambulance services",
    })
  ),
  4131: i18n._(
    t({
      id: "setup.business-category.options.bus-lines",
      message: "Bus lines",
    })
  ),
  4411: i18n._(
    t({
      id: "setup.business-category.options.steamships-and-cruise-lines",
      message: "Steamships and cruise lines",
    })
  ),

  4582: i18n._(
    t({
      id: "setup.business-category.options.airports",
      message: "Airports, flying fields and airport terminals",
    })
  ),
  4784: i18n._(
    t({
      id: "setup.business-category.options.tolls-and-bridge-fees",
      message: "Tolls and bridge fees",
    })
  ),
  5094: i18n._(
    t({
      id: "setup.business-category.options.precious-stones-and-metals",
      message: "Precious stones and metals, watches and jewellery",
    })
  ),
  5099: i18n._(
    t({
      id: "setup.business-category.options.durable-goods",
      message: "Durable goods - not elsewhere classified",
    })
  ),
  5131: i18n._(
    t({
      id: "setup.business-category.options.piece-goods",
      message: "Piece goods, notions and other dry goods",
    })
  ),
  5169: i18n._(
    t({
      id: "setup.business-category.options.chemicals-and-allied-products",
      message: "Chemicals and allied products - not elsewhere classified",
    })
  ),
  5231: i18n._(
    t({
      id: "setup.business-category.options.glass-paint-and-wallpaper-shops",
      message: "Glass, paint and wallpaper shops",
    })
  ),
  5309: i18n._(
    t({
      id: "setup.business-category.options.duty-free-shops",
      message: "Duty-free shops",
    })
  ),
  5310: i18n._(
    t({
      id: "setup.business-category.options.discount-shops",
      message: "Discount shops",
    })
  ),
  5399: i18n._(
    t({
      id: "setup.business-category.options.miscellaneous-general-merchandise",
      message: "Miscellaneous general merchandise",
    })
  ),

  5541: i18n._(
    t({
      id: "setup.business-category.options.service-stations",
      message: "Service stations (with or without ancillary services)",
    })
  ),
  5542: i18n._(
    t({
      id: "setup.business-category.options.automated-fuel-dispensers",
      message: "Automated fuel dispensers",
    })
  ),
  5551: i18n._(
    t({
      id: "setup.business-category.options.boat-dealers",
      message: "Boat dealers",
    })
  ),
  5571: i18n._(
    t({
      id: "setup.business-category.options.motorcycle-shops-and-dealers",
      message: "Motorcycle shops and dealers",
    })
  ),
  5611: i18n._(
    t({
      id: "setup.business-category.options.mens-and-boys-clothing",
      message: "Men’s and boys’ clothing and accessory shops",
    })
  ),

  5621: i18n._(
    t({
      id: "setup.business-category.options.women-ready-to-wear-shop",
      message: "Women’s ready-to-wear shops",
    })
  ),

  5631: i18n._(
    t({
      id: "setup.business-category.options.womens-accessory",
      message: "Women’s accessory and speciality shops",
    })
  ),
  5641: i18n._(
    t({
      id: "setup.business-category.options.childrens-and-infants-wear",
      message: "Children’s and infants’ wear shops",
    })
  ),
  5651: i18n._(
    t({
      id: "setup.business-category.options.family-clothing",
      message: "Family clothing shops",
    })
  ),
  5661: i18n._(
    t({
      id: "setup.business-category.options.shoe-shops",
      message: "Shoe shops",
    })
  ),
  5681: i18n._(
    t({
      id: "setup.business-category.options.furriers-and-fur-shops",
      message: "Furriers and fur shops",
    })
  ),
  5691: i18n._(
    t({
      id: "setup.business-category.options.mens-and-womens-clothing",
      message: "Men’s and women’s clothing shops",
    })
  ),
  5697: i18n._(
    t({
      id: "setup.business-category.options.tailors",
      message: "Tailors, seamstresses, mending and alterations",
    })
  ),
  5698: i18n._(
    t({
      id: "setup.business-category.options.wig-and-toupee-shops",
      message: "Wig and toupee shops",
    })
  ),
  5699: i18n._(
    t({
      id: "setup.business-category.options.miscellaneous-apparel-and-accessory-shops",
      message: "Miscellaneous apparel and accessory shops",
    })
  ),
  5718: i18n._(
    t({
      id: "setup.business-category.options.fireplaces-fireplace-screens-and-accessories-shops",
      message: "Fireplaces, fireplace screens and accessories shops",
    })
  ),
  5719: i18n._(
    t({
      id: "setup.business-category.options.miscellaneous-home-furnishing-speciality-shops",
      message: "Miscellaneous home furnishing speciality shops",
    })
  ),
  5722: i18n._(
    t({
      id: "setup.business-category.options.household-appliance-shops",
      message: "Household appliance shops",
    })
  ),
  5735: i18n._(
    t({
      id: "setup.business-category.options.record-shops",
      message: "Record shops",
    })
  ),
  5812: i18n._(
    t({
      id: "setup.business-category.options.eating-places-and-restaurants",
      message: "Eating places and restaurants",
    })
  ),
  5813: i18n._(
    t({
      id: "setup.business-category.options.drinking-places-alcoholic-beverages",
      message:
        "Drinking places (alcoholic beverages) - bars, taverns, night-clubs, cocktail lounges and discothèques",
    })
  ),
  5921: i18n._(
    t({
      id: "setup.business-category.options.package-shops-beer-wine-and-liquor",
      message: "Package shops - beer, wine and liquor",
    })
  ),
  5931: i18n._(
    t({
      id: "setup.business-category.options.used-merchandise-and-second-hand-shops",
      message: "Used merchandise and second-hand shops",
    })
  ),
  5933: i18n._(
    t({
      id: "setup.business-category.options.pawn-shops",
      message: "Pawn shops",
    })
  ),
  5935: i18n._(
    t({
      id: "setup.business-category.options.wrecking-and-salvage-yards",
      message: "Wrecking and salvage yards",
    })
  ),
  5937: i18n._(
    t({
      id: "setup.business-category.options.antique-reproduction-shops",
      message: "Antique reproduction shops",
    })
  ),
  5944: i18n._(
    t({
      id: "setup.business-category.options.jewellery-watch-clock-and-silverware-shops",
      message: "Jewellery, watch, clock and silverware shops",
    })
  ),
  5945: i18n._(
    t({
      id: "setup.business-category.options.hobby-toy-and-game-shops",
      message: "Hobby, toy and game shops",
    })
  ),
  5947: i18n._(
    t({
      id: "setup.business-category.options.gift-card-novelty-and-souvenir-shops",
      message: "Gift, card, novelty and souvenir shops",
    })
  ),
  5950: i18n._(
    t({
      id: "setup.business-category.options.glassware-and-crystal-shops",
      message: "Glassware and crystal shops",
    })
  ),
  5972: i18n._(
    t({
      id: "setup.business-category.options.stamp-and-coin-shops",
      message: "Stamp and coin shops",
    })
  ),
  5973: i18n._(
    t({
      id: "setup.business-category.options.religious-goods-and-shops",
      message: "Religious goods and shops",
    })
  ),
  5978: i18n._(
    t({
      id: "setup.business-category.typewriter-outlets",
      message: "Typewriter outlets - sales, service and rentals",
    })
  ),
  5983: i18n._(
    t({
      id: "setup.business-category.options.fuel-oil-wood-coal-and-liquefied-petroleum",
      message: "Fuel dealers - fuel oil, wood, coal and liquefied petroleum",
    })
  ),
  5994: i18n._(
    t({
      id: "setup.business-category.options.newsagents-and-news-stands",
      message: "Newsagents and news-stands",
    })
  ),

  5999: i18n._(
    t({
      id: "setup.business-category.options.miscellaneous-and-speciality-retail-outlets",
      message: "Miscellaneous and speciality retail outlets",
    })
  ),
  6010: i18n._(
    t({
      id: "setup.business-category.options.financial-institutions-manual-cash-disbursements",
      message: "Financial institutions - manual cash disbursements",
    })
  ),
  6011: i18n._(
    t({
      id: "setup.business-category.options.financial-institutions-automated-cash-disbursements",
      message: "Financial institutions - automated cash disbursements",
    })
  ),
  6051: i18n._(
    t({
      id: "setup.business-category.options.non-financial-institutions",
      message:
        "Non-financial institutions - foreign currency, money orders (not wire transfer), scrip and travellers’ checks",
    })
  ),
  7012: i18n._(
    t({
      id: "setup.business-category.options.timeshares",
      message: "Timeshares",
    })
  ),
  7216: i18n._(
    t({
      id: "setup.business-category.options.dry-cleaners",
      message: "Dry cleaners",
    })
  ),
  7251: i18n._(
    t({
      id: "setup.business-category.options.shoe-repair-shops",
      message: "Shoe repair shops, shoe shine parlours and hat cleaning shops",
    })
  ),
  7277: i18n._(
    t({
      id: "setup.business-category.options.counselling-services",
      message: "Counselling services - debt, marriage and personal",
    })
  ),
  7296: i18n._(
    t({
      id: "setup.business-category.options.clothing-rentals",
      message: "Clothing rentals - costumes, uniforms and formal wear",
    })
  ),
  7299: i18n._(
    t({
      id: "setup.business-category.options.miscellaneous-personal-services",
      message: "Miscellaneous personal services - not elsewhere classified",
    })
  ),
  7321: i18n._(
    t({
      id: "setup.business-category.options.consumer-credit-reporting-agencies",
      message: "Consumer credit reporting agencies",
    })
  ),
  7322: i18n._(
    t({
      id: "setup.business-category.options.debt-collection-agencies",
      message: "Debt collection agencies",
    })
  ),
  7829: i18n._(
    t({
      id: "setup.business-category.options.motion-picture-and-video-tape",
      message: "Motion picture and video tape production and distribution",
    })
  ),
  7832: i18n._(
    t({
      id: "setup.business-category.options.motion-picture-theatres",
      message: "Motion picture theatres",
    })
  ),
  7841: i18n._(
    t({
      id: "setup.business-category.options.video-tape-rentals",
      message: "Video tape rentals",
    })
  ),
  7922: i18n._(
    t({
      id: "setup.business-category.options.theatrical-producers",
      message:
        "Theatrical producers (except motion pictures) and ticket agencies",
    })
  ),
  7932: i18n._(
    t({
      id: "setup.business-category.options.billiard-and-pool-establishments",
      message: "Billiard and pool establishments",
    })
  ),
  7933: i18n._(
    t({
      id: "setup.business-category.options.bowling-alleys",
      message: "Bowling alleys",
    })
  ),
  7998: i18n._(
    t({
      id: "setup.business-category.options.aquariums-seaquariums-and-dolphinariums",
      message: "Aquariums, seaquariums and dolphinariums",
    })
  ),
  8734: i18n._(
    t({
      id: "setup.business-category.options.testing-laboratories",
      message: "Testing laboratories (non-medical)",
    })
  ),
  9222: i18n._(
    t({
      id: "setup.business-category.options.fines",
      message: "Fines",
    })
  ),
  9223: i18n._(
    t({
      id: "setup.business-category.options.bail-and-bond-payments",
      message: "Bail and bond payments",
    })
  ),
  9311: i18n._(
    t({
      id: "setup.business-category.options.tax-payments",
      message: "Tax payments",
    })
  ),
  9402: i18n._(
    t({
      id: "setup.business-category.options.postal-services-govt-only",
      message: "Postal services - government only",
    })
  ),
  5192: i18n._(
    t({
      id: "setup.business-category.options.books-periodicals-and-newspapers",
      message: "Books, periodicals and newspapers",
    })
  ),
  5311: i18n._(
    t({
      id: "setup.business-category.options.department-stores",
      message: "Department stores",
    })
  ),
  5193: i18n._(
    t({
      id: "setup.business-category.options.florists-supplies-nursery-stock-and-flowers",
      message: "Florists’ supplies, nursery stock and flowers",
    })
  ),
  5422: i18n._(
    t({
      id: "setup.business-category.options.freezer-and-locker-meat-provisioners",
      message: "Freezer and locker meat provisioners",
    })
  ),
  5411: i18n._(
    t({
      id: "setup.business-category.options.groceries-and-supermarkets",
      message: "Groceries and supermarkets",
    })
  ),
  5251: i18n._(
    t({
      id: "setup.business-category.options.hardware-shops",
      message: "Hardware shops",
    })
  ),
  5301: i18n._(
    t({
      id: "setup.business-category.options.health-food-dietary-supplement-products",
      message: "Health food/dietary/supplement products",
    })
  ),
  5200: i18n._(
    t({
      id: "setup.business-category.options.home-supply-warehouse-outlets",
      message: "Home supply warehouse outlets",
    })
  ),
  5261: i18n._(
    t({
      id: "setup.business-category.options.lawn-and-garden-supplies-outlets",
      message: "Lawn and garden supplies outlets, including nurseries",
    })
  ),
  5211: i18n._(
    t({
      id: "setup.business-category.options.lumber-and-building-materials-outlets",
      message: "Lumber and building materials outlets",
    })
  ),
  5499: i18n._(
    t({
      id: "setup.business-category.options.miscellaneous-food-shops",
      message:
        "Miscellaneous food shops - convenience and speciality retail outlets",
    })
  ),
  5271: i18n._(
    t({
      id: "setup.business-category.options.mobile-home-dealers",
      message: "Mobile home dealers",
    })
  ),
  5199: i18n._(
    t({
      id: "setup.business-category.options.non-durable-goods",
      message: "Non-durable goods - not elsewhere classified",
    })
  ),
  5198: i18n._(
    t({
      id: "setup.business-category.options.paints-varnishes-and-supplies",
      message: "Paints, varnishes and supplies",
    })
  ),
  5172: i18n._(
    t({
      id: "setup.business-category.options.petroleum-and-petroleum-products",
      message: "Petroleum and petroleum products",
    })
  ),
  5331: i18n._(
    t({
      id: "setup.business-category.options.variety-stores",
      message: "Variety stores",
    })
  ),
  5300: i18n._(
    t({
      id: "setup.business-category.options.wholesale-clubs",
      message: "Wholesale clubs",
    })
  ),
});
