import { get, hasIn } from "lodash";

// The address search is powered by Loqate
// Documentation can be found at https://www.loqate.com/resources/support/apis/capture/

const FIND_RESULTS_LIMIT = "7";
const DOMAIN_URL = "https://services.postcodeanywhere.co.uk/";
const FIND_URL = `${DOMAIN_URL}Capture/Interactive/Find/v1.00/json3.ws`;
const RETRIEVE_URL = `${DOMAIN_URL}Capture/Interactive/Retrieve/v1.00/json3.ws`;
const KEY = "DU55-ZM25-KP71-GB94";

interface FindAddressApiResponse {
  Items: {
    Id: string;
    Type: string;
    Text: string;
    Highlight: string;
    Description: string;
  }[];
}

interface RetrieveAddressApiResponse {
  Items: {
    SubBuilding: string;
    BuildingNumber: string;
    BuildingName: string;
    Street: string;
    City: string;
    PostalCode: string;
    ProvinceCode: string;
    CountryIso2: string;
    Line1: string;
    Line2: string;
    Line3: string;
  }[];
}

export interface FindAddressResult {
  id: string;
  type: string;
  label: string;
}

export interface RetrieveAddressResult {
  address_line1?: string;
  address_line2?: string;
  address_line3?: string;
  flat_number?: string;
  building_number?: string;
  building_name?: string;
  street: string;
  city: string;
  region?: string;
  postal_code: string;
  country_code: string;
}

export interface RetrievePersonAddressResult {
  flat_number?: string;
  building_number?: string;
  building_name?: string;
  street: string;
  city: string;
  region?: string;
  postal_code: string;
  country_code: string;
}

const sanitizedAddress = (
  retrievedAddressUnFiltered: Record<string, unknown>
) =>
  Object.keys(retrievedAddressUnFiltered)
    .filter((key) => retrievedAddressUnFiltered[key] !== "")
    .reduce(
      (obj, key) => ({ ...obj, [key]: retrievedAddressUnFiltered[key] }),
      {}
    ) as RetrieveAddressApiResponse["Items"][0];

export const findAddress = (
  text: string,
  country: string,
  container?: string
): Promise<FindAddressResult[]> =>
  fetch(FIND_URL, {
    method: "POST",
    body: getFindFormData(text, country, container),
  })
    .then((response): Promise<FindAddressApiResponse> => {
      if (response.status !== 200) {
        throw new Error("Address Find Call Error");
      }
      return response.json();
    })
    .then((result) => {
      if (hasIn(result, "Items[0].Error")) {
        throw new Error(get(result, "Items[0].Error"));
      }
      return result.Items.map(
        ({ Id, Type, Text, Description }): FindAddressResult => ({
          id: Id,
          type: Type,
          label: `${Text}, ${Description}`,
        })
      );
    })
    .catch(() => []);

export const retrieveAddress = (id: string): Promise<RetrieveAddressResult> =>
  fetch(RETRIEVE_URL, {
    method: "POST",
    body: getRetrieveFormData(id),
  })
    .then((response): Promise<RetrieveAddressApiResponse> => {
      if (response.status !== 200) {
        throw new Error("Address Find Call Error");
      }
      return response.json();
    })
    .then((result): RetrieveAddressResult => {
      if (hasIn(result, "Items[0].Error")) {
        throw new Error(get(result, "Items[0].Error"));
      }
      const retrievedAddress = sanitizedAddress(get(result, "Items[0]", {}));
      return {
        address_line1: retrievedAddress.Line1,
        address_line2: retrievedAddress.Line2,
        address_line3: retrievedAddress.Line3,
        flat_number: retrievedAddress.SubBuilding,
        building_number: retrievedAddress.BuildingNumber,
        building_name: retrievedAddress.BuildingName,
        street: retrievedAddress.Street,
        city: retrievedAddress.City,
        region: retrievedAddress.ProvinceCode,
        postal_code: retrievedAddress.PostalCode,
        country_code: retrievedAddress.CountryIso2,
      };
    });

export const retrievePersonAddress = (
  id: string
): Promise<RetrievePersonAddressResult> =>
  fetch(RETRIEVE_URL, {
    method: "POST",
    body: getRetrieveFormData(id),
  })
    .then((response): Promise<RetrieveAddressApiResponse> => {
      if (response.status !== 200) {
        throw new Error("Address Find Call Error");
      }
      return response.json();
    })
    .then((result): RetrievePersonAddressResult => {
      if (hasIn(result, "Items[0].Error")) {
        throw new Error(get(result, "Items[0].Error"));
      }
      const retrievedAddress = sanitizedAddress(get(result, "Items[0]", {}));
      return {
        flat_number: retrievedAddress.SubBuilding,
        building_number: retrievedAddress.BuildingNumber,
        building_name: retrievedAddress.BuildingName,
        street: retrievedAddress.Street,
        city: retrievedAddress.City,
        region: retrievedAddress.ProvinceCode,
        postal_code: retrievedAddress.PostalCode,
        country_code: retrievedAddress.CountryIso2,
      };
    });

const getFindFormData = (
  text: string,
  country: string,
  container = ""
): FormData => {
  const findFormData = new FormData();
  findFormData.append("key", KEY);
  findFormData.append("text", text);
  findFormData.append("countries", country);
  findFormData.append("container", container || "");
  findFormData.append("limit", FIND_RESULTS_LIMIT);
  return findFormData;
};

const getRetrieveFormData = (id: string): FormData => {
  const findFormData = new FormData();
  findFormData.append("key", KEY);
  findFormData.append("id", id);
  return findFormData;
};
