import { useEffect } from "react";
import { Field, Label, Input } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { Field as Config, FieldArrayProps } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

import { error, presenceCheck, validateAddressField } from "./helpers";

const AddressLineOneField: React.FC<FieldArrayProps> = ({ defaultValue }) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors: clearError,
  } = useFormContext<BusinessDetailsConfig>();

  useEffect(() => {
    clearError("address_line1");
    setValue("address_line1", defaultValue);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const presenceCheckValidation = presenceCheck(
    i18n._(
      t({
        id: "setup.business-details.address-line-1-required-error",
        message: "Please enter line 1 of your company address",
      })
    )
  );
  const addressFormatValidation = validateAddressField(
    `${i18n._(
      t({
        id: "setup.business-details.address-line-1-error",
        message: "Please enter a valid address",
      })
    )}`
  );
  const validationRules = {
    ...presenceCheckValidation,
    validate: {
      ...presenceCheckValidation.validate,
      ...addressFormatValidation,
    },
  };

  return (
    <Field>
      <Label htmlFor="address_line1">
        <Trans id="setup.business-details.address-line-1">Address line 1</Trans>
      </Label>
      <Input
        id="address_line1"
        {...register("address_line1", validationRules)}
        defaultValue={defaultValue}
        className="fs-exclude"
      />
      {error(errors, "address_line1")}
    </Field>
  );
};

export const config: Config = {
  name: "address_line1",
  required: true,
  displayName: "Address line one",
  component: AddressLineOneField,
};

export default AddressLineOneField;
