import {
  CreditorType,
  CreditorDetailResource,
  CharityType,
  AmlPersonWithAddressResource,
  TrusteeElement,
} from "@gocardless/api/dashboard/types";

export interface TrusteeDetails {
  creditor_type: CreditorType | null | undefined;
  country_code: string | undefined;
  charity_type: CharityType | undefined;
  account_holder_is_trustee: boolean | undefined;
  signatories: AmlPersonWithAddressResource[];
  trustees: TrusteeElement[];
}

/**
 * Builds TrusteeDetails object from creditor_details
 * API endpoint. Default empty arrays are provided
 * for signatories and trustees as until a merchant
 * first submits their creditor_details these key/value
 * pairs are not present on the API response.
 *
 * To determine at what stage a merchant is in the
 * onboarding flow we need to be able to evaluate
 * these in numerous places. This prevents repetitive
 * handling of undefined types all over the setup flow.
 */
export const getTrusteeDetails = (
  creditorDetails: CreditorDetailResource | undefined
): TrusteeDetails => {
  const detail = creditorDetails?.detail;
  return {
    creditor_type: creditorDetails?.creditor_type,
    country_code: detail?.country_code,
    charity_type: detail?.charity_type,
    account_holder_is_trustee: detail?.account_holder_is_trustee,
    signatories: detail?.signatories || [],
    trustees: detail?.trustees || [],
  };
};

/**
 * If the account holder is a trustee the trustees array
 * should also include their name and DOB details. In this case
 * the trustees array should be returned with the signatory
 * filtered out as they update these details on the About You
 * page.
 *
 * If the account holder is NOT a trustee and the trustees
 * array is not empty then the full list should be returned.
 *
 * In cases where there are no trustees an empty record should be
 * returned, which will create the form on the page.
 */
export const getTrustees = ({
  trustees,
  signatories,
  account_holder_is_trustee,
}: TrusteeDetails): TrusteeElement[] => {
  const hasTrustees = trustees.length > 0;
  const signatory = signatories[0];
  return account_holder_is_trustee
    ? hasTrustees && signatory
      ? trustees.filter(
          (t) =>
            // exclude the matching signatory trustee
            !(
              t.given_name === signatory.given_name &&
              t.family_name === signatory.family_name &&
              t.date_of_birth === signatory.date_of_birth
            )
        )
      : []
    : hasTrustees
      ? trustees
      : [
          {
            given_name: "",
            family_name: "",
            date_of_birth: "",
            country_code: "",
          },
        ];
};
