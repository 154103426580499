// list of the top 8 institutions by number of billing requests created
// taken from https://looker.gocardless.io/explore/banking_integrations_production/billing_requests?qid=GHWPCOAVTnxEEhzlUWi4sH
// as of Aug 2022
export const TopPopularBanksGB: string[] = [
  "STARLING_SRLGGB3L",
  "NATWEST_NWBKGB2L",
  "MONZO_MONZGB2L",
  "LLOYDS_BUSINESS_LOYDGB2L",
  "HSBC_BUSINESS_HBUKGB4B",
  "TSB_GB_TSBSGB2A",
  "REVOLUT_REVOGB21",
  "VIRGIN_NRNBGB22",
  "BANK_OF_SCOTLAND_BUSINESS_BOFSGBS1",
  "NATIONWIDE_NAIAGB21",
];

// Priorisitation:
// Result from the AI search "20 banks used by the most small businesses in the US"
// MX provider returns a logo
// They don’t have a separate business institution (we don't show only one, merchant
// can search for them and make an informed choice)
export const TopPopularBanksUS: string[] = [
  "mx_wells_fargo",
  "mx_chase",
  "mx_bank_of_america",
  "mx_69825", // Key Bank,
  "mx_capital_one",
  "mx_us_bank",
  "mx_68342", // TAB Bank
  "mx_681421b7-0a2f-1e05-7bac-6ca325b86d2d", // Live Oak Bank
];

export const TopPopularBanks: Record<string, string[]> = {
  GB: TopPopularBanksGB,
  US: TopPopularBanksUS,
};
