import { useEffect } from "react";
import { get, useFormContext } from "react-hook-form";
import { Trans, t } from "@lingui/macro";
import {
  Field,
  Label,
  Input,
  FormFieldStatus,
  Hint,
} from "@gocardless/flux-react";
import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";
import { useLingui } from "@lingui/react";
import { hasIn } from "lodash";

import { validateAddressField } from "./helpers";

type FlatNumberFieldProps = {
  fieldPath: string;
} & FieldArrayProps;

const FlatNumberField: React.FC<FlatNumberFieldProps> = ({
  defaultValue,
  fieldPath,
}) => {
  const { i18n } = useLingui();

  const {
    register,
    unregister,
    setValue,
    formState: { errors },
  } = useFormContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => unregister(fieldPath), []);
  useEffect(() => {
    setValue(fieldPath, defaultValue);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const errorMessage = hasIn(errors, fieldPath)
    ? `${get(errors, `${fieldPath}`)?.message}`
    : undefined;

  const validationRules = {
    validate: validateAddressField(
      `${i18n._(
        t({
          id: "setup.about-you.address.flat-number",
          message: "Please enter a valid address",
        })
      )}`
    ),
  };

  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="Home flat number">Home flat number</Trans>
      </Label>
      <Input
        {...register(fieldPath, validationRules)}
        id={fieldPath}
        defaultValue={defaultValue}
        className="fs-exclude"
      />{" "}
      {errorMessage && (
        <Hint status={FormFieldStatus.Danger}>{errorMessage}</Hint>
      )}
    </Field>
  );
};

const DirectorFlatNumberField: React.FC<FieldArrayProps> = (props) => (
  <FlatNumberField
    {...props}
    fieldPath={`directors[${props.index}].flat_number`}
  />
);

const OwnerFlatNumberField: React.FC<FieldArrayProps> = (props) => (
  <FlatNumberField
    {...props}
    fieldPath={`shareholders[${props.index}].flat_number`}
  />
);

const PersonFlatNumberField: React.FC<FieldArrayProps> = (props) => (
  <FlatNumberField {...props} fieldPath="person.flat_number" />
);

const ControlFlatNumberField: React.FC<FieldArrayProps> = (props) => (
  <FlatNumberField
    {...props}
    fieldPath={`control_prongs[${props.index}].flat_number`}
  />
);

const TrusteeFlatNumberField: React.FC<FieldArrayProps> = (props) => (
  <FlatNumberField
    {...props}
    fieldPath={`trustees[${props.index}].flat_number`}
  />
);

const PartnerFlatNumberField: React.FC<FieldArrayProps> = (props) => (
  <FlatNumberField
    {...props}
    fieldPath={`partners[${props.index}].flat_number`}
  />
);

const UBOFlatNumberField: React.FC<FieldArrayProps> = (props) => (
  <FlatNumberField
    {...props}
    fieldPath={`ultimate_beneficial_owners[${props.index}].flat_number`}
  />
);

export const directorConfig: Config = {
  name: "flat_number",
  displayName: "Home flat number",
  component: DirectorFlatNumberField,
};

export const ownerConfig: Config = {
  name: "flat_number",
  displayName: "Home flat number",
  component: OwnerFlatNumberField,
};

export const personConfig: Config = {
  name: "flat_number",
  displayName: "Home flat number",
  component: PersonFlatNumberField,
};

export const controlConfig: Config = {
  name: "flat_number",
  displayName: "Home flat number",
  component: ControlFlatNumberField,
};

export const trusteeConfig: Config = {
  name: "flat_number",
  displayName: "Home flat number",
  component: TrusteeFlatNumberField,
};

export const partnerConfig: Config = {
  name: "flat_number",
  displayName: "Home flat number",
  component: PartnerFlatNumberField,
};

export const uboConfig: Config = {
  name: "flat_number",
  displayName: "Home flat number",
  component: UBOFlatNumberField,
};

export default FlatNumberField;
