import { PricingPackagesV3 } from "../usePackageStateV3";

export type BasePricingPackages =
  | PricingPackagesV3.STANDARD
  | PricingPackagesV3.ADVANCED
  | PricingPackagesV3.PRO
  | PricingPackagesV3.ENTERPRISE;

export const getBasePackage = (
  packageState: PricingPackagesV3
): BasePricingPackages => {
  switch (packageState) {
    case PricingPackagesV3.ADVANCED:
    case PricingPackagesV3.ADVANCED_INVOICED:
      return PricingPackagesV3.ADVANCED;
    case PricingPackagesV3.PRO:
    case PricingPackagesV3.PRO_INVOICED:
      return PricingPackagesV3.PRO;
    case PricingPackagesV3.ENTERPRISE:
      return PricingPackagesV3.ENTERPRISE;
    case PricingPackagesV3.STANDARD_INVOICED:
    default:
      return PricingPackagesV3.STANDARD;
  }
};
