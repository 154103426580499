import * as Fields from "../fields";
import * as Individual from "../GLOBAL/individual";

/**
 * GB Individual Business Details
 * includes additional fields so
 * all are defined here
 */
export const BusinessDetails = [
  Fields.Country,
  Fields.CreditorType,
  Fields.IndividualTradingName,
  Fields.VATNumber,
  Fields.TaxJurisdiction,
  /** Additional fields for /intro variant of setup */
  ...Individual.AboutYou,
];

/**
 * GB About You page is the same
 * as the global configuration
 */
export const AboutYou = [...Individual.AboutYou];

/**
 * GB Business Category is the same
 * as the global configuration
 */
export const BusinessCategory = [...Individual.BusinessCategory];
