import { CreditorType } from "@gocardless/api/dashboard/types";

import { ValidatorData } from "../validators/useComplete";

// Allow components with any props if needed
/* eslint-disable  @typescript-eslint/no-explicit-any */
export type Component = Readonly<React.FC<any> | (() => JSX.Element) | Field[]>;

export type Field = Readonly<{
  name: string;
  displayName: any;
  component: Component;
  required?: boolean | ((v: ValidatorData) => boolean);
}>;

export enum Geo {
  GB = "GB",
  US = "US",
}

export enum SetupPages {
  ABOUT_YOU = "AboutYou",
  BANK_STATEMENT_NAME = "BankStatementName",
  BUSINESS_CATEGORY = "BusinessCategory",
  BUSINESS_DETAILS = "BusinessDetails",
  BUSINESS_DIRECTORS = "BusinessDirectors",
  BUSINESS_OWNERS = "BusinessOwners",
  TRUSTEE_DETAILS = "TrusteeDetails",
  TRUST_OWNERS = "TrustOwners",
}

export type FieldConfigLegacy = Readonly<{
  [page in SetupPages]: ({ [key in CreditorType]?: Field[] } | Field[])[];
}>;

export type FieldConfig = {
  [type in CreditorType]: {
    [page in SetupPages]?: (Field | Field[])[];
  };
};

export interface FieldArrayProps {
  defaultValue?: string;
  index?: number;
}

export interface FieldSelectProps {
  onChange: (value: number) => void;
  value: number;
}

export interface FieldAttributes {
  [index: string]: {
    component?: React.FC<any>;
    props?: object;
    canRender?: boolean;
  };
}
